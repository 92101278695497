import React from "react";

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"

import Carousel from "react-material-ui-carousel"

import clsx from 'clsx';

import HorizontalSliderStylesFunc from "../../../Style/HorizontalSliderStyle"
import * as Constants from "../../../Style/Constants"


function HorizontalSlider({elementList, nbElementsPerPage, carouselKey, arrows, wrapperClass}) {
    let HorizontalSliderStyles = HorizontalSliderStylesFunc();
    // let [page, setPage] = React.useState(0);
    let [slices, setSlices] = React.useState([]);

    const nbPages = Math.ceil(elementList.length / nbElementsPerPage);

    let version = 0;
    React.useEffect(() => {
        let newSlices = [];
        for (let j = 0; j < nbPages; j++) {
            const slice = [];
            for (let i = j * nbElementsPerPage; i < j * nbElementsPerPage + nbElementsPerPage; i++) {
                slice.push(elementList[i % elementList.length]);
            }
            newSlices.push(<Grid container key={"slide-" + j + "-version-" + version} justify={"space-around"}>{slice}</Grid>)
        }
        setSlices(newSlices);
        version += 1;
    }, [elementList, nbElementsPerPage]);

    return (
        <Carousel
            style={{margin: "auto"}}
            className={clsx(HorizontalSliderStyles.carousel, wrapperClass)}
            autoPlay={false}
            indicators={!arrows}
            navButtonsAlwaysInvisible={!arrows}
            key={carouselKey + "-" + slices.length}>
            {slices}
        </Carousel>
    );
}

export default HorizontalSlider;