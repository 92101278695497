import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon"

function HeartIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 3.814121,4.5977683 6.315697,14.60408 v 2.44856 c 0,0.3488 0.282768,0.6316 0.631576,0.6316 h 15.157913 c 0.3488,0 0.6316,-0.2828 0.6316,-0.6316 0,-0.3488 -0.2828,-0.6316 -0.6316,-0.6316 H 7.5788494 v -1.35968 l 14.6301766,-2.4384 c 0.26616,-0.04432 0.4748,-0.25296 0.51912,-0.51912 l 1.2632,-7.5789597 c 0.06416,-0.38496 -0.23272,-0.735408 -0.62304,-0.735408 H 4.876177 L 3.756969,0.43185623 C 3.671001,0.17395623 3.429649,2.3e-7 3.157801,2.3e-7 H 0.63148425 c -0.348812,0 -0.63157920466,0.2827672 -0.63157920466,0.6315792 0,0.34881284 0.28276720466,0.63158087 0.63157920466,0.63158087 H 2.702585 Z M 23.999906,21.47368 c 0,-1.396 -1.13032,-2.52632 -2.52632,-2.52632 -1.396,0 -2.52632,1.13032 -2.52632,2.52632 0,1.396 1.13032,2.52632 2.52632,2.52632 1.396,0 2.52632,-1.13032 2.52632,-2.52632 z m -2.52632,-1.26312 c -0.69832,0 -1.26312,0.5648 -1.26312,1.26312 0,0.69832 0.5648,1.2632 1.26312,1.2632 0.69832,0 1.2632,-0.56488 1.2632,-1.2632 0,-0.69832 -0.56488,-1.26312 -1.2632,-1.26312 z M 8.8419859,21.47368 c 0,-1.396 -1.1303205,-2.52632 -2.5262889,-2.52632 -1.395968,0 -2.52632,1.13032 -2.52632,2.52632 0,1.396 1.130352,2.52632 2.52632,2.52632 1.3959684,0 2.5262889,-1.13032 2.5262889,-2.52632 z M 6.315697,20.21056 c -0.698352,0 -1.26316,0.5648 -1.26316,1.26312 0,0.69832 0.564808,1.2632 1.26316,1.2632 0.6983444,0 1.2631524,-0.56488 1.2631524,-1.2632 0,-0.69832 -0.564808,-1.26312 -1.2631524,-1.26312 z M 5.229873,5.0526323 H 22.622786 L 21.556386,11.4512 7.4185774,13.80744 Z"
                id="path3838"
                style={{clipRule: "evenodd", fillRule: "evenodd"}} />
                {/*style="clip-rule:evenodd;fill:#c6c6c6;fill-rule:evenodd;stroke-width:0.80000001" />*/}
        </SvgIcon>
    )
}

export default HeartIcon
