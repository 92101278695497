import {API_URL, JWT_TOKEN_NAME, JWT_TOKEN_PREFIX, LIVE_URL, CHAT_URL} from "../Config/API";
import { store } from '../Store'


export const setDefaultHeaders = (headers = {}) => {
    const defaultHeaders = {
        "Accept": "application/ld+json"
    };

    if (store.getState().login.token) {
        defaultHeaders[JWT_TOKEN_NAME] = JWT_TOKEN_PREFIX + " " + store.getState().login.token;
    }

    return {
        ...defaultHeaders,
        ...headers
    };
};

export const getApiUrl = (route, queryParams = null) => {
    let apiUrl = API_URL + "api/";

    if (route) {
        apiUrl += route;
    }

    if (queryParams) {
        const searchParams = new URLSearchParams();
        for (const key in queryParams) {
            if (queryParams[key] !== undefined && queryParams[key] !== null && queryParams[key] !== "") {
                if (Array.isArray(queryParams[key])) {
                    for (const param of queryParams[key]) {
                        if (param != null) {
                            searchParams.append(key + "[]", param);
                        }
                    }
                } else {
                    searchParams.append(key, queryParams[key]);
                }
            }
        }
        apiUrl += "?" + searchParams.toString();
    }

    return apiUrl;
};

export const getLiveStreamUrl = (liveToken) => {
    return LIVE_URL + liveToken + '/index.m3u8';
}

export const getLiveChatUrl = () => {
    return CHAT_URL;
}