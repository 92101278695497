import React from "react";
import clsx from 'clsx';

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";

import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlineChecked from '@material-ui/icons/CheckBoxOutlined';

import Text from "../Component/FontText"
import { connect } from 'react-redux'
import logo from "../../Assets/Images/logo-violette-sauvage-final.svg"
import gplusLogo from "../../Assets/Images/gplus_logo.svg"
import fbLogo from "../../Assets/Images/fb_logo.svg"
import { LINKS } from "../Component/InternalLink"
import TextInputIcon from "./Component/TextInputIcon";
import {useForm, Controller} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"
import ErrorStyleFunc from '../Styles/Error'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {GoogleLogin} from "react-google-login";
import Reaptcha from 'reaptcha';

import RegisterStylesFunc from '../../Style/RegisterStyle'
import * as Constants from '../../Style/Constants'
import MetaTags from "react-meta-tags";

function Component({navigation, token, onRegister, registerErrors, loginFacebook, loginGoogle, isLoading}) {
    let ErrorStyle = ErrorStyleFunc();
    let RegisterStyles = RegisterStylesFunc();
    const { setError, clearErrors, handleSubmit, errors, watch, register, setValue } = useForm();
    let [ recaptchaToken, setRecaptchaToken ] = React.useState(null);

    React.useEffect(() => {
        if (registerErrors) {
            for (let i = 0; i < registerErrors.length; i++) {
                setError(registerErrors[i]["propertyPath"], {type: "custom", message: registerErrors[i]["message"]});
            }
        }
    }, [registerErrors]);

    let onClickRegister = (data) => {
        onRegister(data.username, data.email, data.password, data.newsletter, recaptchaToken);
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title += ' - S\'inscrire';
    }, [token]);

    let onVerify = (response) => {
        clearErrors(["token"]);
        setRecaptchaToken(response);
    };

    const registerFacebook = (response) => {
        loginFacebook(response.accessToken);
    };

    const registerGoogle = (response) => {
        loginGoogle(response.accessToken);
    };

    if (token === null) {
        return (
            <Grid container xs={12} className={RegisterStyles.container} alignItems={"center"}>
                <MetaTags>
                    <title>Inscrivez sur Violette Sauvage, vide dressing en ligne</title>
                    <meta name="description"
                          content={"Créez votre compte personnel en tant que vendeur ou acheteur sur Violette Sauvage, votre vide dressing en ligne."}/>
                </MetaTags>
                <Grid item xs={12}>
                    <Text className={RegisterStyles.welcomeLabel}>Bienvenue sur</Text>
                </Grid>
                <Grid container item xs={12} justify={"center"}>
                    <CardMedia src={logo} component={"img"} className={RegisterStyles.logo} />
                </Grid>
                <Grid container item xs={12} justify={"center"} className={RegisterStyles.connectWrapper}>
                    <Text className={RegisterStyles.connectLabel}>S'inscrire avec</Text>
                    <GoogleLogin
                        clientId="526247064655-51pg5hgahi10al745olf4pd476gjkanu.apps.googleusercontent.com"
                        buttonText="Login"
                        onSuccess={registerGoogle}
                        onFailure={registerGoogle}
                        cookiePolicy={'single_host_origin'}
                        render={renderProps => (
                            <Button onClick={renderProps.onClick} className={RegisterStyles.snButton}>
                                <CardMedia className={RegisterStyles.snLogo} src={gplusLogo} component={"img"} />
                            </Button>
                        )}/>
                    <FacebookLogin
                        // appId="2519008774979459"
                        appId={"4322364311117912"}
                        fields="first_name,last_name,email"
                        callback={registerFacebook}
                        render={renderProps => (
                            <Button onClick={renderProps.onClick} className={RegisterStyles.snButton}>
                                <CardMedia className={RegisterStyles.snLogo} src={fbLogo} component={"img"} />
                            </Button>
                        )}/>
                </Grid>
                <Grid item xs={12}>
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            icon={"user-circle"}
                            placeholder={"Nom d'utilisateur"}
                            rules={{
                                required: "Ce champ est obligatoire"
                            }}
                            name={"username"}
                        />
                </Grid>
                <Grid item xs={12}>
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            icon={"user-circle"}
                            placeholder={"Adresse e-mail"}
                            rules={{
                                required: "Ce champ est obligatoire",
                                pattern: {
                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Format de l'adresse e-mail invalide"
                                }
                            }}
                            name={"email"}
                        />
                </Grid>
                <Grid item xs={12}>
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            icon={"key"}
                            placeholder={"Mot de passe"}
                            rules={{
                                required: "Ce champ est obligatoire",
                                validate: (value) => value === watch('password2') || "Les deux mots de passe doivent être identiques"
                            }}
                            name={"password"}
                            secure={true}
                        />
                </Grid>
                <Grid item xs={12}>
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            icon={"key"}
                            placeholder={"Confirmation du mot de passe"}
                            rules={{
                                required: "Ce champ est obligatoire",
                                validate: (value) => value === watch('password') || "Les deux mots de passe doivent être identiques"
                            }}
                            name={"password2"}
                            secure={true}
                            enterkeyhint="done"
                        />
                </Grid>
                <Grid container item xs={12} style={{marginBottom: 50, marginTop: 5}}>
                    <Grid item xs={12} sm={6} style={{marginBottom: 20}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"newsletter"}
                                    inputRef={register()}
                                    icon={<CheckBoxOutline style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.FONT_LIGHT_GRAY}}/>}
                                    checkedIcon={<CheckBoxOutlineChecked style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.PINK}}/>}
                                />
                            }
                            label={
                                <Box style={{textAlign: "left"}}>
                                    <Text className={RegisterStyles.checkboxLabel}>S'abonner à la newsletter</Text>
                                </Box>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"cgu"}
                                    inputRef={register({required: "Vous devez accepter les conditions générales d'utilisation"})}
                                    icon={<CheckBoxOutline style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.FONT_LIGHT_GRAY}}/>}
                                    checkedIcon={<CheckBoxOutlineChecked style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.PINK}}/>}
                                />
                            }
                            label={
                                <Box style={{textAlign: "left"}}>
                                    <Text component={"span"} className={clsx(RegisterStyles.checkboxLabel, {[ErrorStyle.error]: errors["cgu"]})}>J'accepte les <Link href={'/CGV'} target={"_blank"}>CGV/CGU et la politique de confidentialité</Link> de Violette Sauvage</Text>
                                    {/*<Link href={"/CGV"}>CGV et la politique de confidentialité</Link>*/}
                                </Box>
                            }
                        />
                    </Grid>
                    <ErrorMessage name={"cgu"} errors={errors} as={Text} className={ErrorStyle.error} style={{width: "100%"}} />

                </Grid>
                <Grid container item xs={12} justify={"center"}>
                    <Reaptcha sitekey="6LdOPAMeAAAAAGDcJRlSWk-MYVaQyOo0swtFNnaS" onVerify={onVerify} />
                    <ErrorMessage name={"token"} errors={errors} as={Text} className={ErrorStyle.error} style={{width: "100%"}} />

                </Grid>
                <Grid item xs={12}>
                    <Button onClick={handleSubmit(onClickRegister)} color={"primary"} variant={"contained"} disableElevation className={RegisterStyles.button}>
                        Inscription
                    </Button>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container xs={12} className={RegisterStyles.container} alignItems={"center"}>
                <Grid item xs={12}>
                    <Text className={RegisterStyles.welcomeLabel}>Bienvenue sur</Text>
                </Grid>
                <Grid container item xs={12} justify={"center"}>
                    <CardMedia src={logo} component={"img"} className={RegisterStyles.logo} />
                </Grid>
                <Grid container item xs={12} justify={"center"} style={{marginBottom: 50}}>
                    <Text className={RegisterStyles.connectLabel}>Ton compte a bien été créé !</Text>
                </Grid>

                <Grid xs={12} sm style={{marginTop: 10}}>
                    <Button variant={"contained"} color={"primary"} disableElevation href={LINKS["CREATE_MY_SHOP"]["web"]} size={"small"}>
                        Vends maintenant
                    </Button>
                </Grid>
                <Grid xs={12} sm style={{marginTop: 10}}>
                    <Button variant={"contained"} color={"primary"} disableElevation href={LINKS["HOMEPAGE"]["web"]} size={"small"}>
                        Continue ton shopping
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        registerErrors: state.user.registerErrors,
        isLoading: state.loading.isLoading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRegister: (login, mail, pwd, newsletter, recaptchaToken) => {
            dispatch({type: 'REGISTER_REQUESTED', payload: {login, mail, pwd, newsletter, recaptchaToken}})
        },
        loginFacebook: (accessToken) => {
            dispatch({type: 'LOGIN_FACEBOOK_REQUESTED', payload: {accessToken}})
        },
        loginGoogle: (accessToken) => {
            dispatch({type: 'LOGIN_GOOGLE_REQUESTED', payload: {accessToken}})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent
