import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    // Global
    button: {
        margin: "auto"
    },
    buttonLabel: {
        ...GlobalStyle.uppercaseText,
        padding: 10,
        backgroundColor: Constants.WHITE
    },
    whiteBlock: {
        marginLeft: 25,
        marginRight: 25,
        marginTop: 50,
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
            marginBottom: 20
        }
    },

    card: {
        ...GlobalStyle.rowCenteredHW,
        marginLeft: Constants.GET_SIZE(25),
        marginRight: Constants.GET_SIZE(25),
        marginTop: Constants.GET_SIZE(50),
        marginBottom: Constants.GET_SIZE(50),
        width: "100%"
    },
    grayBlock: {
        backgroundColor: "#F3F3F3",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        paddingTop: 50,
        paddingBottom: 50,
        [theme.breakpoints.down('xs')]: {
            backgroundSize: "auto",
        }
    },
    blockTitle: {
        fontSize: Constants.FONT_SIZE_BIG,
        ...GlobalStyle.uppercaseText,
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(35),
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },
    blockTitleWhite: {
        // marginTop: Constants.GET_SIZE(90),
        color: Constants.WHITE
    },
    blockFooter: {
        ...GlobalStyle.uppercaseText,
        marginTop: Constants.GET_SIZE(25),
    },

    row: {
        ...GlobalStyle.rowCenteredHW
    },

    // Header
    homepageHeaderWrapper: {
        maxHeight: 250,
        height: 250,
    },
    homepageHeaderLeftWrapper: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        width: "calc(39% - 1px)",
        borderRight: "1px solid white"
    },

    homepageHeaderRightWrapper: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        width: "61%",
    },

    homepageHeaderImage: {
        width: "100%",
    },

    homepageHeaderText: {
        color: Constants.FONT_LIGHT_GRAY,
        width: 200
    },

    homepageHeaderText2: {
        width: 350
    },

    headerImageTitle: {
        ...GlobalStyle.headerImageTitle,
        position: "absolute",
        bottom: 50,
        left: 58,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            bottom: 20,
            left: 25,
            width: "85%",
            overflowWrap: "break-word"
        }
    },

    homepageHeaderRectangle: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: 205,
        height: 15,
        [theme.breakpoints.down('xs')]: {
            width: "90%",
            height: 10
        }
    },

    homepageHeaderPinkRectangle: {
        backgroundColor: Constants.PINK
    },

    homepageHeaderGrayRectangle: {
        backgroundColor: Constants.LIGHT_GRAY
    },



    // Tendance sauvages

    block1Image2: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: 338,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    block1Image1: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: 335,
        position: "relative",
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            height: 200,
        }
    },
    blurFilter: {
        height: "100%",
        backdropFilter: "blur(5px)"
    },
    lovedShopsImageBackground: {
        width: "100%",
        height: Constants.GET_SIZE(288),
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        zIndex: -1
    },
    lovedShop: {
        margin: "auto",
        maxWidth: 320,
    },
    lovedShopsWrapper: {
        width: "100%",
        alignItems: "center"
    },
    lovedShopsSlider: {
        // marginLeft: Constants.GET_SIZE(30)
    },
    block1Text1Wrapper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        padding: 20
    },
    block1Text1: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
    },
    block1Text1_1: {
        fontSize: Constants.FONT_SIZE_BIGGER,
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },
    block1Text1_2: {
        marginBottom: 20
    },

    leftColumn: {
        width: "40%",
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },

    tendanceSauvagesWrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(5, auto)",
        width: "100%",
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "0 25px",
        alignContent: "start",
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        '@media (max-width: 1340px)': {
            gridTemplateColumns: "repeat(4, auto)",
        },
        '@media (max-width: 1120px)': {
            gridTemplateColumns: "repeat(3, auto)",
        },
        '@media (max-width: 850px)': {
            gridTemplateColumns: "repeat(2, auto)",
        }
    },

    // New products sauvages
    newProductsWrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(4, auto)",
        gap: "0 24px",
        // ...GlobalStyle.row,
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "center",
        '@media (max-width: 1400px)': {
            gridTemplateColumns: "repeat(3, auto)",
        },
        '@media (max-width: 1035px)': {
            gridTemplateColumns: "repeat(2, auto)",
        },
        '@media (max-width: 690px)': {
            gridTemplateColumns: "repeat(1, auto)",
        }
    },
    newProductSlideWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },




    // Events

    eventCard: {
        marginTop: Constants.GET_SIZE(38),
        marginBottom: Constants.GET_SIZE(38),
        marginLeft: "auto",
        marginRight: "auto",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: Constants.BORDER_GRAY,
        // marginLeft: Constants.GET_SIZE(45),
        // marginRight: Constants.GET_SIZE(45),
        padding: Constants.GET_SIZE(21),
        maxWidth: "85%"
    },
    eventImage:{
        width: "100%",
        height: 181,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    eventCity:{
        ...GlobalStyle.uppercaseText,
        textAlign: "center",
        marginTop: Constants.GET_SIZE(30),
        maxWidth: Constants.GET_SIZE(319),
        marginLeft: "auto",
        marginRight: "auto"
    },
    eventAddress:{
        color: Constants.FONT_LIGHT_GRAY,
        textAlign: "center",
        maxWidth: Constants.GET_SIZE(319),
        marginLeft: "auto",
        marginRight: "auto"
    },
    eventDate:{
        fontSize: Constants.FONT_SIZE_MEDIUM,
        color: Constants.PINK,
        textAlign: "center",
        marginTop: Constants.GET_SIZE(20),
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE
        }
    },


    deliveryBlock: {
        maxWidth: 1121,
        height: 125,
        marginTop: 32,
        marginBottom: 46,
        textAlign: "center",
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down('xs')]: {
            marginTop: 15,
            marginBottom: 20,
        }
    },
    deliveryBackground: {
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: 124
    },
    deliveryText1: {
        fontSize: Constants.FONT_SIZE_BIG,
        ...GlobalStyle.uppercaseText,
        marginBottom: Constants.GET_SIZE(20),
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE
        }
    },
    deliveryText2: {
        ...GlobalStyle.uppercaseText,
        color: Constants.PINK
    },



    instagramBlock: {
        marginLeft: Constants.GET_SIZE(40),
        marginRight: Constants.GET_SIZE(40),
        marginBottom: Constants.GET_SIZE(120),
        textAlign: "center"
    },
    instagramTitle: {
        fontSize: Constants.FONT_SIZE_BIG,
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },

    slider: {
        [theme.breakpoints.down('xs')]: {
            width: "97%"
        }
    },

    swipe: {
        overflow: 'hidden',
        visibility: 'hidden',
        position: 'relative'
    },
    swipeWrap: {
        overflow: 'hidden',
        position: 'relative'
    },
    swipeWrapComponent: {
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            float: 'left',
            maxWidth: 'initial',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            paddingLeft: 5,
            paddingRight: 5
        }
    },
    swipeWrapComponentNewProduct: {
        [theme.breakpoints.down('xs')]: {
            height: 380,
            backgroundColor: "#F3F3F3"
        }
    },
    swipeWrapComponentTendanceSauvage: {
        [theme.breakpoints.down('xs')]: {
            height: 330
        }
    },
    otherLives: {
        maxWidth: 255,
        width: 255,
        maxHeight: 211,
        height: 211,
        backgroundColor: Constants.BACKGROUND_DARK_GRAY,
        cursor: 'pointer'
    },
    otherLivesText: {
        color: Constants.WHITE,
        fontWeight: "bold"
    }
}));
