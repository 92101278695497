import React, { useCallback } from 'react';
import clsx from 'clsx';
import { TouchableOpacity } from 'react-native-web';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Text from "../Component/FontText";
import Paginator from "./Component/Paginator";
import CustomCheckbox from "../Component/CustomCheckbox";
import { connect } from 'react-redux'
import {Link, useParams, withRouter} from "react-router-dom";

import Criterion from "./Component/Criterion";
import { slugify } from "../../Utils/StringUtils"
import ProductSquare from "./Component/ProductSquare"

import CategoryListStylesFunc from "../../Style/CategoryListStyle"
import * as Constants from "../../Style/Constants"
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import MetaTags from "react-meta-tags";


function Component({history, navigation, familyTree, token, fetchCriteria, criteria, fetchProducts, products, firstPage, lastPage, isLoading, totalCount}) {
    let CategoryListStyles = CategoryListStylesFunc();
    let { familyId, familySlug, categoryId, categorySlug, subCategoryId, subCategorySlug, subCategory2Id, subCategory2Slug, page } = useParams();

    let [respShop, setRespShop] = React.useState(false);
    let [createurShop, setCreateurShop] = React.useState(false);
    let [blogShop, setBlogShop] = React.useState(false);
    let [sizes, setSizes] = React.useState([]);
    let [brands, setBrands] = React.useState([]);
    let [states, setStates] = React.useState([]);
    let [price, setPrice] = React.useState([0, 1500]);
    let [deliveries, setDeliveries] = React.useState([]);
    let [materials, setMaterials] = React.useState([]);
    let [colors, setColors] = React.useState([]);
    let [realPage, setRealPage] = React.useState(1);
    let [displayFilters, setDisplayFilters] = React.useState(false);
    const [families, setFamilies] = React.useState([])

    let [sort, setSort] = React.useState(-1);

    const familiesCriteria = familyTree ? familyTree.filter(family => family.name === 'HOMME' || family.name === 'FEMME' || family.name === 'ENFANT').map(family => {
        return {id: family.id, value: family.name}
    }) : [];

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title += " - Les produits";
    }, []);

    React.useEffect(() => {
        if (page) {
            setRealPage(page)
        }
    }, [page]);

    let reinitFilters = () => {
        setRespShop(false);
        setCreateurShop(false);
        setBlogShop(false);
        setSizes([]);
        setBrands([]);
        setStates([]);
        setPrice([0, 1500]);
        setDeliveries([]);
        setMaterials([]);
        setColors([]);
        setSort(-1);

        searchProducts();
    };

    let searchProducts = () => {
        fetchProducts(
            familyId,
            families,
            subCategory2Id ? subCategory2Id : subCategoryId ? subCategoryId : categoryId ? categoryId : null,
            history.location.state && history.location.state.search ? history.location.state.search : null,
            respShop,
            createurShop,
            blogShop,
            sizes,
            brands,
            states,
            price,
            deliveries,
            materials,
            colors,
            realPage,
            sort === -1 ? 0 : sort
        );
    };

    const searchCriteria = useCallback(() => {
        fetchCriteria(
            brands,
            subCategory2Id ? subCategory2Id : subCategoryId ? subCategoryId : categoryId ? categoryId : null,
            colors,
            deliveries,
            familyId,
            families,
            materials,
            price,
            [respShop ? "1" : null, createurShop ? "2" : null, blogShop ? "3": null], /* shopTypes */
            sizes,
            states
        );
    }, [brands, categoryId, subCategory2Id, subCategoryId, colors, deliveries, familyId, families, materials, price, respShop, createurShop, blogShop, sizes, states])

    React.useEffect(() => {
        searchCriteria();
    }, [searchCriteria]);

    React.useEffect(() => {
        searchProducts();
    }, [realPage, familyId, categoryId, subCategoryId, subCategory2Id, history.location.state, blogShop, respShop, createurShop, sort]);

    let family = null, category = null, subCategory = null, smallestCat = null;
    let familiesDom = [];
    let categoriesDom = [];
    let subCategoriesDom = [];
    let subCategories2Dom = [];

    let generateUrl = (famId, famName, catId, catName, subCatId, subCatName, subCat2Id, subCat2Name) => {
        const arr = ["category"];
        arr.push(famId);
        arr.push(slugify(famName));
        if (catId) {
            arr.push(catId);
            arr.push(slugify(catName));
            if (subCatId) {
                arr.push(subCatId);
                arr.push(slugify(subCatName));
                if (subCat2Id) {
                    arr.push(subCat2Id);
                    arr.push(slugify(subCat2Name));
                }
            }
        }

        return "/" + arr.join("/");
    };

    let getCategoryUrl = (family, category, subCat, subCat2) => {
        let result = "/category/" + family.id + "/" + slugify(family["name"]);
        const components = [];
        if (category) {
            components.push(category.id);
            components.push(slugify(category.name));
            if (subCat) {
                components.push(subCat.id);
                components.push(slugify(subCat.name));
                if (subCat2) {
                    components.push(subCat2.id);
                    components.push(slugify(subCat2.name));
                }
            }
        }

        return result + "/" + components.join("/");
    }

    let arianeDom = [];
    // let currentCat = family;
    // arianeDom.unshift(<Link to={getCategoryUrl(family, currentCat)} style={{textDecoration: "none", color: "black"}}><Text className={CategoryListStyles.arianeText}>{currentCat["name"]}</Text></Link>);
    // while (currentCat["parent"] != null) {
    //     currentCat = currentCat["parent"];
    //     arianeDom.unshift(<Link to={getCategoryUrl(family, currentCat)} style={{textDecoration: "none", color: "black"}}><Text className={CategoryListStyles.arianeText}>{currentCat["name"]}&nbsp;/&nbsp;</Text></Link>);
    // }


    if (familyTree) {
        for (const fam of familyTree) {
            familiesDom.push(
                <TouchableOpacity key={fam["name"]}  onPress={() => history.push(generateUrl(fam["id"], fam["name"]))}>
                    <Text className={clsx(CategoryListStyles.familyTreeText, CategoryListStyles.familyTreeFamily, {[CategoryListStyles.selected] : fam["id"] == familyId && !categoryId})}>{fam["name"]}</Text>
                </TouchableOpacity>
            );
            if (fam["id"] == familyId) {
                arianeDom.push(<Link to={getCategoryUrl(fam, null)} style={{textDecoration: "none", color: "black"}}><Text className={CategoryListStyles.arianeText}>{fam["name"]}</Text></Link>);
                family = fam;
                for (const cat of fam["children"]) {
                    categoriesDom.push(
                        <TouchableOpacity key={cat["name"]}  onPress={() => history.push(generateUrl(fam["id"], fam["name"], cat["id"], cat["name"]))}>
                            <Text className={clsx(CategoryListStyles.familyTreeText, CategoryListStyles.familyTreeCategory, {[CategoryListStyles.selected]: cat["id"] == categoryId && !subCategoryId})}>{cat["name"]}</Text>
                        </TouchableOpacity>
                    );
                    if (cat["id"] == categoryId) {
                        arianeDom.push(<Text className={CategoryListStyles.arianeText}>&nbsp;/&nbsp;</Text>);
                        arianeDom.push(<Link to={getCategoryUrl(fam, cat)} style={{textDecoration: "none", color: "black"}}><Text className={CategoryListStyles.arianeText}>{cat["name"]}</Text></Link>);
                        category = cat;
                        smallestCat = cat;
                        if (cat["children"]) {
                            for (const subCat of cat["children"]) {
                                subCategoriesDom.push(
                                    <TouchableOpacity key={subCat["name"]}
                                                      onPress={() => history.push(generateUrl(fam["id"], fam["name"], cat["id"], cat["name"], subCat["id"], subCat["name"]))}>
                                        <Text
                                            className={clsx(CategoryListStyles.familyTreeText, CategoryListStyles.familyTreeSubCategory, {[CategoryListStyles.selected]: subCat["id"] == subCategoryId && !subCategory2Id})}>{subCat["name"]}</Text>
                                    </TouchableOpacity>
                                );
                                if (subCat["id"] == subCategoryId) {
                                    arianeDom.push(<Text className={CategoryListStyles.arianeText}>&nbsp;/&nbsp;</Text>);
                                    arianeDom.push(<Link to={getCategoryUrl(fam, cat, subCat)} style={{textDecoration: "none", color: "black"}}><Text className={CategoryListStyles.arianeText}>{subCat["name"]}</Text></Link>);
                                    subCategory = subCat;
                                    smallestCat = subCat;
                                    if (subCat["children"]) {
                                        for (const subCat2 of subCat["children"]) {
                                            subCategories2Dom.push(
                                                <TouchableOpacity key={subCat2["name"]}
                                                                  onPress={() => history.push(generateUrl(fam["id"], fam["name"], cat["id"], cat["name"], subCat["id"], subCat["name"], subCat2["id"], subCat2["name"]))}>
                                                    <Text
                                                        className={clsx(CategoryListStyles.familyTreeText, CategoryListStyles.familyTreeSubCategory2, {[CategoryListStyles.selected] : subCat2["id"] == subCategory2Id})}>{subCat2["name"]}</Text>
                                                </TouchableOpacity>
                                            );
                                            if (subCat2["id"] == subCategory2Id) {
                                                arianeDom.push(<Text className={CategoryListStyles.arianeText}>&nbsp;/&nbsp;</Text>);
                                                arianeDom.push(<Link to={getCategoryUrl(fam, cat, subCat, subCat2)} style={{textDecoration: "none", color: "black"}}><Text className={CategoryListStyles.arianeText}>{subCat2["name"]}</Text></Link>);
                                                smallestCat = subCat2;
                                            }
                                        }
                                        subCategoriesDom.push(<Box className={CategoryListStyles.familyTreeSubCategory2Wrapper}>{subCategories2Dom}</Box>);
                                    }
                                }
                            }
                            categoriesDom.push(<Box className={CategoryListStyles.familyTreeSubCategoryWrapper}>{subCategoriesDom}</Box>);
                        }
                    }
                }
                familiesDom.push(<Box className={CategoryListStyles.familyTreeCategoryWrapper}>{categoriesDom}</Box>);
            }
        }

        let productsDom = [];
        if (products) {
            for (const product of products) {
                productsDom.push(<Grid xs={6} sm className={CategoryListStyles.productWrapperGrid}><ProductSquare product={product} wrapperStyle={CategoryListStyles.productWrapper}/></Grid>)
            }
        }

        let recursiveSizeTypes = (sizeTypes, cat) => {
            if (cat.sizeType && sizeTypes.indexOf(cat.sizeType) === -1) {
                sizeTypes.push(cat.sizeType);
            }
            if (cat.children && cat.children.length > 0) {
                for (const c of cat.children) {
                    recursiveSizeTypes(sizeTypes, c);
                }
            }
        };

        let filteredSizes;
        let sizeTypes = [];
        if (!smallestCat && !family) {
            filteredSizes = criteria.sizes;
        } else {
            if (smallestCat) {
                recursiveSizeTypes(sizeTypes, smallestCat);
            } else if (family) {
                for (const c of family.children) {
                    recursiveSizeTypes(sizeTypes, c);
                }
            }
            filteredSizes = criteria.sizes.filter(size => (sizeTypes.indexOf(size.type) !== -1 && (!family || family.name !== "Curvy" || size.curvy)));
        }

        let recursiveMaterialTypes = (materialTypes, cat) => {
            if (cat.materialType && materialTypes.indexOf(cat.materialType) === -1) {
                materialTypes.push(cat.materialType);
            }
            if (cat.children && cat.children.length > 0) {
                for (const c of cat.children) {
                    recursiveMaterialTypes(materialTypes, c);
                }
            }
        };

        let filteredMaterials;
        let materialTypes = [];
        if (!smallestCat && !family) {
            filteredMaterials = criteria.materials;
        } else {
            if (smallestCat) {
                recursiveMaterialTypes(materialTypes, smallestCat);
            } else if (family) {
                for (const c of family.children) {
                    recursiveMaterialTypes(materialTypes, c);
                }
            }
            let localMaterials = criteria.materials.filter(material => materialTypes.indexOf(material.type) !== -1);
            filteredMaterials = [];
            for (const mat of localMaterials) {
                if (!(mat.value in filteredMaterials)) {
                    filteredMaterials[mat.value] = {"value": mat.value, "ids": [mat.id]}
                } else {
                    filteredMaterials[mat.value]["ids"].push(mat.id);
                }
            }
            filteredMaterials = Object.values(filteredMaterials);

            filteredMaterials.sort((a, b) =>  {
                return a.value === "J'ai un doute" ? 1 : (b.value === "J'ai un doute" ? -1 : 0)
            });
            // filteredMaterials = criteria.materials.filter(material => materialTypes.indexOf(material.type) !== -1);
        }


        return (
            <Box>
                <MetaTags>
                    <title>{family ? "Vide dressing " + family["name"] + (smallestCat ? " : " + smallestCat.name : "") : history.location.state && history.location.state.search ? "Rechercher un article sur Violette Sauvage, vide dressing en ligne" : "Tous les produits de Violette Sauvage, vide dressing en ligne"}</title>
                    <meta name="description"
                          content={family ? "Prêt-à-porter " + family["name"] + (smallestCat ? ", " + smallestCat.name : "") + " : Des articles de seconde-main disponibles sur Violette Sauvage !" : "Recherchez & achetez un article de seconde main sur le meilleur vide-dressing en ligne Violette Sauvage"}/>
                </MetaTags>
                <Box className={CategoryListStyles.container}>
                    <Grid item container xs={12} sm style={{marginTop: 20}}>
                        {arianeDom}
                    </Grid>
                    <Text className={CategoryListStyles.title} component={"h1"}>{family ? family["name"] + (smallestCat ? " / " + smallestCat.name : "") : history.location.state && history.location.state.search ? "Recherche" : "Tous les produits"}</Text>
                    <Box className={clsx(CategoryListStyles.row, CategoryListStyles.subContainer)}>
                        <Box className={CategoryListStyles.familyTree}>
                            <div className={CategoryListStyles.familyTreeInner}>
                            {familiesDom}
                            </div>
                        </Box>
                        <Button onClick={() => setDisplayFilters(!displayFilters)} disableElevation variant={"contained"} color={"primary"} className={CategoryListStyles.displayFiltersButton} >
                            {displayFilters ? "Cacher les filtres" : "Afficher les filtres"}
                        </Button>
                        <Box className={clsx(CategoryListStyles.criteriaWrapper, {[CategoryListStyles.criteriaWrapperDisplay]: displayFilters})}>
                            <Box className={CategoryListStyles.row}>
                                <CustomCheckbox
                                    title={<Text className={CategoryListStyles.checkboxLabel}>Boutiques créateurs</Text>}
                                    containerClass={CategoryListStyles.checkBox}
                                    checkedColor={Constants.PINK}
                                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                                    checked={createurShop}
                                    onPress={() => setCreateurShop(!createurShop)}
                                    size={"large"}
                                />
                                <CustomCheckbox
                                    title={<Text className={CategoryListStyles.checkboxLabel}>Boutiques blogueurs</Text>}
                                    containerClass={CategoryListStyles.checkBox}
                                    checkedColor={Constants.PINK}
                                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                                    checked={blogShop}
                                    onPress={() => setBlogShop(!blogShop)}
                                    size={"large"}
                                />
                                <CustomCheckbox
                                    title={<Text className={CategoryListStyles.checkboxLabel}>Boutiques responsables</Text>}
                                    containerClass={CategoryListStyles.checkBox}
                                    checkedColor={Constants.PINK}
                                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                                    checked={respShop}
                                    onPress={() => setRespShop(!respShop)}
                                    size={"large"}
                                />
                            </Box>
                            <Box className={CategoryListStyles.row}>
                                {criteria.sizes && <Criterion name={"Taille"} values={filteredSizes} setValue={setSizes} selected={sizes} width={Constants.GET_SIZE(103)} />}
                                {criteria.brands && <Criterion name={"Marque"} values={criteria.brands} searchable={true} setValue={setBrands} selected={brands} width={Constants.GET_SIZE(194)} luxury={family && family.name === "Luxe"}/>}
                                {criteria.states && <Criterion name={"Etat"} values={criteria.states} setValue={setStates} selected={states} width={Constants.GET_SIZE(159)} />}
                                <Criterion name={"Prix"} setValue={setPrice} selected={price} scale={isLoading ? null : {min: 0, max:1500}} width={Constants.GET_SIZE(207)} />
                                <Criterion name={"Livraison"} values={isLoading ? [] : [{id: 0, value: "Chronopost / MondialRelay"}, {id: 1, value: "Click&Collect"}, {id: 2, value: "Remise en main propre"}]} setValue={setDeliveries} selected={deliveries} width={Constants.GET_SIZE(234)} />
                                {criteria.materials && <Criterion name={"Matières"} values={filteredMaterials} searchable={true} setValue={setMaterials} selected={materials} width={Constants.GET_SIZE(139)} />}
                                {criteria.colors && <Criterion name={"Couleurs"} values={criteria.colors} setValue={setColors} selected={colors} width={Constants.GET_SIZE(129)} />}
                                {familySlug === 'luxe' && <Criterion name={"Type"} setValue={setFamilies} selected={families} values={familiesCriteria} />}
                                <Grid item container style={{maxWidth: 500, paddingBottom: 30, textAlign: "center"}} alignItems={"flex-end"}>
                                    <Grid item xs={12} sm>
                                        <Button variant={"contained"} color={"primary"} disableElevation onClick={searchProducts} className={CategoryListStyles.button}>
                                            Filtrer
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm>
                                        <Button variant={"contained"} disableElevation onClick={reinitFilters} className={CategoryListStyles.button}>
                                            Réinitialiser
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    <Grid container className={CategoryListStyles.productsWrapper}>
                        {productsDom.length > 0 ? <Grid container justify={"space-between"} style={{paddingTop: 5}}>
                            <Grid item alignItems={"center"} style={{display: "flex"}}>
                                <Text className={CategoryListStyles.nbResultsText}>{totalCount} résultats</Text>
                            </Grid>
                            <Grid item alignItems={"center"} style={{display: "flex"}}>
                                <Select
                                    variant={"outlined"}
                                    className={clsx(CategoryListStyles.pickerWrapper)}
                                    onChange={(event) => {
                                        setSort(event.target.value);
                                    }}
                                    value={sort}
                                >
                                    <MenuItem value={-1} disabled>Trier par</MenuItem>
                                    <MenuItem value={0} >Plus récent</MenuItem>
                                    <MenuItem value={1} >Prix croissant</MenuItem>
                                    <MenuItem value={2} >Prix décroissant</MenuItem>
                                </Select>
                            </Grid>
                        </Grid> : !isLoading ? <Text className={CategoryListStyles.noProductsText}>Aucun produit ne correspond à votre recherche.</Text> : null}
                        {productsDom}
                    </Grid>
                    {familyId && <Paginator firstPage={firstPage} lastPage={lastPage} page={parseInt(realPage)} currentUrl={generateUrl(familyId, familySlug, categoryId, categorySlug, subCategoryId, subCategorySlug, subCategory2Id, subCategory2Slug)}/>}
                    {!familyId && <Paginator firstPage={firstPage} lastPage={lastPage} page={parseInt(realPage)} setPage={setRealPage}/>}
                </Box>
            </Box>
        );
    } else {
        return (
            <Box>
            </Box>
        )
    }
}

const mapStateToProps = state => {
    return {
        familyTree: state.family.familyTree,
        token: state.login.token,
        criteria: state.criteria,
        products: state.product.productsList,
        firstPage: state.product.pagination.first,
        lastPage: state.product.pagination.last,
        totalCount: state.product.totalCount,
        isLoading: state.loading.isLoadingGeneral
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCriteria: (brands, category, colors, deliveries, family, families, materials, price, shopTypes, sizes, states) => {
            dispatch({type: 'FETCH_CRITERIA_REQUESTED', brands, category, colors, deliveries, family, families, materials, price, shopTypes, sizes, states})
        },
        fetchProducts: (family, families, category, name, respShop, createurShop, blogShop, sizes, brands, states, price, deliveries, materials, colors, page, sort) => {
            dispatch({type: 'SEARCH_PRODUCTS_REQUESTED', family, families, category, name, respShop, createurShop, blogShop, sizes, brands, states, price, deliveries, materials, colors, page, sort})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
