import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    block: {
        maxWidth: 1352,
        margin: "auto",
        backgroundColor: Constants.WHITE,
        paddingTop: 50,
        paddingBottom: 70,
        [theme.breakpoints.down('xs')]: {
            padding: 20
        }
    },
    blockPinkCard: {
        backgroundColor: Constants.PINK,
        maxWidth: 340,
        // maxHeight: 257,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 50,
        paddingBottom: 50,
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "initial",
            maxHeight: "initial",
            padding: 20
        }
    },
    blockPinkCardTitle: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        fontWeight: "bold",
        marginBottom: 25,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }

    },
    blockPinkCardContent: {
        color: Constants.WHITE,
        marginBottom: 25

    },
    blockPinkCardLink: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        textDecorationLine: "underline"

    },
    blockMiddleCard: {
        maxWidth: 672,
        // maxHeight: 257,
        textAlign: "center",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 50,
        paddingBottom: 50,
        [theme.breakpoints.down('xs')]: {
            maxWidth: "initial",
            maxHeight: "initial",
            padding: 20
        }

    },
    blockMiddleCardContent: {
        maxWidth: 340,
        margin: "auto"
    },
    blockMiddleCardTitle: {
        fontSize: Constants.FONT_SIZE_BIG,
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        color: Constants.WHITE,
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    blockMiddleCardText: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        marginBottom: 20
    },
    blockMiddleCardLink: {
        ...GlobalStyle.uppercaseText,
        backgroundColor: Constants.WHITE,
        paddingLeft: 70,
        paddingRight: 70,
        paddingTop: 16,
        paddingBottom: 16,
    },
    blockMiddleCardLinkWrapper: {
        alignItems: "center"
    }
}));
