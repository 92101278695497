import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        width: Constants.GET_SIZE(322),
        height: Constants.GET_SIZE(354),
        marginBottom: Constants.GET_SIZE(42),
    },
    cardActionArea: {
        paddingTop: 35,
        paddingBottom: 40,
    },
    image: {
        width: 180,
        height: 180,
        // marginLeft: 70,
        // marginRight: 70,
        margin: "auto",
        [theme.breakpoints.down('xs')]: {
            width: "80%",
            aspectRatio: 1,
            height: 'auto',
        }
    },
    shopName: {
        textAlign: "center",
        marginTop: 85,

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    overlay: {
        position: "absolute",
        top: 0,
        bottom: Constants.GET_SIZE(85),
        left: 0,
        right: 0,
        backgroundColor: "#FFFFFFA0",
        textAlign: "center",
        justifyContent: "center",
        padding: Constants.GET_SIZE(30),
        transition: "opacity 0.2s",
        opacity: 0,
        "&:hover": {
            opacity: 1,
            [theme.breakpoints.down('xs')]: {
                opacity: 0,
            }
        }
    }
}));
