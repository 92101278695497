import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        flexDirection: "row",
        alignItems: "center",
        padding: Constants.GET_SIZE(10),
    },
    icon: {
        marginRight: Constants.GET_SIZE(5),
    }
}));
