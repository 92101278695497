import React from "react";

import Box from "@material-ui/core/Box";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckBox from "@material-ui/core/Checkbox";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import CustomCheckboxStylesFunc from '../../Style/CustomCheckboxStyle'

function Component({size, containerStyle, containerClass, title, checkedColor, uncheckedColor, checked, checkedIcon, onPress, rightIcon, fullWidth, error, disabled}) {
    let CustomCheckboxStyles = CustomCheckboxStylesFunc();

    return <FormGroup>
        <FormControlLabel
            control={
                <CheckBox
                    color={"primary"}
                    icon={<CheckBoxOutlineBlankIcon fontSize={size} />}
                    checkedIcon={<CheckBoxIcon fontSize={size} />}
                    checked={checked}
                    disabled={disabled}
                    onChange={event => onPress(event.target.checked)}
                />
            }
            label={title}
            labelPlacement={rightIcon ? "start" : "end"}
            style={fullWidth ? {width: "100%"} : {}} />
        {error && <FormHelperText error style={{marginBottom: 20}}>{error.message}</FormHelperText>}
    </FormGroup>
    // let iconStyle = [];
    // let realSize = size;
    // realSize = (parseFloat(size) - 0.3) + "vw";
    // if (checked) {
    //     iconStyle.push({color: checkedColor});
    // } else {
    //     iconStyle.push({color: uncheckedColor});
    // }
    //
    // return (
    //     <TouchableOpacity style={containerStyle} className={clsx(CustomCheckboxStyles.wrapper, containerClass)} onPress={onPress}>
    //         {!rightIcon && <Box style={{width: realSize}}>
    //             <FontAwesome name={checked ? checkedIcon ? checkedIcon : DEFAULT_CHECKED_ICON : DEFAULT_UNCHECKED_ICON} className={CustomCheckboxStyles.icon} style={[iconStyle, {fontSize: realSize}]} />
    //         </Box>}
    //         {title}
    //         {rightIcon && <Box style={{width: realSize}}>
    //             <FontAwesome name={checked ? checkedIcon ? checkedIcon : DEFAULT_CHECKED_ICON : DEFAULT_UNCHECKED_ICON} className={CustomCheckboxStyles.icon} style={[iconStyle, {fontSize: realSize}]} />
    //         </Box>}
    //     </TouchableOpacity>
    // );
}

export default Component;
