import { call, put, takeLatest, cancelled } from 'redux-saga/effects'
import {
    ApiFetchMe,
    ApiUserCreate,
    RegisterNewsletter,
    EmptyWishlist,
    SaveUser,
    FetchUsers,
    ApiFetchUser,
    BlockUser,
    ReceivePayment,
    FetchBestSellers,
    FetchBestBuyers,
    DeleteUser,
    FetchAllUsers,
    SubscribeToFirebase, SendFirebasePush
} from '../Endpoint/User'
import { ApiLogin } from "../Endpoint/Login";
import { handleError } from "../../Utils/SagaUtils";
import {store} from "../../Store";

// worker Saga: will be fired on REGISTER_REQUESTED actions
function* register(action) {
    try {
        let user = yield call(ApiUserCreate, action.payload.login, action.payload.mail, action.payload.pwd, action.payload.newsletter, action.payload.recaptchaToken);

        yield put({type: "REGISTER_SUCCEEDED"});

        user = yield call(ApiLogin, action.payload.mail, action.payload.pwd);
        const token = user.data.token;
        yield put({type: "LOGIN_SUCCEEDED", token});
    } catch (e) {
        yield put(handleError("REGISTER_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchMe(action) {
    try {
        let result = yield call(ApiFetchMe, action.payload.token);
        yield put({type: "FETCH_ME_SUCCEEDED", user: result.data[0]})

    } catch (e) {
        yield put(handleError("FETCH_ME_FAILED", e));
    } finally {
        if (yield cancelled()) {
            // yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchUser(action) {
    try {
        let result = yield call(ApiFetchUser, action.id);
        yield put({type: "FETCH_USER_SUCCEEDED", user: result.data})

    } catch (e) {
        yield put(handleError("FETCH_USER_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* emptyWishlist(action) {
    if (store.getState().login.token) {
        try {
            let result = yield call(EmptyWishlist);
            yield put({type: "EMPTY_WISHLIST_SUCCEEDED", user: result.data[0]})

        } catch (e) {
            yield put(handleError("EMPTY_WISHLIST_FAILED", e));
        } finally {
            if (yield cancelled()) {
                yield put({type: "REMOVE_LOADER", general: false});
            }
        }
    } else {
        yield put({type: "EMPTY_WISHLIST_SUCCEEDED", anon: true})
    }
}

function* saveUser(action) {
    try {
        let result = yield call(SaveUser, action.user);
        yield put({type: "FETCH_ME_SUCCEEDED", user: result.data})

        yield put({type: "DISPLAY_USER_CONFIRMATION_MODAL"})
    } catch (e) {
        yield put(handleError("SAVE_USER_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* registerNewsletter(action) {
    try {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(action.mail)) {
            yield put({
                type: "REGISTER_NEWSLETTER_FAILED",
                errorCode: "local_error",
                message: "Veuillez entrer une adresse e-mail valide."
            })
        } else {
            let result = yield call(RegisterNewsletter, action.mail);
            if (result.data.status === 'OK') {
                yield put({type: "REGISTER_NEWSLETTER_SUCCEEDED"})
            } else {
                yield put({
                    type: "REGISTER_NEWSLETTER_FAILED",
                    errorCode: result.data.code,
                    message: result.data.message
                })
            }
        }
    } catch (e) {
        yield put(handleError("REGISTER_NEWSLETTER_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchUsers(action) {
    try {
        let result = yield call(FetchUsers, action.search, action.itemsPerPage, action.page);
        yield put({type: "FETCH_USERS_SUCCEEDED", users: result.data["hydra:member"], pagination: result.data["hydra:view"], keepLoading: action.hideLoader})

    } catch (e) {
        yield put(handleError("FETCH_USERS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}

function* fetchBestSellers(action) {
    try {
        let result = yield call(FetchBestSellers, action.itemsPerPage, action.page);
        yield put({type: "FETCH_BEST_SELLERS_SUCCEEDED", users: result.data["hydra:member"], pagination: result.data["hydra:view"]})

    } catch (e) {
        yield put(handleError("FETCH_BEST_SELLERS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchBestBuyers(action) {
    try {
        let result = yield call(FetchBestBuyers, action.itemsPerPage, action.page);
        yield put({type: "FETCH_BEST_BUYERS_SUCCEEDED", users: result.data["hydra:member"], pagination: result.data["hydra:view"]})

    } catch (e) {
        yield put(handleError("FETCH_BEST_BUYERS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* blockUser(action) {
    try {
        let result = yield call(BlockUser, action.id, action.block, action.text);
        yield put({type: "BLOCK_USER_SUCCEEDED", user: result.data})

    } catch (e) {
        yield put(handleError("BLOCK_USER_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* deleteUser(action) {
    try {
        let result = yield call(DeleteUser, action.id);
        yield put({type: "DELETE_USER_SUCCEEDED", id: action.id})

    } catch (e) {
        yield put(handleError("DELETE_USER_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function*  receivePayment(action) {
    try {
        let result = yield call(ReceivePayment, action.amount);
        yield put({type: "RECEIVE_PAYMENT_SUCCEEDED", user: result.data})

    } catch (e) {
        yield put(handleError("RECEIVE_PAYMENT_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchAll(action) {
    try {
        let result = yield call(FetchAllUsers);
        yield put({type: "FETCH_ALL_USERS_SUCCEEDED", users: result.data["hydra:member"]})

    } catch (e) {
        yield put(handleError("FETCH_ALL_USERS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* subscribeToFirebase(action) {
    try {
        let result = yield call(SubscribeToFirebase, action.token);
        yield put({type: "SUBSCRIBE_TO_FIREBASE_SUCCEEDED", keepLoading: true})

    } catch (e) {
        yield put(handleError("SUBSCRIBE_TO_FIREBASE_FAILED", e));
    } finally {

    }
}

function* sendFirebasePush(action) {
    try {
        let result = yield call(SendFirebasePush, action.title, action.message, action.push, action.mail, action.target);
        yield put({type: "SEND_FIREBASE_PUSH_SUCCEEDED"})

    } catch (e) {
        yield put(handleError("SEND_FIREBASE_PUSH_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}


function* mySaga() {
    yield takeLatest("REGISTER_REQUESTED", register);
    yield takeLatest("FETCH_ME_REQUESTED", fetchMe);
    yield takeLatest("FETCH_USERS_REQUESTED", fetchUsers);
    yield takeLatest("FETCH_BEST_SELLERS_REQUESTED", fetchBestSellers);
    yield takeLatest("FETCH_BEST_BUYERS_REQUESTED", fetchBestBuyers);
    yield takeLatest("FETCH_USER_REQUESTED", fetchUser);
    yield takeLatest("REGISTER_NEWSLETTER_REQUESTED", registerNewsletter);
    yield takeLatest("EMPTY_WISHLIST_REQUESTED", emptyWishlist);
    yield takeLatest("SAVE_USER_REQUESTED", saveUser);
    yield takeLatest("BLOCK_USER_REQUESTED", blockUser);
    yield takeLatest("DELETE_USER_REQUESTED", deleteUser);
    yield takeLatest("RECEIVE_PAYMENT_REQUESTED", receivePayment);
    yield takeLatest("FETCH_ALL_USERS_REQUESTED", fetchAll);
    yield takeLatest("SUBSCRIBE_TO_FIREBASE_REQUESTED", subscribeToFirebase);
    yield takeLatest("SEND_FIREBASE_PUSH_REQUESTED", sendFirebasePush);
}

export default mySaga;
