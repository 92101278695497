import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"


export default makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            width: "90%",
        }
    },
    row: {
        ...GlobalStyle.row,
    },
    title: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        width: "max-content",
        marginBottom: Constants.GET_SIZE(15),

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    button: {
        justifyContent: "center",
        backgroundColor: Constants.PINK,
        // width: Constants.GET_SIZE(150),
        // height: Constants.GET_SIZE(40),
        padding: 20,
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },

    contentWrapper: {
        ...GlobalStyle.row,
        marginBottom: Constants.GET_SIZE(50),
        width: "100%"
    },
    shopsWrapper: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        width: "100%",
        marginLeft: Constants.GET_SIZE(25),
        paddingLeft: Constants.GET_SIZE(50),
        paddingTop: Constants.GET_SIZE(50),
        paddingBottom: Constants.GET_SIZE(75),
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
        }
    },
    shopWrapper: {
        ...GlobalStyle.rowCentered,
        justifyContent: "space-between",
        backgroundColor: Constants.WHITE,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        width: "100%",
        maxWidth: 900,
        marginBottom: 25,
        paddingLeft: 20,
        paddingRight: 20,
        // paddingTop: 20,
        // paddingBottom: 20,
    },
    shopImage: {
        width: Constants.GET_SIZE(60),
        height: Constants.GET_SIZE(60),
        borderRadius: "50%",
        marginRight: Constants.GET_SIZE(25),
        marginTop: 10,
        marginBottom: 10,
    },
    shopContact: {

        marginRight: Constants.GET_SIZE(25),
    },
    shopTextGrid: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center"
        }
    },
    shopTextWrapper: {
        // maxWidth: Constants.GET_SIZE(546),
    },
    shopName: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    shopNbProducts: {

        color: Constants.FONT_GRAY
    }

}));
