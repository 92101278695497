import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"

export default makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: "min(320px, 90vw)",
        marginBottom: Constants.GET_SIZE(12),
        marginTop: Constants.GET_SIZE(13),
        backgroundColor: "transparent",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY
        }

    },
    wrapperWithBackground: {
        backgroundColor: Constants.WHITE,
        // marginLeft: Constants.GET_SIZE(12),
        // marginRight: Constants.GET_SIZE(13),
        borderColor: Constants.BORDER_GRAY,
        borderWidth: Constants.GET_SIZE(1),
        borderStyle: "solid",
        // height: Constants.GET_SIZE(354)
    },
    cardActionArea: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardActions: {
        width: "100%",
        marginTop: "auto",
    },
    smallCardActions: {
        height: "10%"
    },
    row: {
        ...GlobalStyle.row,
        marginTop: Constants.GET_SIZE(15),
        marginBottom: Constants.GET_SIZE(15),
        paddingLeft: Constants.GET_SIZE(25),
        paddingRight: Constants.GET_SIZE(25),
        justifyContent: "space-between",
        width: "100%"
    },
    column: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: "100%"
    },
    image: {
        width: Constants.GET_SIZE(180),
        height: Constants.GET_SIZE(180),
        marginLeft: Constants.GET_SIZE(65),
        marginRight: Constants.GET_SIZE(65),
        marginTop: Constants.GET_SIZE(20),
        marginBottom: Constants.GET_SIZE(20)
    },
    imageWithoutBackground: {
        width: Constants.GET_SIZE(170),
        height: Constants.GET_SIZE(170),
        marginLeft: Constants.GET_SIZE(35),
        marginRight: Constants.GET_SIZE(35),
        marginTop: Constants.GET_SIZE(10),
        marginBottom: Constants.GET_SIZE(10)
    },
    productName: {
        ...GlobalStyle.uppercaseText,
        textAlign: "center",
        maxWidth: 200,
        width: "100%",
        margin: "auto",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    colorAndSize: {

        color: Constants.FONT_MEDIUM_GRAY,
        textAlign: 'center'
    },
    price: {
        fontSize: Constants.FONT_SIZE_BIG,
        color: Constants.PINK,
        textAlign: "center",
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },
    heartIcon: {
        width: 30,
        height: 30,
        color: Constants.LIGHT_GRAY,
        fill: "none"
    },
    starIcon: {
        width: 30,
        height: 30,
        fill: Constants.LIGHT_GRAY
    },
    heartIconSelected: {
        color: Constants.PINK,
        fill: "currentColor"
    },
    starIconSelected: {
        width: 30,
        height: 30,
        fill: Constants.PINK
    },
    cartIcon: {
        width: 30,
        height: 30,
        color: Constants.LIGHT_GRAY,
        stroke: "none"
    },
    cartIconSelected: {
        color: Constants.PINK,
    },

    wishlistButtons: {

    },
    wishlistWhiteButton: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        justifyContent: "center",
        width: Constants.GET_SIZE(190),
        height: Constants.GET_SIZE(40)
    },
    wishlistWhiteButtonLabel: {
        ...GlobalStyle.buttonLabel,
        color: Constants.BLACK,
        fontSize: Constants.FONT_SIZE_SMALL,

    },
    wishlistPinkButton: {
        backgroundColor: Constants.PINK,
        justifyContent: "center",
        width: Constants.GET_SIZE(190),
        height: Constants.GET_SIZE(40),
        marginBottom: Constants.GET_SIZE(50)
    },
    wishlistPinkButtonLabel: {
        ...GlobalStyle.buttonLabel,
        fontSize: Constants.FONT_SIZE_SMALL,
    },
    discount: {
        position: "absolute",
        top: 5,
        right: 5,

        borderRadius: 50,
        backgroundColor: Constants.PINK,
        color: Constants.WHITE,
        padding: 5,
        paddingRight: 10,
        paddingLeft: 10,
    },
    priceWithoutDiscount: {
        position: "absolute",
        color: Constants.PINK,
        textAlign: "center",
        width: "100%",
        // margin: "auto",
        top: -10,
        textDecoration: "line-through"
    },
    sold: {
        position: "absolute",
        top: 85,
        backgroundColor: Constants.PINK,
        color: "white",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 35,
        width: 60,
        marginLeft: -55,
        left: "50%",
        textAlign: "center"
    },
    cantAddLive: {
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: 10,
            height: 59,
        }
    }
}));
