import React from "react";
import clsx from 'clsx';

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import CardMedia from "@material-ui/core/CardMedia"

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Text from "../Component/FontText";
import TextInput from "../Component/FontInput";
import { connect } from 'react-redux'
import { FontAwesome } from '../../FontAwesome';
import { withRouter } from "react-router-dom";

import FooterStylesFunc from "../../Style/FooterStyle"
import * as Constants from "../../Style/Constants"

import AmericanExpressLogo from "../../Assets/Images/cb_american_express_logo.png"
import DeltaLogo from "../../Assets/Images/cb_delta_logo.png"
import MastercardLogo from "../../Assets/Images/cb_mastercard_logo.png"
import VisaLogo from "../../Assets/Images/cb_visa_logo.png"
import TikTokLogo from "../../Assets/Images/logo_tiktok.svg"
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutline from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxOutlineChecked from "@material-ui/icons/CheckBoxOutlined";
import ErrorStyleFunc from "../Styles/Error";


function Component({history, newsletterError, newsletterOk, registerNewsletter, confirmNewsletter}) {
    let FooterStyles = FooterStylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    let [mail, setMail] = React.useState("");
    let [cgu, setCgu] = React.useState(false);
    let [cguError, setCguError] = React.useState(false);

    const handleNewsletterClick = (mail) => {
        if (cgu) {
            setCguError(false);
            registerNewsletter(mail);
        } else {
            setCguError(true);
        }
    }

    return (
        <Box>
            <IconButton className={FooterStyles.upArrowWrapper} onClick={() => window.scrollTo(0, 0)}>
                <KeyboardArrowUpIcon className={FooterStyles.upArrow}/>
            </IconButton>
            <Box className={FooterStyles.firstBlock}>
                <Grid container className={FooterStyles.row1} justify={"space-around"}>
                    <Grid item className={clsx(FooterStyles.column, FooterStyles.hiddenSmall)}>
                        <Text className={FooterStyles.columnTitle}>Aide et assistance</Text>

                        <Link href={"/contact"}><Text className={FooterStyles.columnItem}>Nous contacter</Text></Link>
                        <Link href={"/cgv"}><Text className={FooterStyles.columnItem}>CGU/CGV</Text></Link>
                        <Link href={"/politique_de_confidentialite"}><Text className={FooterStyles.columnItem}>Politique de confidentialité</Text></Link>
                        <Link href={"/faq"}><Text className={FooterStyles.columnItem}>FAQ</Text></Link>
                    </Grid>
                    <Grid item className={clsx(FooterStyles.column, FooterStyles.hiddenSmall)}>
                        <Text className={FooterStyles.columnTitle}>à propos de nous</Text>

                        <Link href={"/meetingVS"}><Text className={FooterStyles.columnItem}>A la rencontre</Text>
                            <Text className={FooterStyles.columnItem}>de Violette Sauvage</Text></Link>
                    </Grid>
                    <Grid item className={clsx(FooterStyles.column, FooterStyles.hiddenSmall)}>
                        <Text className={FooterStyles.columnTitle}>Vos services</Text>

                        <Link href={"/ouvrir_une_boutique"}><Text className={FooterStyles.columnItem}>Ouvrir une boutique</Text></Link>
                        <Link href={"/manuel_du_vendeur"}><Text className={FooterStyles.columnItem}>Manuel du vendeur</Text></Link>
                        <Link href={"/dashboard"}><Text className={FooterStyles.columnItem}>Votre compte</Text></Link>
                        <Link href={"/conditions_de_livraison"}><Text className={FooterStyles.columnItem}>Tarifs et Livraisons</Text></Link>
                        <Link href={'/associations'}><Text className={FooterStyles.columnItem}>Les associations partenaires</Text></Link>
                    </Grid>
                    <Grid item className={FooterStyles.column}>
                        <Text className={FooterStyles.columnTitle}>Videz votre dressing</Text>
                        <Button variant={"contained"} size={"small"} className={FooterStyles.becomeSellerButton} href={"/ma_boutique"}>
                            Ouvrir ma boutique maintenant
                        </Button>
                    </Grid>
                    <Grid item className={FooterStyles.column}>
                        <Text className={FooterStyles.columnTitle}>Nous suivre</Text>
                        <Box style={{flexDirection: "row", marginBottom: 25}}>
                            <Link href="https://www.facebook.com/violettesauvagevidedressing" target={'_blank'} className={FooterStyles.socialNetwork}>
                                <FontAwesome name="facebook-f" style={{fontSize: Constants.FONT_SIZE_MEDIUM}} color={Constants.WHITE} />
                            </Link>
                            <Link href="https://twitter.com/sauvageviolette" target={'_blank'} className={FooterStyles.socialNetwork}>
                                <FontAwesome name="twitter" style={{fontSize: Constants.FONT_SIZE_MEDIUM}} color={Constants.WHITE} />
                            </Link>
                            <Link href="https://www.instagram.com/violettesauvage" target={'_blank'}  className={FooterStyles.socialNetwork}>
                                <FontAwesome name="instagram" style={{fontSize: Constants.FONT_SIZE_MEDIUM}} color={Constants.WHITE} />
                            </Link>
                            <Link href="https://www.tiktok.com/@violettesauvage_" target={'_blank'}  className={FooterStyles.socialNetwork}>
                                <CardMedia component={"img"} src={TikTokLogo} className={FooterStyles.tiktokLogo}/>
                            </Link>
                        </Box>

                        <Text className={FooterStyles.columnTitle}>Newsletter</Text>

                        <Box className={FooterStyles.newsletterInputWrapper}>
                            <TextInput className={FooterStyles.newsletterInput} placeholder={"Entrez votre adresse mail"} onChange={(event) => setMail(event.target.value)} />
                            <Box className={FooterStyles.newsletterInputDivider} />
                            <Button disableElevation onClick={() => {handleNewsletterClick(mail)}} className={FooterStyles.newsletterInputButton}>
                                <FontAwesome name={"paper-plane"} color={Constants.PINK} className={FooterStyles.newsletterInputIcon}/>
                            </Button>
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"cgu"}
                                    value={cgu}
                                    onChange={e => setCgu(!cgu)}
                                    icon={<CheckBoxOutline style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.FONT_LIGHT_GRAY}}/>}
                                    checkedIcon={<CheckBoxOutlineChecked style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.PINK}}/>}
                                />
                            }
                            label={
                                <Box style={{textAlign: "left"}}>
                                    <Text component={"span"} className={clsx(FooterStyles.checkboxLabel, {[ErrorStyle.error]: cguError})}>J'accepte les <Link href={'/CGV'} target={"_blank"}>CGV/CGU et la politique<br />de confidentialité</Link> de Violette Sauvage</Text>
                                    {/*<Link href={"/CGV"}>CGV et la politique de confidentialité</Link>*/}
                                </Box>
                            }
                        />
                        {newsletterError ?
                            <Text className={FooterStyles.newsletterError}>{newsletterError}</Text> :
                            null
                        }
                    </Grid>
                </Grid>
                <Box className={FooterStyles.cbRow}>
                    <CardMedia component={"img"} src={AmericanExpressLogo} className={FooterStyles.cbLogo} />
                    <CardMedia component={"img"} src={DeltaLogo} className={FooterStyles.cbLogo} />
                    <CardMedia component={"img"} src={MastercardLogo} className={FooterStyles.cbLogo} />
                    <CardMedia component={"img"} src={VisaLogo} className={FooterStyles.cbLogo} />
                </Box>
            </Box>
            <Box className={FooterStyles.row2}>
                <Text className={FooterStyles.copyright}>Copyright Violette Sauvage 2020 - Réalisation Co-fondateur - <Link href={"/mentions_legales"} style={{color: '#737373'}}>Mentions légales</Link></Text>
            </Box>
            <ConfirmationModal
                visible={newsletterOk}
                title={"Abonnement à la newsletter"}
                message={"Vous êtes bien inscrit à la newsletter"}
                onConfirm={() => {confirmNewsletter()}} />
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        newsletterError: state.user.newsletterError,
        newsletterOk: state.user.newsletterOk
    }
};

const mapDispatchToProps = dispatch => {
    return {
        registerNewsletter: (mail) => {
            dispatch({type: 'REGISTER_NEWSLETTER_REQUESTED', mail})
        },
        confirmNewsletter: () => {
            dispatch({type: 'CONFIRM_NEWSLETTER'})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
