import React from 'react';
import { connect } from 'react-redux'

import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import CardMedia from "@material-ui/core/CardMedia"

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// import PictureModalStylesFunc from "../../../Style/PictureModalStyle"
import {getImageUrl} from "../../../Utils/ImageUtils";


function Component ({visible, setVisible, picture}) {
    // let PictureModalStyles = PictureModalStylesFunc();

    let _renderModalContent = () => (
        <Box style={{width: "100%", height: "100%"}}>
            <CardMedia component={"img"} src={picture ? (picture.contentUrl ? getImageUrl(picture.contentUrl, "real") : picture.uri) : null} style={{maxHeight: "calc(100vh - 65px)", width: "calc(100vw - 65px)", objectFit: "contain"}}/>
            <HighlightOffIcon onClick={() => setVisible(false)} style={{position: "absolute", top: -15, right: -15, color: "white", cursor: "pointer", fontSize: 30}}/>
        </Box>
    );

    return (
        <Dialog open={visible} maxWidth={"xl"} PaperProps={{style: {overflow: "visible"}}}>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;
