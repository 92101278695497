import React from "react";
import Text from "../Component/FontText";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";


export const LINKS = {
    "CART": {
        "web": "/cart",
        "mobile": "Cart",
        "label": "Mon panier"
    },
    "LOGIN": {
        "web": "/login",
        "mobile": "Login",
        "label": "Connexion"
    },
    "REGISTER": {
        "web": "/register",
        "mobile": "Register",
        "label": "Création de compte"
    },
    "HOMEPAGE": {
        "web": "/",
        "mobile": "Home",
        "label": "Landing page"
    },
    "PRODUCT_LIST": {
        "web": "/products",
        "mobile": "ProductList",
        "label": "Liste des produits"
    },
    "FORGOT_PASSWORD": {
        "web": "/forgot-password",
        "mobile": "ForgotPassword",
        "label": "Mot de passe oublié"
    },
    "STATIC_MEETING_VS": {
        "web": "/meetingVS",
        "mobile": "MeetingVS",
        "label": "Rencontrer VS"
    },
    "STATIC_CGV": {
        "web": "/CGV",
        "mobile": "CGV",
        "label": "CGV"
    },
    "STATIC_VALUES": {
        "web": "/nos_valeurs",
        "mobile": "VALUES"
    },
    "STATIC_CAC_DELIVERY": {
        "web": "/livraison_click_and_collect",
        "mobile": "CAC_DELIVERY"
    },


    "DASHBOARD": {
        "web": "/dashboard",
        "mobile": "Dashboard",
        "label": "Mon compte"
    },
    "WISHLIST": {
        "web": "/wishlist",
        "mobile": "Wishlist",
        // "label": "Wishlist"
    },
    "ALERTS": {
        "web": "/mes_alertes",
        "mobile": "Alerts",
        // "label": "Mes alertes"
    },
    "PURCHASES": {
        "web": "/mes_commandes",
        "mobile": "Purchases",
        // "label": "Mes commandes"
    },
    "MESSAGE_THREADS": {
        "web": "/messages",
        "mobile": "MessageThreads",
        // "label": "Messagerie"
    },
    "MY_SHOP": {
        "web": "/ma_boutique",
        "mobile": "MyShop",
        // "label": "Ma boutique"
    },
    "CREATE_MY_SHOP": {
        "web": "creer_ma_boutique",
        "mobile": "CreateMyShop",
        // "label": "Créer ma boutique"
    },
    "MY_PRODUCTS": {
        "web": "/mes_annonces",
        "mobile": "MyProducts",
        // "label": "Mes annonces"
    },
    "MY_BANK_INFO": {
        "web": "/mes_coordonnées_bancaires",
        "mobile": "MyBankInfo",
        // "label": "Mes coordonnées bancaires"
    },
    "MY_WALLET": {
        "web": "/mon_porte_monnaie",
        "mobile": "MyWallet",
        // "label": "Mon wallet"
    },
    "MY_INFO": {
        "web": "/mes_informations",
        "mobile": "MyInfo",
        // "label": "Mes infos"
    },
    "MY_SALES": {
        "web": "/mes_transactions",
        "mobile": "MySales",
        // "label": "Mes transactions"
    },
    "CREATE_PRODUCT": {
        "web": "/ajouter_produit",
        "mobile": "CreateProduct",
        // "label": "Créer produit"
    },
    "MY_LIVES": {
        "web": "/mes_lives",
        "mobile": "MyLives",
        // "label": "Créer produit"
    },
    "TUTO_LIVES": {
        "web": "/tuto_live",
        "mobile": "TutoLives",
        // "label": "Créer produit"
    },
    "CREATE_LIVE": {
        "web": "/inscription_live",
        "mobile": "CreateLive",
        // "label": "Créer produit"
    },


    "ADMIN_LIST": {
        "web": "/admin/liste",
        "mobile": "AdminList"
    },
    "CRITERIA_LIST": {
        "web": "/admin/criteria"
    },
    "MONEY_DASHBOARD": {
        "web": "/admin/suivi_transactions",
        "mobile": "MoneyDashboard"
    },
    "ADMIN_DASHBOARD": {
        "web": "/admin/administration_manuelle",
        "mobile": "AdminDashboard"
    },
    "NOTIF_DASHBOARD": {
        "web": "/admin/gestion_notifications",
        "mobile": "NotifDashboard"
    },
    "LIVE_PROMOTION_LIST": {
        "web": "/admin/live_promo",
        "mobile": "LivePromotionList"
    },
    "ASSOCIATION_LIST": {
        "web": "/admin/associations",
        "mobile": "AssociationList"
    }
};

export function InternalLink({link, label, linkStyle, wrapperStyle, labelStyle, linkClass, wrapperClass, labelClass, icon, onPress, classes, variant, center, secondary}) {
    if (classes) {
        return (
            <Link href={LINKS[link]["web"]} className={variant ? classes['link-' + variant] : classes.link}>
                <Typography className={variant ? classes['linkTypo-' + variant] : classes.linkTypo}>
                    {icon}{label}
                </Typography>
                {secondary}
            </Link>
        );
    } else {
        return (
            <Link href={LINKS[link]["web"]} style={{width: "max-content", display: "block", margin: center ? "auto" : "initial", position: "relative"}} className={linkClass} color={"inherit"}>
                <Text style={labelStyle} className={labelClass}>
                    {icon}{label}
                </Text>
                {secondary}
            </Link>
        );
    }
}
