import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "../GlobalStyle";
import * as Constants from "../Constants"
import CommonStyle from "./CommonStyle";


export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,
        titleWrapper: {
            alignItems: "center",
            textAlign: "center"
        },
        titlePage1: {
            paddingLeft: 275,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0
            }
        },
        title : Object.assign({}, CommonStyles.title, {marginBottom: 20}),
        subTitle: {

            marginBottom: Constants.GET_SIZE(50),
        },
        productTitle: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            marginBottom: Constants.GET_SIZE(10),
            textTransform: "uppercase",
            paddingBottom: Constants.GET_SIZE(5),
            borderBottomWidth: 1,
            borderBottomStyle: "solid"
        },
        productForm: {
            marginBottom: Constants.GET_SIZE(50),
        },
        column: {
            maxWidth: 430,
        },

        buttonsBox: {
            display: 'inline-flex',
            flexDirection: 'column'
        },

        addButton: {
            ...CommonStyles.button,
            marginBottom: 10,
        },

        saveButton: {
            ...CommonStyles.button,
            marginTop: 10,
        },

        inputWrapper: {
            maxWidth: Constants.GET_SIZE(430),
            width: "100%"
        },
        checkboxLabel: {

            color: Constants.FONT_GRAY
        },
        checkboxLinkWrapper: {
            ...GlobalStyle.rowCentered
        },
        checkBox: {
            backgroundColor: Constants.WHITE,
            borderColor: Constants.WHITE,
            width: Constants.GET_SIZE(300)
        },
        snInputWrapper: {
            ...GlobalStyle.rowCentered,
        },
        picturesWrapper: {
            ...GlobalStyle.rowCentered,
            flexWrap: "wrap",
            marginLeft: (-120 / Constants.MAQUETTE_WIDTH_VW) + "vw",
            marginTop: Constants.GET_SIZE(100),
            marginBottom: Constants.GET_SIZE(50),

        },
        picture: {
            width: Constants.GET_SIZE(270),
            height: Constants.GET_SIZE(220),
            // marginBottom: Constants.GET_SIZE(20),
            // marginRight: Constants.GET_SIZE(13),
            // marginTop: Constants.GET_SIZE(13),
            borderWidth: 1,
            borderColor: Constants.BORDER_GRAY,
            borderStyle: "solid",
            borderRadius: "5%",
            resizeMode: "contain"
        },
        pictureButtonLabel: {
            ...GlobalStyle.buttonLabel
        },
        // pictureButton: {
        //     padding: 15,
        //     paddingLeft: 25,
        //     paddingRight: 25,
        //     marginBottom: 10,
        // },
        pictureSubtext: {

            color: Constants.FONT_GRAY,
            maxWidth: 300,
            marginBottom: 20,
        },
        pictureWrapper: {
            position: "relative",
            width: Constants.GET_SIZE(270),
            height: Constants.GET_SIZE(220),
        },
        pictureDeleteIcon: {
            color: Constants.FONT_GRAY,

            fontSize: Constants.FONT_SIZE_BIGGER,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
            }
        },
        pictureDeleteIconWrapper: {
            position: "absolute",
            top: -20,
            right: 0,
            [theme.breakpoints.down('xs')]: {
                right: 10,
            }
        },

        pictureEditIconWrapper: {
            position: "absolute",
            background: 'rgba(0, 0, 0, 0.54)',
            padding: 5,
            top: 5,
            left: 5,
            [theme.breakpoints.down('xs')]: {
                left: 45,
            },
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.7)',
            }
        },

        pictureEditIcon: {
            color: '#fff',
            fontSize: Constants.FONT_SIZE_MEDIUM,
        },

        pickerWrapper: {
            width: "100%",
            marginBottom: 20,
        },
        picker: {
            width: "100%",
        },
        pickerPlaceholder: {
            color: "#a9a9a9"
        },

        colorsWrapper: {
            ...GlobalStyle.rowCentered,
            marginBottom: 20,
        },
        colorLabel: {

            marginRight: Constants.GET_SIZE(30),
            marginLeft: Constants.GET_SIZE(25),
            color: Constants.FONT_GRAY,
            width: "max-content"
        },
        colorPastille: {
            width: Constants.GET_SIZE(20),
            height: Constants.GET_SIZE(20),
            marginRight: Constants.GET_SIZE(5),
            marginLeft: Constants.GET_SIZE(5),
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center"
        },
        colorSelected: {},


        quantityWrapper: {
            ...GlobalStyle.rowCentered,
            width: Constants.GET_SIZE(430)
        },
        quantityLabel: {

            marginRight: Constants.GET_SIZE(30),
            marginLeft: Constants.GET_SIZE(25),
            color: Constants.FONT_GRAY,
            width: "max-content"
        },


        deliveryModesSuperWrapper: {
            position: "relative",
            marginBottom: Constants.GET_SIZE(25),
            marginTop: Constants.GET_SIZE(25),
        },
        deliveryModesWrapper: {
            maxWidth: Constants.GET_SIZE(317),
            width: "100%",
            backgroundColor: Constants.WHITE,
            borderColor: Constants.BORDER_GRAY,
            borderWidth: 1,
            borderStyle: "solid",
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                maxWidth: "100%"
            }
        },
        deliveryModesWrapperError: {
            borderColor: Constants.COLOR_ERROR
        },
        deliveryModesTitleWrapper: {
            padding: Constants.GET_SIZE(15),
        },
        deliveryModesTitle: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            }
        },
        deliveryModesList: {
            borderColor: Constants.BORDER_GRAY,
            borderWidth: 1,
            borderTopStyle: "solid",
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            width: "100%",
            overflowY: "auto",
        },
        deliveryModesListContent: {
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            height: 100
        },
        deliveryModeCheckbox: {
            width: "100%",
            justifyContent: "space-between",
            padding: 0
        },
        deliveryModeLabel: {},
        deliveryModeCaret: {
            transform: "rotate(-180deg);",
            transition: "transform 0.3s"
        },
        deliveryModeCaretOpen: {
            transform: "rotate(0deg);",
        },
        upwardTriangle: {
            position: "absolute",
            left: 20,
            top: -9,
            width: 0,
            height: 0,
            borderTopWidth: 0,
            borderLeftWidth: 20,
            borderLeftColor: "transparent",
            borderRightWidth: 20,
            borderRightColor: "transparent",
            borderBottomWidth: 20,
            borderBottomColor: Constants.WHITE,
            borderStyle: "solid",
        },
        upwardTriangleBorder: {
            position: "absolute",
            left: Constants.GET_SIZE(20),
            top: -10,
            width: 0,
            height: 0,
            zIndex: -1,
            borderTopWidth: 0,
            borderLeftWidth: Constants.GET_SIZE(20),
            borderLeftColor: "transparent",
            borderRightWidth: Constants.GET_SIZE(20),
            borderRightColor: "transparent",
            borderBottomWidth: Constants.GET_SIZE(20),
            borderBottomColor: Constants.BORDER_GRAY,
            borderStyle: "solid",
        },
        upwardTriangleBorderError: {
            borderBottomColor: Constants.COLOR_ERROR
        },

        brandWrapper: {
            zIndex: 999,
            marginBottom: 20,
        },
        brandWarning: {

            color: Constants.PINK
        },
        congratsText: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            },
            textAlign: "center"
        },
        colorSelectedIcon: {
            color: Constants.PINK,

            fontSize: Constants.FONT_SIZE_SMALL,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
            }
        }
    }
});
