import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    // Global


    // Row 1
    firstBlock: {
        minHeight: Constants.GET_SIZE(275),
        backgroundColor: Constants.GRAY_FOOTER,
        width: "100%",
        // paddingLeft: Constants.GET_SIZE(108),
        // paddingRight: Constants.GET_SIZE(108),
        paddingTop: Constants.GET_SIZE(47),
        boxSizing: "border-box"
    },
    row1: {
        width: "100%",
        marginBottom: 15
    },

    column: {
        [theme.breakpoints.down('xs')]: {
            width: "90%",
            textAlign: "center",
            marginBottom: Constants.GET_SIZE(23),
        }
    },

    hiddenSmall: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },

    columnTitle: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        marginBottom: Constants.GET_SIZE(23),
    },

    columnItem: {
        color: Constants.FONT_GRAY,
        marginBottom: Constants.GET_SIZE(10),
    },

    socialNetwork: {
        display: "inline",
        marginRight: 8,
        marginLeft: 8,
        marginBottom: Constants.GET_SIZE(17),
    },

    tiktokLogo: {
        width: 16,
        display: "inline-block"
    },

    becomeSellerText: {
        fontSize: (11.5 / Constants.MAQUETTE_WIDTH_VW) + "vw",
        fontWeight: "bold",
        marginRight: Constants.GET_SIZE(20),
        marginLeft: Constants.GET_SIZE(20),
    },

    becomeSellerButton: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 200
        }
    },

    // Row 2
    row2: {
        ...GlobalStyle.rowCentered,
        backgroundColor: "#F8ADB1",
        height: Constants.GET_SIZE(65),
        width: "100%",
        justifyContent: 'center'
    },
    copyright: {
        fontSize: Constants.FONT_SIZE_SMALL,
        color: Constants.FONT_GRAY,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE
        }
    },

    upArrow: {
        height: 30,
        lineHeight: 30,
        width: 30,
        padding: 9,
        backgroundColor: Constants.PINK,
        borderRadius: "50%",
        textAlign: "center"
    },
    upArrowWrapper: {
        zIndex: 10,
        right: 40,
        bottom: 40,
        position: "fixed",
    },
    newsletterInputWrapper: {
        backgroundColor: Constants.WHITE,
        display: "flex",
        flexDirection: "row",
        borderColor: Constants.PINK,
        height: Constants.GET_SIZE(40),
        borderWidth: 1
    },
    newsletterInput: {
        fontSize: Constants.FONT_SIZE_SMALL,
        color: Constants.GRAY_FOOTER,
        margin: Constants.GET_SIZE(10),
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE
        }
    },
    newsletterInputButton: {
        [theme.breakpoints.down('xs')]: {
            width: 'auto'
        }
    },
    newsletterInputDivider: {
        height: "100%",
        width: 1,
        backgroundColor: Constants.PINK,
    },
    newsletterError: {
        fontSize: Constants.FONT_SIZE_SMALL,
        color: Constants.COLOR_ERROR,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE
        }
    },
    newsletterConfirmation: {
        fontSize: Constants.FONT_SIZE_SMALL,
        color: Constants.WHITE,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE
        }
    },
    newsletterInputIcon: {
        margin: Constants.GET_SIZE(10),
    },
    cbRow: {
        ...GlobalStyle.row,
        justifyContent: "flex-end",
        width: "90%",
        paddingBottom: 15
    },
    cbLogo: {
        width: Constants.GET_SIZE(33),
        height: Constants.GET_SIZE(22),
        marginLeft: Constants.GET_SIZE(5),
    },
    checkboxLabel: {
        color: Constants.FONT_LIGHT_GRAY
    },
}));
