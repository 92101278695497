import * as Constants from "./Constants";


export default (theme) => ({
    centeredContainer: {
        backgroundColor: Constants.WHITE,
        margin: "auto",
        marginTop: 50,
        marginBottom: 100,
        width: "100%",
        maxWidth: 650,
        [theme.breakpoints.down('xs')]: {
            width: "90%",
            marginTop: 20,
        },
        textAlign: "center"
    },
    button: {
        marginTop: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20
        }
    },

    welcomeLabel: {
        fontSize: Constants.FONT_SIZE_BIGGEST,
        marginBottom: 30,
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGEST_MOBILE,
            marginBottom: 10,
        }
    },
    connectLabel: {
        fontSize: Constants.FONT_SIZE_BIG,
        color: Constants.FONT_DARK_GRAY,
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },
    connectWrapper: {
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20
        }
    },
    logo: {
        width: 300,
        height: 138,
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            width: 200,
            height: 75,
            marginBottom: 20,
        }
    },
});
