import React from "react";
import clsx from "clsx";
import { Image } from "react-native";

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Rating from '@material-ui/lab/Rating';
import CardMedia from "@material-ui/core/CardMedia";

import Text from "../Component/FontText";
import ShopSubscribeButton from "./Component/ShopSubscribeButton";
import { connect } from 'react-redux'
import ShopHeader from "../../Assets/Images/shop_header.png";
import ProductSquare from "./Component/ProductSquare";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";

import ShopStylesFunc from "../../Style/ShopStyle";
import * as Constants from "../../Style/Constants";
import Map from "../Component/Map"
import Paginator from "./Component/Paginator";

import LogoPinterest from "../../Assets/Images/SocialMedia/badgeRGB.svg";
import LogoTwitter from "../../Assets/Images/SocialMedia/Twitter_Social_Icon_Circle_Color.png";
import LogoInsta from "../../Assets/Images/SocialMedia/glyph-logo_May2016.png";
import LogoFB from "../../Assets/Images/SocialMedia/f_logo_RGB-Blue_58.png";
import {getImageUrl} from "../../Utils/ImageUtils";
import UserIcon from "../../Assets/Images/user_icon.svg";
import Moment from "moment";
import Link from "@material-ui/core/Link";



function Component({history, navigation, shop, fetchShop, firstPage, lastPage, isLoading, getShopsProducts, voteForShop, width, resetError, shopError}) {
    let ShopStyles = ShopStylesFunc();
    let [page, setPage] = React.useState(1);
    let [showVote, setShowVote] = React.useState(false);
    let { id } = useParams();

    let productsPerPage = 12;
    if (isWidthDown('xs', width)) {
        productsPerPage = 6;
    }

    React.useEffect(() => {
        fetchShop(id);
    }, [id]);

    React.useEffect(() => {
        if (shopError) {
            if (shopError === "NOT_FOUND") {
                history.push("/");
            }
            resetError();
        }
    }, [shopError]);

    React.useEffect(() => {
        if (shop && !shop.products) {
            document.title = 'VioletteSauvage - Boutique ' + shop.name;
            shop.products = [];
            getShopsProducts(shop.id, page, productsPerPage);
        }
    }, [shop]);

    React.useEffect(() => {
        if (shop) {
            getShopsProducts(shop.id, page, productsPerPage);
        }
    }, [page]);

    let productsDom = [];
    if (shop && shop.products) {
        for (const product of shop.products) {
            productsDom.push(<Grid item xs={12} sm  style={{maxWidth: 350}}>
                <ProductSquare shop={shop} product={product} wrapperStyle={ShopStyles.productWrapper} />
            </Grid>)
        }
    }

    if (!isLoading && productsDom.length === 0) {
        productsDom.push(<Text className={ShopStyles.emptyShopLabel}>Cette boutique n'a pas encore de produit.</Text>)
    }

    let osnDom = [];
    if (shop) {
        for (const key in shop.socialNetworks) {
            if (key.startsWith("other_")) {
                osnDom.push(
                    <Box className={ShopStyles.otherSocialMediaWrapper}>
                        <Link href={shop.socialNetworks[key]} target={'_blank'}>
                            <Text className={ShopStyles.otherSocialMedia}
                                  resizeMode={"stretch"}>W</Text>
                        </Link>
                    </Box>
                );
            }
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box>
            <Grid container item xs={12} className={ShopStyles.homepageHeaderWrapper}>
                <Box className={ShopStyles.homepageHeaderLeftWrapper} style={{backgroundImage: "url(" + ShopHeader + ")"}}>
                    <Box className={clsx(ShopStyles.homepageHeaderRectangle, ShopStyles.homepageHeaderPinkRectangle)} />
                    <Text className={clsx(ShopStyles.headerImageTitle, ShopStyles.homepageHeaderText)}>{shop ? shop.name : ""}</Text>
                </Box>
            </Grid>
            <Grid container className={ShopStyles.wrapper}>
                <Grid item container xs={12} sm className={ShopStyles.leftColumn}>
                    <Box className={ShopStyles.grayBlock}>
                        {(shop && shop.picture) && <CardMedia component={"img"} src={getImageUrl(shop.picture.contentUrl, "medium")} className={ShopStyles.shopImage}/>}
                        {(!shop || !shop.picture) && <CardMedia component={"img"} src={UserIcon} className={ShopStyles.shopImage} />}
                        <Text className={ShopStyles.memberSinceText}>Membre depuis le {shop ? Moment(shop.createdAt).format("DD/MM/YYYY") : ''}</Text>
                        {shop && <Text className={ShopStyles.shopType}>{shop.type === 0 ? "Boutique particulier / professionnel de la seconde main" : shop.type === 1 ? "Boutique eco-responsable" : shop.type === 2 ? "Boutique créateur" : shop.type === 3 ? "Boutique blogueur" : ""}</Text>}
                        <Text className={ShopStyles.description}>{shop ? shop.description : ""}</Text>
                    </Box>
                    <Box className={ShopStyles.grayBlock} style={{alignItems: "start"}}>
                        <Text className={ShopStyles.secondBlockText} style={{textTransform: "uppercase"}}>{shop ? shop.facturationCity : ""}</Text>
                        <Text className={ShopStyles.secondBlockText}>-</Text>
                        <Text className={ShopStyles.secondBlockText}>{shop ? shop.nbProducts : 0} produit{shop && shop.nbProducts > 1 ? "s" : ""} en vente</Text>
                        <Text className={ShopStyles.secondBlockText}>{shop ? shop.nbSoldProducts : 0} produit{shop && shop.nbSoldProducts > 1 ? "s" : ""} vendu{shop && shop.nbSoldProducts > 1 ? "s" : ""}</Text>
                    </Box>
                    <Box className={ShopStyles.grayBlock}>
                        <Rating name="read-only" value={shop && shop.note ? shop.note : 0} readOnly/>
                        {shop && shop.canIVote && <Button onClick={() => setShowVote(!showVote)} variant={"contained"} color={"primary"} disableElevation fullWidth style={{marginTop: 20, marginBottom: 20}}>Noter cette boutique</Button>}
                        {shop && shop.canIVote && <Rating name={"vote-for-shop"} value={shop && shop.myShopEval ? shop.myShopEval.note : 0} onChange={(event, value) => {voteForShop(shop.id, value); setShowVote(false);}} style={{visibility: showVote ? "visible" : "hidden"}}/>}
                    </Box>
                    <Grid container justify={"center"} alignItems={"center"} direction={"column"}>
                        <Grid container justify={"center"} className={ShopStyles.socialMediaRow}>
                            {shop && "facebook" in shop.socialNetworks && shop.socialNetworks.facebook !== "" &&
                                <Box className={ShopStyles.socialMediaWrapper}>
                                    <Image source={LogoFB} className={ShopStyles.socialMedia}  accessibilityRole={"link"} href={shop.socialNetworks.facebook} target={'_blank'} resizeMode={"stretch"}/>
                                </Box>
                            }
                            {shop && "twitter" in shop.socialNetworks && shop.socialNetworks.twitter !== "" &&
                                <Box className={ShopStyles.socialMediaWrapper}>
                                    <Image source={LogoTwitter} className={ShopStyles.socialMedia}  accessibilityRole={"link"} href={shop.socialNetworks.twitter} target={'_blank'} resizeMode={"stretch"} />
                                </Box>
                            }
                            {shop && "instagram" in shop.socialNetworks && shop.socialNetworks.instagram !== "" &&
                                <Box className={ShopStyles.socialMediaWrapper}>
                                    <Image source={LogoInsta} className={ShopStyles.socialMedia}  accessibilityRole={"link"} href={shop.socialNetworks.instagram} target={'_blank'} resizeMode={"stretch"} />
                                </Box>
                            }
                            {shop && "pinterest" in shop.socialNetworks && shop.socialNetworks.pinterest !== "" &&
                                <Box className={ShopStyles.socialMediaWrapper}>
                                    <Image source={LogoPinterest} className={ShopStyles.socialMedia}  accessibilityRole={"link"} href={shop.socialNetworks.pinterest} target={'_blank'} resizeMode={"stretch"} />
                                </Box>
                            }
                            {osnDom.length > 0 ? osnDom : null}
                        </Grid>
                        <ShopSubscribeButton shop={shop} variant={"outlined"} wrapperStyle={{marginBottom: 25, marginTop: 25, width: "100%", maxWidth: 200}}/>
                        <Button onClick={() => history.push("/message/nouveau", {shopId: shop.id})} variant={"contained"} color={"primary"} disableElevation size={"small"} style={{marginBottom: 60, width: "100%", maxWidth: 200}}>Contacter</Button>

                        {shop !== null && shop.latitude && shop.longitude &&
                        <Map
                            center={[shop.latitude, shop.longitude]}
                            zoom={13}
                            visible={true}
                            mapStyle={{
                                width: Constants.GET_SIZE(323),
                                height: Constants.GET_SIZE(230),
                                marginBottom: 40
                            }}
                            fixedViewpoint={true}
                            markers={[{position: [shop.latitude, shop.longitude], text: null}]}
                        />
                        }
                    </Grid>
                </Grid>
                <Grid item container xs={12} sm className={ShopStyles.rightColumn}>
                    {productsDom}
                </Grid>
            </Grid>
            {shop !== null && <Paginator firstPage={shop.pagination.first} lastPage={shop.pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE} />}
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        shop: state.shop.currentShop,
        isLoading: state.loading.isLoadingGeneral,
        shopError: state.shop.shopError,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchShop: (id) => {
            dispatch({type: 'FETCH_SHOP_REQUESTED', id})
        },
        resetError: () => {
            dispatch({type: 'RESET_SHOP_ERROR'})
        },
        getShopsProducts: (shopId, page, itemsPerPage) => {
            dispatch({type: 'GET_SHOPS_PRODUCTS_REQUESTED', shopId, page, itemsPerPage})
        },
        voteForShop: (shopId, note) => {
            dispatch({type: 'EVALUATE_SHOP_REQUESTED', shopId, note})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default withWidth()(VisibleComponent)
