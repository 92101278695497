import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Text from '../Component/FontText';
import LeftMenu from './Partial/LeftMenu';
import Base from './Base';
import StylesFunc from '../../Style/MiddleOffice/CreateProductStyle';
import ProductForm from '../Front/ProductForm';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import * as Constants from '../../Style/Constants';
import CheckBoxOutlineChecked from '@material-ui/icons/CheckBoxOutlined';
import Link from '@material-ui/core/Link';
import { useForm } from 'react-hook-form';
import ErrorStyleFunc from '../Styles/Error';
import { ErrorMessage } from '@hookform/error-message';
import { useUuid } from '../../Hooks/useUuid';
import { useGetBrandIdFromName } from '../../Hooks/useGetBrandIdFromName';
import { usePrevious } from '../../Hooks/usePrevious';

function Component ({ history, screenKey, admin }) {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();

    const uuid = useUuid();
    const { errors, handleSubmit, register, setValue, watch } = useForm({ shouldFocusError: true });

    const [saving, setSaving] = useState(false);
    const [someProductNeedModeration, setSomeProductsNeedModeration] = useState(false);

    // Get hooks
    const getBrandIdFromName = useGetBrandIdFromName();

    // Get redux states and setup actions
    const dispatch = useDispatch();

    const user = useSelector(state => state.user.me);
    const currentProduct = useSelector(state => state.product.currentProduct);
    const currentProducts = useSelector(state => state.product.currentProducts);
    const previewProduct = useSelector(state => state.product.previewProduct);
    const productsSavedSuccessfully = useSelector(state => state.product.productsSavedSuccessfully);

    const fetchProduct = useCallback((id) => {
        dispatch({ type: 'FETCH_PRODUCT_REQUESTED', id });
    }, [dispatch]);

    const saveCurrentProducts = useCallback((products) => {
        dispatch({ type: 'SAVE_CURRENT_PRODUCTS', products });
    }, [dispatch]);

    const savePreviewProduct = useCallback((product, pictures) => {
        dispatch({ type: 'SAVE_PREVIEW_PRODUCT', product, pictures });
    }, [dispatch]);

    const saveProduct = useCallback((product, pictures, newBrand, key) => {
        dispatch({ type: 'SAVE_PRODUCT_REQUESTED', product, pictures, newBrand, key });
    }, [dispatch]);

    // Setup reference to forms
    const formRefs = useRef([]);
    if (formRefs.current.length !== Object.keys(currentProducts).length) {
        formRefs.current = Array(Object.keys(currentProducts).length).fill().map((_, i) => formRefs.current[i] || createRef());
    }

    // Redirect if user not logged or has no shop
    useEffect(() => {
        if (!user || !user.shop) {
            history.push('/creer_ma_boutique');
        }
    }, [user]);

    const MODES = {
        FORM: 'form',
        SUCCESS: 'success'
    };

    const [mode, setMode] = React.useState(MODES.FORM);

    const { id } = useParams();

    const handleAddProduct = useCallback(() => {
        saveCurrentProducts({ ...currentProducts, [uuid()]: createEmptyProduct() });
    }, [currentProducts, saveCurrentProducts]);

    const handleDeleteProduct = useCallback((productKey) => {
        const productsCopy = { ...currentProducts };
        delete productsCopy[productKey];
        saveCurrentProducts(productsCopy);
    }, [currentProducts, saveCurrentProducts]);

    const handleResetPage = useCallback(() => {
        if (screenKey === 'EditProduct') {
            history.push('/ajouter_produit');
        } else {
            saveCurrentProducts({ [uuid()]: createEmptyProduct() });
            setMode(MODES.FORM);
        }
    }, [setMode, saveCurrentProducts]);

    React.useEffect(() => {
        if (id) {
            if (currentProduct === null) {
                // First fetch product
                fetchProduct(id);
            } else {
                // Then load product
                loadProducts();
            }
        } else {
            loadProducts();
        }
    }, [id, currentProduct]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [mode]);

    let loadProducts = useCallback(() => {
        // 4 cas :
        // 1) Création de produit, pas de preview
        // 2) Création de produit, preview
        // 3) Edition de produit, pas de preview
        // 4) Edition de produit, preview avec meme id
        // 5) Edition de produit, preview != id
        if (screenKey === 'CreateProduct') {
            if (Object.keys(currentProducts).length === 0) {
                saveCurrentProducts({ [uuid()]: createEmptyProduct() });
                return;
            }

            if (previewProduct) {
                const isPreviewingAnEditedProducts = Object.values(currentProducts).filter(product => product.id === previewProduct.id).length > 0;
                if (!isPreviewingAnEditedProducts) {
                    // Reset preview product
                    savePreviewProduct(null, []);
                }
            }
        } else { // EditProduct
            if (previewProduct && previewProduct.id === id) {
                saveCurrentProducts({ [uuid()]: previewProduct });
                return;
            }

            if (currentProduct !== null) {
                saveCurrentProducts({ [uuid()]: currentProduct });
            }
        }
    }, [currentProduct]);

    const createEmptyProduct = useCallback(() => {
        return {
            id: null,
            name: null,
            description: null,
            family: null,
            category: null,
            brand: null,
            sizes: [],
            colors: [],
            state: null,
            material: null,
            quantity: 0,
            price: 0,
            discount: null,
            chronopost: false,
            mondialRelay: false,
            clickAndCollect: true,
            handOver: false,
            returnPossible: false
        };
    }, []);

    // Preview clicked for a product
    const handlePreview = useCallback((productKey, product, pictures) => {
        product.productWeight = product.category.productWeight;
        product.packageWeight = product.category.packageWeight;
        savePreviewProduct(product, pictures);
        history.push('/preview');
    }, []);

    // Save button clicked
    const handleSave = useCallback(() => {
        setSaving(true);
        const promises = formRefs.current.map(async (formRef) => {
            return [formRef, await formRef ? formRef.validate() : null];
        });

        // Validate all product forms
        Promise.all(promises).then((results) => {
            const failedResults = results.filter(([_, result]) => result === false);
            if (failedResults.length === 0) {
                // Finally valid shared fields
                handleSubmit(() => {
                    formRefs.current.forEach((formRef) => {
                        formRef.submit((data) => {
                            let brand = getBrandIdFromName(data.brandSearch);

                            const categoryId = data.subCat2 && data.subCat2 !== -1 ? data.subCat2 :
                                (data.subCat && data.subCat !== -1 ? data.subCat : data.category);

                            if (brand === -1) {
                                setSomeProductsNeedModeration(true);
                            }

                            const product = {
                                id: screenKey === 'EditProduct' ? id : null,
                                family: '/api/families/' + parseInt(data.family).toString(),
                                name: data.name,
                                description: data.description,
                                brand: brand !== -1 ? '/api/criterion_brands/' + brand : null,
                                sizes: data.sizes ? data.sizes.map(size => '/api/criterion_sizes/' + size) : [],
                                colors: data.colors.map(color => '/api/criterion_colors/' + color),
                                state: '/api/criterion_states/' + data.state,
                                material: '/api/criterion_materials/' + data.material,
                                quantity: parseInt(data.quantity),
                                price: parseFloat(data.price),
                                discount: data.discount ? parseInt(data.discount) : 0,
                                chronopost: data.deliveryModes.indexOf(0) !== -1,
                                mondialRelay: data.deliveryModes.indexOf(0) !== -1,
                                clickAndCollect: true,
                                handOver: data.deliveryModes.indexOf(2) !== -1,
                                returnPossible: data.deliveryModes.indexOf(0) !== -1 && !!data.returnPossible,
                                category: '/api/categories/' + categoryId.toString()
                            };

                            saveProduct(product, data.pictures, brand === -1 ? data.brandSearch : null, formRef.getProductKey());
                        });
                    });
                    setSaving(false);
                }, () => setSaving(false))();
            } else {
                const [formRef] = failedResults[0];
                // Submit first form with errors to focus input with error
                formRef.submit(() => {}, () => setSaving(false));
            }
        });
    }, [formRefs, getBrandIdFromName, handleSubmit, saveProduct, screenKey]);

    const prevProductsSavedSuccessfully = usePrevious(productsSavedSuccessfully);
    // Remove product successfully saved
    useEffect(() => {
        // Run this effect only if productSavedSuccessfully change
        if (!prevProductsSavedSuccessfully || prevProductsSavedSuccessfully.length === productsSavedSuccessfully.length) {
            return;
        }

        const currProducts = Object.keys(currentProducts).reduce((newCurrentProducts, productKey) => {
            // If product is not in saved successfully
            if (productsSavedSuccessfully.filter(p => p.key === productKey).length === 0) {
                newCurrentProducts[productKey] = currentProducts[productKey];
            }
            return newCurrentProducts;
        }, {})

        // All products are saved successfully
        if (Object.keys(currentProducts).length > 0 && Object.keys(currProducts).length === 0) {
            setMode(MODES.SUCCESS);
        }

        saveCurrentProducts(currProducts);
    }, [currentProducts, productsSavedSuccessfully, saveCurrentProducts]);

    // Setup shared fields validation
    let cgv = watch('cgv', false);
    React.useEffect(() => {
        register({ name: 'cgv' }, { required: 'Vous devez accepter les conditions générales de vente' });
    }, [register]);

    return <Base content={
        <Box className={Styles.container}>
            <Text className={clsx(Styles.title)}>Mettre en vente un article</Text>
            {mode === MODES.FORM && <Text className={Styles.subTitle}>Je veux vendre mes articles:</Text>}
            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={!admin ? 'CREATE_PRODUCT' : 'ADMIN_LIST'}/>
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    {mode === MODES.FORM &&
                    (
                        <div>
                            {Object.keys(currentProducts).map((productKey, i) => <ProductForm ref={el => formRefs.current[i] = el} key={productKey} productKey={productKey} number={i + 1} screenKey={screenKey} deleteHandler={handleDeleteProduct} previewHandler={handlePreview}/>)}

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={cgv}
                                            onChange={(event) => setValue('cgv', event.target.checked)}
                                            icon={<CheckBoxOutline style={{ fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.FONT_LIGHT_GRAY }}/>}
                                            checkedIcon={<CheckBoxOutlineChecked style={{ fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.PINK }}/>}
                                        />
                                    }
                                    label={
                                        <Box>
                                            <Text component={'span'} className={clsx(Styles.checkboxLabel, { [ErrorStyle.error]: errors['cgv'] })}>J'ai lu et j'accepte les </Text>
                                            <Link href={'/CGV'} target={"_blank"}>CGV/CGU et la politique de confidentialité</Link>
                                        </Box>
                                    }
                                />
                            </FormGroup>
                            <ErrorMessage name={'cgv'} errors={errors} as={Text} className={ErrorStyle.error}/>

                            <Box className={Styles.buttonsBox}>
                                {screenKey === 'CreateProduct' && <Button onClick={handleAddProduct} color={'secondary'} variant={'contained'} disableElevation className={Styles.addButton}>
                                    Ajouter un article
                                </Button>}
                                <Button onClick={handleSave} color={'primary'} variant={'contained'} disableElevation className={Styles.saveButton} disabled={saving}>
                                    Mettre en vente
                                </Button>
                            </Box>
                        </div>
                    )
                    }
                    {mode === MODES.SUCCESS && <Box style={{ alignItems: 'center', width: '100%' }}>
                        <Text className={Styles.congratsText}>{screenKey === 'EditProduct' ? 'Félicitations, ton article a bien été modifié !' : !someProductNeedModeration ? 'Félicitations, tes articles ont bien été mis en vente !' : 'Félicitations, tes articles ont bien été enregistrés. Cependant certains sont soumis à modération.'}</Text>
                        <Grid container xs={12} justify={'center'} style={{ marginTop: 20 }}>
                            <Button onClick={() => handleResetPage()} color={'primary'} variant={'contained'} disableElevation className={Styles.button}>
                                Mettre d'autres articles en vente
                            </Button>
                        </Grid>
                    </Box>}
                </Box>
            </Grid>
        </Box>
    }/>;
}

const VisibleComponent = withRouter(Component);

export default VisibleComponent;
