import React from "react";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Switch from '@material-ui/core/Switch';

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FontAwesome } from '../../FontAwesome';

import Text from "../Component/FontText"
import Base from "./Base"
import StylesFunc from "../../Style/Back/CriteriaListStyle"
import * as Constants from "../../Style/Constants";
import TextInput from "../Component/FontInput";


function Component({
                       history,
                       navigation,
                       token,
                       searchBrands, brandsList, brandsPagination, updateBrand,
                       searchColors, colorsList, colorsPagination,
                       searchHeels, heelsList, heelsPagination,
                       searchMaterials, materialsList, materialsPagination,
                       searchSizes, sizesList, sizesPagination,
                       searchStates, statesList, statesPagination,
                       searchStyles, stylesList, stylesPagination,
                   }) {
    let Styles = StylesFunc();
    let tab, setTab, page, setPage, search, setSearch, sort, setSort, sortOrder, setSortOrder;
    if (history.location.state && history.location.state.listState) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [tab, setTab] = React.useState(history.location.state.listState.tab);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [page, setPage] = React.useState(history.location.state.listState.page);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [search, setSearch] = React.useState(history.location.state.listState.search);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [sort, setSort] = React.useState(history.location.state.listState.sort);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [sortOrder, setSortOrder] = React.useState(history.location.state.listState.sortOrder);
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [tab, setTab] = React.useState("BRANDS");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [page, setPage] = React.useState(1);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [search, setSearch] = React.useState("");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [sort, setSort] = React.useState("status");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [sortOrder, setSortOrder] = React.useState("ASC");
    }

    let [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
    let [deleteId, setDeleteId] = React.useState(null);

    let pagination = {
        "BRANDS": brandsPagination,
        "COLORS": colorsPagination,
        "HEELS": heelsPagination,
        "MATERIALS": materialsPagination,
        "SIZES": sizesPagination,
        "STATES": statesPagination,
        "STYLES": stylesPagination,
    };

    React.useEffect(() => {
        startSearch()
    }, [token, page, tab, history.location.state]);

    React.useEffect(() => {
        if (page > pagination[tab].last) {
            setPage(pagination[tab].last);
        } else if (page < pagination[tab].first) {
            setPage(pagination[tab].first);
        }
    }, [pagination[tab]]);

    let startSearch = () => {
        switch (tab) {
            case "BRANDS":
                searchBrands(search, 10, page, sort, sortOrder);
                break;
            case "COLORS":
                searchColors(search, 10, page);
                break;
            case "HEELS":
                searchHeels(search, 10, page);
                break;
            case "MATERIALS":
                searchMaterials(search, 10, page);
                break;
            case "SIZES":
                searchSizes(search, 10, page);
                break;
            case "STATES":
                searchStates(search, 10, page);
                break;
            case "STYLES":
                searchStyles(search, 10, page);
                break;
        }
    };

    let toggleBrandStatus = (brand) => {
        let newBrand = Object.assign({}, brand, {status: brand.status === 1 ? 0 : 1});
        updateBrand(newBrand);
    }

    let handleClickEditBrand = (brand) => {
        history.push("/admin/editer_criterion/" + brand.id, {listState: {tab, page, search}})
    };

    // let handleClickDeleteUser = (user) => {
    //     setDeleteId(user.id);
    //     setDeleteModalVisible(true);
    // };

    let _getTabs = () => {
        return <Box className={Styles.tabRow}>
            {/*<TouchableOpacity className={clsx(Styles.tabButton, {[Styles.tabButtonActive]: tab === "BRANDS"})} onPress={() => setTab("BRANDS")}>*/}
                {/*<Text className={clsx(Styles.tabText, {[Styles.tabTextActive]: tab === "BRANDS"})}>Marque</Text>*/}
            {/*</TouchableOpacity>*/}
            {/*<TouchableOpacity className={clsx(Styles.tabButton, {[Styles.tabButtonActive]: tab === "PRODUCT"})} onPress={() => setTab("PRODUCT")}>*/}
                {/*<Text className={clsx(Styles.tabText, {[Styles.tabTextActive]: tab === "PRODUCT"})}>Produits</Text>*/}
            {/*</TouchableOpacity>*/}
            {/*<TouchableOpacity className={clsx(Styles.tabButton, {[Styles.tabButtonActive]: tab === "SHOP"})} onPress={() => setTab("SHOP")}>*/}
                {/*<Text className={clsx(Styles.tabText, {[Styles.tabTextActive]: tab === "SHOP"})}>Boutiques</Text>*/}
            {/*</TouchableOpacity>*/}
            {/*<TouchableOpacity className={clsx(Styles.tabButton, {[Styles.tabButtonActive]: tab === "DISPUTE"})} onPress={() => setTab("DISPUTE")}>*/}
                {/*<Text className={clsx(Styles.tabText, {[Styles.tabTextActive]: tab === "DISPUTE"})}>Litiges</Text>*/}
            {/*</TouchableOpacity>*/}
            <Box className={Styles.tabInputWrapper}>
                <FontAwesome name="search" style={{fontSize: Constants.FONT_SIZE_NORMAL}} />
                <TextInput
                    className={Styles.tabInput}
                    placeholder="Recherche"
                    onPressEnter={startSearch}
                    onChange={(event) => setSearch(event.target.value)}
                    value={search}
                />
            </Box>
            {/*<TouchableOpacity className={clsx(Styles.tabButton, Styles.tabButtonActive)} accessibilityRole={"link"} href={getApiUrl((tab === "USER" ? "users" : tab === "PRODUCT" ? "products" : tab === "SHOP" ? "shops" : tab === "DISPUTE" ? "disputes": null) + "/export.csv")} target={'_blank'}>*/}
                {/*<Text className={clsx(Styles.tabText, Styles.tabTextActive)}>Export</Text>*/}
            {/*</TouchableOpacity>*/}
        </Box>
    };

    let _getBrandHeaderRow = () => {
        return <Grid container className={clsx(Styles.headerRow)}>
            <Grid item style={{width: 100}}>
                <Text className={clsx(Styles.headerCell)}>ID</Text>
            </Grid>
            <Grid item xs>
                <Text className={clsx(Styles.headerCell)}>Marque</Text>
            </Grid>
            <Grid item>
                <Text className={clsx(Styles.headerCell)}>Statut</Text>
            </Grid>
        </Grid>
    };

    let _getBrandRow = (brand) => {
        return <Grid container className={Styles.dataRow}>
            <Grid item style={{width: 100}}>
                <Text className={clsx(Styles.dataCell)}>{brand.id}</Text>
            </Grid>
            <Grid item xs>
                <Text className={clsx(Styles.dataCell)}>{brand.value}</Text>
            </Grid>
            <Grid item>
                <Box className={clsx(Styles.dataCell)}>
                    <Switch checked={brand.status === 1} color={"primary"} onChange={() => toggleBrandStatus(brand)}/>
                </Box>
            </Grid>
        </Grid>
    };

    // let _getProductHeaderRow = () => {
    //     return <Box className={Styles.headerRow}>
    //         <Text className={clsx(Styles.productDataCell1, Styles.headerCell)}>Nom</Text>
    //         <Text className={clsx(Styles.productDataCell2, Styles.headerCell)}>Boutique</Text>
    //         <Text className={clsx(Styles.productDataCell3, Styles.headerCell)}>Famille</Text>
    //         <Text className={clsx(Styles.productDataCell4, Styles.headerCell)}>Catégorie</Text>
    //         <Text className={clsx(Styles.productDataCell7, Styles.headerCell)}>Type de transaction</Text>
    //     </Box>
    // };
    //
    // let _getProductRow = (product) => {
    //     let cat1 = null;
    //     let cat2 = null;
    //     let cat3 = null;
    //     if (!product.category.parent) {
    //         cat1 = product.category.name;
    //     } else {
    //         if (!product.category.parent.parent) {
    //             cat1 = product.category.parent.name;
    //             cat2 = product.category.name;
    //         } else {
    //             cat1 = product.category.parent.parent.name;
    //             cat2 = product.category.parent.name;
    //             cat3 = product.category.name;
    //         }
    //     }
    //
    //     let deliveryModesDom = [];
    //     if (product.chronopost) {
    //         deliveryModesDom.push("Chronopost\n")
    //     }
    //     if (product.mondialRelay) {
    //         deliveryModesDom.push("Mondial Relay\n")
    //     }
    //     if (product.clickAndCollect) {
    //         deliveryModesDom.push("Click & Collect\n")
    //     }
    //     if (product.handOver) {
    //         deliveryModesDom.push("En main propre\n")
    //     }
    //     return <Box className={Styles.dataRow}>
    //         <Text className={clsx(Styles.productDataCell1, Styles.dataCellText)}>{product.name}</Text>
    //         <Text className={clsx(Styles.productDataCell2, Styles.dataCellText)}>{product.shop.name}</Text>
    //         <Text className={clsx(Styles.productDataCell3, Styles.dataCellText)}>{product.family.name}</Text>
    //         <Text className={clsx(Styles.productDataCell4, Styles.dataCellText)}>{product.category.name}</Text>
    //         <Text className={clsx(Styles.productDataCell7, Styles.dataCellText)}>{deliveryModesDom}</Text>
    //         <TouchableOpacity className={Styles.productDataCell8} onPress={() => handleClickEditProduct(product)}>
    //             <Text className={clsx(Styles.dataCellText, Styles.link)}>Editer</Text>
    //         </TouchableOpacity>
    //         <TouchableOpacity className={Styles.productDataCell9} onPress={() => handleClickDeleteProduct(product)}>
    //             <Text className={clsx(Styles.dataCellText, Styles.link)}>Supprimer</Text>
    //         </TouchableOpacity>
    //     </Box>
    // };
    //
    // let _getShopHeaderRow = () => {
    //     return <Box className={Styles.headerRow}>
    //         <Text className={clsx(Styles.shopDataCell0, Styles.headerCell)}>N°</Text>
    //         <Text className={clsx(Styles.shopDataCell1, Styles.headerCell)}>Nom de la boutique</Text>
    //         <Text className={clsx(Styles.shopDataCell2, Styles.headerCell)}>Pseudo</Text>
    //         <Text className={clsx(Styles.shopDataCell3, Styles.headerCell)}>Nombre d'articles</Text>
    //     </Box>
    // };
    //
    // let _getShopRow = (shop) => {
    //     return <Box className={Styles.dataRow}>
    //         <Text className={clsx(Styles.shopDataCell0, Styles.dataCellText)}>{shop.id}</Text>
    //         <Text className={clsx(Styles.shopDataCell1, Styles.dataCellText)}>{shop.name}</Text>
    //         <Text className={clsx(Styles.shopDataCell2, Styles.dataCellText)}>{shop.owner ? shop.owner.username : ""}</Text>
    //         <Text className={clsx(Styles.shopDataCell3, Styles.dataCellText)}>{shop.nbProducts}</Text>
    //         <TouchableOpacity className={Styles.shopDataCell4} onPress={() => handleClickEditShop(shop)}>
    //             <Text className={clsx(Styles.dataCellText, Styles.link)}>Editer</Text>
    //         </TouchableOpacity>
    //         <TouchableOpacity className={Styles.shopDataCell5} onPress={() => handleClickDeleteShop(shop)}>
    //             <Text className={clsx(Styles.dataCellText, Styles.link)}>Supprimer</Text>
    //         </TouchableOpacity>
    //     </Box>
    // };
    //
    // let _getDisputeHeaderRow = () => {
    //     return <Box className={Styles.headerRow}>
    //         <Text className={clsx(Styles.shopDataCell1, Styles.headerCell)}>N° de commande</Text>
    //         <Text className={clsx(Styles.shopDataCell2, Styles.headerCell)}>Vendeur</Text>
    //         <Text className={clsx(Styles.shopDataCell3, Styles.headerCell)}>Acheteur</Text>
    //     </Box>
    // };
    //
    // let _getDisputeRow = (dispute) => {
    //     return <Box className={Styles.dataRow}>
    //         <Text className={clsx(Styles.shopDataCell1, Styles.dataCellText)}>{dispute.purchase.id}</Text>
    //         <Text className={clsx(Styles.shopDataCell2, Styles.dataCellText)}>{dispute.shop.owner.firstname + " " + dispute.shop.owner.name}</Text>
    //         <Text className={clsx(Styles.shopDataCell3, Styles.dataCellText)}>{dispute.author.firstname + " " + dispute.author.name}</Text>
    //         <TouchableOpacity className={Styles.shopDataCell4} onPress={() => handleClickEditDispute(dispute)}>
    //             <Text className={clsx(Styles.dataCellText, Styles.link)}>Editer</Text>
    //         </TouchableOpacity>
    //         <TouchableOpacity className={Styles.shopDataCell5} onPress={() => handleClickDeleteDispute(dispute)}>
    //             <Text className={clsx(Styles.dataCellText, Styles.link)}>Supprimer</Text>
    //         </TouchableOpacity>
    //     </Box>
    // };

    return <Base
        title={"Liste des " + (tab === "BRANDS" ? "marques" : tab === "PRODUCT" ? "produits" : "boutiques")}
        currentScreen={"CRITERIA_LIST"}
        pagination={pagination[tab]}
        page={page}
        setPage={setPage}
        content={
            <Box className={Styles.container}>
                {_getTabs()}
                {tab === "BRANDS" && _getBrandHeaderRow()}
                {/*{tab === "PRODUCT" && _getProductHeaderRow()}*/}
                {/*{tab === "SHOP" && _getShopHeaderRow()}*/}
                {/*{tab === "DISPUTE" && _getDisputeHeaderRow()}*/}
                <Box className={Styles.dataWrapper}>
                    {tab === "BRANDS" && brandsList.length > 0 && brandsList.map(_getBrandRow)}
                    {/*{tab === "PRODUCT" && productsList && productsList.map(_getProductRow)}*/}
                    {/*{tab === "SHOP" && shopsList && shopsList.map(_getShopRow)}*/}
                    {/*{tab === "DISPUTE" && disputesList && disputesList.map(_getDisputeRow)}*/}
                </Box>
                {/*{tab === "USER" && <ConfirmationModal*/}
                    {/*visible={deleteModalVisible}*/}
                    {/*setVisible={setDeleteModalVisible}*/}
                    {/*title={"Suppression de l'utilisateur"}*/}
                    {/*message={"Êtes-vous sûr de vouloir supprimer cet utilisateur ?"}*/}
                    {/*onConfirm={() => {setDeleteModalVisible(false); deleteUser(deleteId); setTimeout(() => startSearch(), 100);}}*/}
                    {/*onCancel={() => {setDeleteModalVisible(false)}}/>}*/}
                {/*{tab === "PRODUCT" && <ConfirmationModal*/}
                    {/*visible={deleteModalVisible}*/}
                    {/*setVisible={setDeleteModalVisible}*/}
                    {/*title={"Suppression du produit"}*/}
                    {/*message={"Êtes-vous sûr de vouloir supprimer ce produit ?"}*/}
                    {/*onConfirm={() => {setDeleteModalVisible(false); deleteProduct(deleteId); setTimeout(() => startSearch(), 100);}}*/}
                    {/*onCancel={() => {setDeleteModalVisible(false)}}/>}*/}
                {/*{tab === "SHOP" && <ConfirmationModal*/}
                    {/*visible={deleteModalVisible}*/}
                    {/*setVisible={setDeleteModalVisible}*/}
                    {/*title={"Suppression de la boutique"}*/}
                    {/*message={"Êtes-vous sûr de vouloir supprimer cette boutique ?"}*/}
                    {/*onConfirm={() => {setDeleteModalVisible(false); deleteShop(deleteId); setTimeout(() => startSearch(), 100);}}*/}
                    {/*onCancel={() => {setDeleteModalVisible(false)}}/>}*/}
                {/*{tab === "DISPUTE" && <ConfirmationModal*/}
                    {/*visible={deleteModalVisible}*/}
                    {/*setVisible={setDeleteModalVisible}*/}
                    {/*title={"Suppression du litige ?"}*/}
                    {/*message={"Êtes-vous sûr de vouloir supprimer ce litige ?"}*/}
                    {/*onConfirm={() => {setDeleteModalVisible(false); deleteDispute(deleteId); setTimeout(() => startSearch(), 100);}}*/}
                    {/*onCancel={() => {setDeleteModalVisible(false)}}/>}*/}
            </Box>
        }
    />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        brandsList: state.criteria.brandsList,
        colorList: state.criteria.colorList,
        heelList: state.criteria.heelList,
        materialList: state.criteria.materialList,
        sizeList: state.criteria.sizeList,
        stateList: state.criteria.stateList,
        styleList: state.criteria.styleList,

        brandsPagination: state.criteria.brandsListPagination,
        colorPagination: state.criteria.colorListPagination,
        heelPagination: state.criteria.heelListPagination,
        materialPagination: state.criteria.materialListPagination,
        sizePagination: state.criteria.sizeListPagination,
        statePagination: state.criteria.stateListPagination,
        stylePagination: state.criteria.styleListPagination,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchBrands: (search, itemsPerPage, page, sort, sortOrder) => {
            dispatch({type: 'SEARCH_BRANDS_REQUESTED', search, itemsPerPage, page, sort, sortOrder})
        },
        updateBrand: (brand) => {
            dispatch({type: 'UPDATE_BRAND_REQUESTED', brand})
        },
        searchColors: (search, itemsPerPage, page) => {
            dispatch({type: 'SEARCH_COLORS_REQUESTED', search, itemsPerPage, page})
        },
        searchHeels: (search, itemsPerPage, page) => {
            dispatch({type: 'SEARCH_HEELS_REQUESTED', search, itemsPerPage, page})
        },
        searchMaterials: (search, itemsPerPage, page) => {
            dispatch({type: 'SEARCH_MATERIALS_REQUESTED', search, itemsPerPage, page})
        },
        searchSizes: (search, itemsPerPage, page) => {
            dispatch({type: 'SEARCH_SIZES_REQUESTED', search, itemsPerPage, page})
        },
        searchStates: (search, itemsPerPage, page) => {
            dispatch({type: 'SEARCH_STATES_REQUESTED', search, itemsPerPage, page})
        },
        searchStyles: (search, itemsPerPage, page) => {
            dispatch({type: 'SEARCH_STYLES_REQUESTED', search, itemsPerPage, page})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
