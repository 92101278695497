import React from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"

import AutorenewIcon from '@material-ui/icons/Autorenew';

import Text from "../../Component/FontText";
import { withRouter } from "react-router-dom";
import clsx from 'clsx';

import { connect } from "react-redux";

import ProductSquareStylesFunc from "../../../Style/ProductSquareStyle"
import * as Constants from "../../../Style/Constants"

import HeartIcon from "../../Icons/HeartIcon";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import EyeIcon from '@material-ui/icons/VisibilityOutlined';
import CartIcon from "../../Icons/CartIcon";
import {getImageUrl} from "../../../Utils/ImageUtils";
import AddToCartModal from "./AddToCartModal"
import {getApiUrl, setDefaultHeaders} from "../../../Utils/QueryUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import LiveProductDetailsModal from "./LiveProductDetailsModal"
import {useAddToWishlist, useSubscribeLive} from "../../../API/Hook/Product";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";


function Component({history, user, product, liveProduct, shop, addToCart, removeFromCart, token, cart, wishlist, addToWishlist, removeProduct, withoutBackground, smaller, wrapperStyle, currentWishlistPage, myProductsPage, currentPageParameters, itemsPerPage, addLiveProduct, isLiveProduct, setLiveFirstProduct, removeLiveProduct, canAddLiveProduct, isLivePage, setSubscribedLive, sendProposition, instantBuy, setMinPrice, minPrice, isLiveAdmin, propositionInProgress, setPropositionInProgress}) {
    if (liveProduct) {
        product = liveProduct.product;
    }
    let [modalVisible, setModalVisible] = React.useState(false);
    let [productDetailsPopupVisibility, setProductDetailsPopupVisibility] = React.useState(false);
    let [subscribeToLive, isLoadingEye] = useSubscribeLive(product.id, (s) => setSubscribedLive(product, s));
    let [subscribeToProduct, isLoadingHeart] = useAddToWishlist(product);

    let ProductSquareStyles = ProductSquareStylesFunc();
    let onPressHeart = () => {
        subscribeToProduct(!isInWishlist)
        if (isLivePage) {
            if (isInWishlist) {
                product.nbWishers -= 1;
            } else {
                product.nbWishers += 1;
            }
        }
    };

    let onPressCart = () => {
        if (!isInCart) {
            if (product.quantity === 1 && product.colors.length <= 1 && product.sizes.length <= 1) {
                addToCart(product, 1, product.colors[0], product.sizes[0]);
            } else {
                setModalVisible(true);
            }
        } else {
            removeFromCart(product);
        }
        return false;
    };

    let onPressEdit = () => {
        history.push("/editer_produit/" + product.id)
    };

    let onPressRemove = () => {
        removeProduct(product, currentPageParameters);
    };

    if (shop && !product.shop) {
        product.shop = shop;
    }

    const isInCart = cart.reduce((acc, val) => acc || val.product.id === product.id, false);
    const isInWishlist = wishlist.reduce((acc, val) => acc || val.id === product.id, false);

    const brand = product.brand ? product.brand.value : "";
    const size  = !product.sizes || product.sizes.length === 0  ? "" : product.sizes.length === 1 ? " Taille " + product.sizes[0].value : " Plusieurs tailles";
    const subTitle = brand + (brand && size ? " - " : "") + size;

    let productAvailable = true;


    let getBottomRow = () => {
        return (
            <Grid container item xs={12} justify={"space-between"} alignItems={"center"}>
                <Grid item style={{zIndex: 10, position: "relative"}}>
                    <IconButton onClick={onPressHeart}>
                        {isLoadingHeart ? <CircularProgress color={"primary"} className={ProductSquareStyles.loader} size={30} /> : <HeartIcon className={clsx(ProductSquareStyles.heartIcon, {[ProductSquareStyles.heartIconSelected]: isInWishlist})} />}
                    </IconButton>
                    <Text style={{position: "absolute", top: "50%", transform: "translateY(-50%)", right: product.nbWishers > 100 ? -20 : product.nbWishers > 10 ? -12.5 : -5, color: Constants.PINK}}>{product.nbWishers}</Text>
                </Grid>
                <Grid item style={{position: "relative"}}>
                    {product.discount !== 0 && <Text className={ProductSquareStyles.priceWithoutDiscount}>{product.realPriceWithoutDiscount}€</Text>}
                    <Text className={ProductSquareStyles.price}>{product.realPrice}€</Text>
                </Grid>
                <Grid item style={{zIndex: 10, visibility: !productAvailable ? "hidden" : 'visible'}}>
                    <IconButton onClick={onPressCart}>
                        <CartIcon
                            className={clsx(ProductSquareStyles.cartIcon, {[ProductSquareStyles.cartIconSelected]: isInCart})}/>
                    </IconButton>
                </Grid>
            </Grid>
        )
    };

    let getWishlistBottomRow = () => {
        return (
            <Grid container item xs={12} justify={"center"}>
                <Grid item xs={8} style={{position: "relative"}}>
                    {product.discount !== 0 && <Text className={ProductSquareStyles.priceWithoutDiscount}>{product.realPriceWithoutDiscount}€</Text>}
                    <Text className={ProductSquareStyles.price} style={{marginBottom: 20}}>{product.realPrice}€</Text>
                </Grid>
                <Grid item xs={8}>
                    <Button variant={"outlined"} color={"primary"} onClick={onPressHeart} fullWidth disableElevation style={{fontSize: Constants.FONT_SIZE_12, marginBottom: 10}} size={"small"}>
                        Retirer de la wishlist
                    </Button>
                </Grid>
                {(!user || !user.shop || user.shop.id !== product.shop.id) && product.quantity > 0 &&
                <Grid item xs={8}>
                    <Button variant={"contained"} color={"primary"} onClick={onPressCart} fullWidth disableElevation style={{fontSize: Constants.FONT_SIZE_12, marginBottom: 10}} size={"small"}>
                        {isInCart ? "Retirer du panier" : "Ajouter au panier"}
                    </Button>
                </Grid>}
            </Grid>
        )
    };

    let getMyProductsBottomRow = () => {
        return (
            <Grid container item xs={12} justify={"center"}>
                <Grid item xs={8} style={{position: "relative"}}>
                    {product.discount !== 0 && <Text className={ProductSquareStyles.priceWithoutDiscount}>{product.realPriceWithoutDiscount}€</Text>}
                    <Text className={ProductSquareStyles.price} style={{marginBottom: 20}}>{product.realPrice}€</Text>
                </Grid>
                <Grid item xs={8}>
                    <Button variant={"outlined"} color={"primary"} onClick={onPressRemove} fullWidth disableElevation style={{fontSize: Constants.FONT_SIZE_12, marginBottom: 10, visibility: currentPageParameters.productsType === 1 ? "hidden" : "visible"}}>
                        {product.status === 0 ? "Retirer de la vente" : "Remise en vente"}
                    </Button>
                </Grid>
                <Grid item xs={8}>
                    <Button variant={"contained"} color={"primary"} onClick={onPressEdit} fullWidth disableElevation style={{fontSize: Constants.FONT_SIZE_12, marginBottom: 10, visibility: currentPageParameters.productsType === 1 ? "hidden" : "visible"}}>
                        Modifier le produit
                    </Button>
                </Grid>
            </Grid>
        )
    };

    let getLiveProductBottomRow = () => {
        return (
            <Grid container item xs={12} justify={"center"}>
                <Grid item xs={8} style={{position: "relative"}}>
                    {product.discount !== 0 && <Text className={ProductSquareStyles.priceWithoutDiscount}>{product.realPriceWithoutDiscount}€</Text>}
                    <Text className={ProductSquareStyles.price} style={{marginBottom: 20}}>{product.realPrice}€</Text>
                </Grid>
                <Grid item xs={8}>
                    {(canAddLiveProduct || isLiveProduct) && <Button variant={isLiveProduct ? 'contained' : 'outlined'} color={"primary"} onClick={() => isLiveProduct ? removeLiveProduct(product) : canAddLiveProduct ? addLiveProduct(product) : null} fullWidth disableElevation style={{fontSize: Constants.FONT_SIZE_12, marginBottom: 10}} size={"small"}>
                        {isLiveProduct ? 'Retirer le produit' : canAddLiveProduct ? 'Ajouter le produit' : 'Limite atteinte'}
                    </Button>}
                    {(!canAddLiveProduct && !isLiveProduct) && <Text className={ProductSquareStyles.cantAddLive}>
                        Impossible d'ajouter plus de produits.
                    </Text>}
                </Grid>
            </Grid>
        )
    };

    let getCreateLiveBottomRow = () => {
        return (
            <Grid container direction={"column"}>
                <Grid container item xs={12} justify={"space-between"} alignItems={"center"}>
                    <Grid item style={{zIndex: 10}}>
                        <IconButton onClick={() => setLiveFirstProduct(product)}>
                            {liveProduct.position == 0 ? <StarIcon className={ProductSquareStyles.starIconSelected} /> : <StarBorderIcon className={ProductSquareStyles.starIcon}/>}
                        </IconButton>
                    </Grid>
                    <Grid item style={{position: "relative"}}>
                        {product.discount !== 0 && <Text className={ProductSquareStyles.priceWithoutDiscount}>{product.product.realPriceWithoutDiscount}€</Text>}
                        <Text className={ProductSquareStyles.price}>{product.realPrice}€</Text>
                    </Grid>
                    <Grid item style={{zIndex: 10}}>
                        <IconButton onClick={() => removeLiveProduct(product)}>
                            <TrashIcon
                                className={clsx(ProductSquareStyles.starIcon)} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container item alignItems={"center"} justify={"space-between"} style={{paddingLeft: 5, paddingRight: 5}}>
                    <Grid item><Text>Min. :</Text></Grid>
                    <Grid item style={{maxWidth: 175}}><TextField type={"number"} variant={"outlined"} placeholder={"Prix le plus bas"} value={minPrice} onChange={event => setMinPrice(event.target.value)}/></Grid>
                </Grid>
            </Grid>
        )
    };

    let getViewLiveStreamingBottomRow = () => {
        return (
            <Grid container item xs={12} justify={"space-between"} alignItems={"center"}>
                <Grid item style={{zIndex: 10}}>
                </Grid>
                <Grid item style={{position: "relative"}}>
                    {product.discount !== 0 && <Text className={ProductSquareStyles.priceWithoutDiscount}>{product.realPriceWithoutDiscount}€</Text>}
                    <Text className={ProductSquareStyles.price}>{product.realPrice}€</Text>
                </Grid>
                <Grid item style={{zIndex: 10}}>
                </Grid>
            </Grid>
        )
    };

    let getViewLiveNotStreamingBottomRow = () => {
        return (
            <Grid container item xs={12} justify={"space-between"} alignItems={"center"}>
                <Grid item style={{zIndex: 10, position: "relative"}}>
                    <IconButton onClick={isLiveAdmin ? null : onPressHeart}>
                        {isLoadingHeart ? <CircularProgress color={"primary"} className={ProductSquareStyles.loader} size={30} /> : <HeartIcon className={clsx(ProductSquareStyles.heartIcon, {[ProductSquareStyles.heartIconSelected]: isInWishlist})} />}
                    </IconButton>
                    <Text style={{position: "absolute", top: "50%", transform: "translateY(-50%)", right: product.nbWishers > 100 ? -20 : product.nbWishers > 10 ? -12.5 : -5, color: Constants.PINK}}>{product.nbWishers}</Text>
                </Grid>
                <Grid item style={{position: "relative"}}>
                    {product.discount !== 0 && <Text className={ProductSquareStyles.priceWithoutDiscount}>{product.realPriceWithoutDiscount}€</Text>}
                    <Text className={ProductSquareStyles.price}>{product.realPrice}€</Text>
                </Grid>
                <Grid item style={{zIndex: 10, visibility: "hidden"}}>
                    <IconButton onClick={isLiveAdmin ? null : onPressHeart}>
                        {isLoadingHeart ? <CircularProgress color={"primary"} className={ProductSquareStyles.loader} size={30} /> : <HeartIcon className={clsx(ProductSquareStyles.heartIcon, {[ProductSquareStyles.heartIconSelected]: isInWishlist})} />}
                    </IconButton>
                </Grid>
            </Grid>
        )
    };

    let bottomRow;
    if (currentWishlistPage) {
        bottomRow = getWishlistBottomRow();
    } else if (myProductsPage) {
        bottomRow = getMyProductsBottomRow();
    } else if (isLivePage) {
        if (liveProduct) {
            bottomRow = getViewLiveStreamingBottomRow();
        } else {
            bottomRow = getViewLiveNotStreamingBottomRow();
        }
    } else if (addLiveProduct) {
        bottomRow = getLiveProductBottomRow();
    } else if (liveProduct) {
        bottomRow = getCreateLiveBottomRow();
    } else {
        if (product.quantity <= 0) {
            productAvailable = false;
        }
        if (!product.chronopost && !product.mondialRelay && !product.handOver && !product.clickAndCollect) {
            productAvailable = false;
        }
        if (user && user.shop && user.shop.id === product.shop.id) {
            productAvailable = false;
        }
        if ((user && user.status === 1) || (product && product.shop && product.shop.owner && product.shop.owner.status === 1)) {
            productAvailable = false;
        }
        bottomRow = getBottomRow();
    }

    return (<Card
        elevation={0}
        className={clsx(
            ProductSquareStyles.wrapper,
            {[ProductSquareStyles.wrapperWithBackground]: !withoutBackground},
            wrapperStyle
        )}>
        <CardActionArea onClick={isLivePage && liveProduct && product.quantity > 0 ? () => setProductDetailsPopupVisibility(true) : null} href={isLivePage && liveProduct ? null : product.quantity <= 0 ? null : "/product/" + product.id} className={clsx(ProductSquareStyles.cardActionArea)}>
            {product.discount !== 0 && <Text className={ProductSquareStyles.discount}>
                - {product.discount} %
            </Text>}
            {product.returnPossible && <AutorenewIcon style={{color: "#F8ADB1", position: "absolute", top: 5, left: 5}}/>}
            <CardMedia
                image={getImageUrl(product.pictures[0] != null ? product.pictures[0].contentUrl : null, "medium")}
                className={clsx(ProductSquareStyles.image, {[ProductSquareStyles.imageWithoutBackground]: smaller})}
            />
            {product.quantity <= 0 && <span className={ProductSquareStyles.sold}>VENDU</span>}
            {product.quantity > 0 && !!product.reserved && product.reserved >= product.quantity && <span className={ProductSquareStyles.sold}>Réservé</span>}
            <CardContent style={{padding: 0, textAlign: "center"}}>
                <Text className={ProductSquareStyles.productName}>{product.name}</Text>
                {brand && <Text className={ProductSquareStyles.colorAndSize}>{brand}</Text>}
                {size && <Text className={ProductSquareStyles.colorAndSize}>{size}</Text>}
            </CardContent>
        </CardActionArea>
        {bottomRow && <CardActions className={clsx(ProductSquareStyles.cardActions, {[ProductSquareStyles.smallCardActions]: !currentWishlistPage && !myProductsPage && !addLiveProduct})}>
            {bottomRow}
        </CardActions>}
        {!myProductsPage && <AddToCartModal visible={modalVisible} setVisible={setModalVisible} product={product}/>}
        {isLivePage && liveProduct && <LiveProductDetailsModal isLiveAdmin={isLiveAdmin} liveProduct={liveProduct} visible={productDetailsPopupVisibility} setVisible={setProductDetailsPopupVisibility} instantBuy={instantBuy} sendProposition={sendProposition} propositionInProgress={propositionInProgress} setPropositionInProgress={setPropositionInProgress} />}
    </Card>);
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        cart: state.product.cart,
        wishlist: state.product.wishlist
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addToCart: (product, quantity, color, size) => {
            dispatch({type: 'ADD_TO_CART', product, quantity, color, size})
        },
        removeFromCart: (product) => {
            dispatch({type: 'REMOVE_FROM_CART', product})
        },
        addToWishlist: (product, currentWishlistPage, itemsPerPage) => {
            dispatch({type: 'ADD_TO_WISHLIST', product, subscribe: !product.isWish, page: currentWishlistPage, itemsPerPage})
        },
        removeProduct: (product, currentPageParameters) => {
            dispatch({type: 'REMOVE_PRODUCT_REQUESTED', id: product.id, ...currentPageParameters})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent;
