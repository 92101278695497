import React from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import SocialShareBarStylesFunc from '../../Style/SocialShareBarStyle'
import MailLogo from '../../Assets/Images/mail_logo.svg'
import MessengerLogo from '../../Assets/Images/facebook_logo.svg'
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import ShareIcon from '@material-ui/icons/Share';

function Component({link, mailTitle}) {
    let SocialShareBarStyles = SocialShareBarStylesFunc();
    let handleMessenger = () => {
        window.FB.ui({
            method: 'share',
            href: window.location.href,
            quote: 'Découvrez ce superbe produit'
        }, function(response){});
    };

    let handleShare = () => {
        navigator.share({
            title: mailTitle,
            text: 'Découvrez ce superbe produit',
            url: window.location.href,
        })
    };

    return (
        <Box className={SocialShareBarStyles.wrapper}>
            <Button className={SocialShareBarStyles.iconWrapper} accessibilityRole={"link"} href={"mailto:?body=" + (window.location.href) + "&subject=" + mailTitle} target={"_blank"}>
                <CardMedia component={"img"} src={MailLogo} className={SocialShareBarStyles.mailIcon}/>
            </Button>
            <Button className={SocialShareBarStyles.iconWrapper} onClick={handleMessenger}>
                <CardMedia component={"img"} src={MessengerLogo} className={SocialShareBarStyles.messengerIcon}/>
            </Button>
            {navigator.share && <Button className={SocialShareBarStyles.iconWrapper} onClick={handleShare}>
                <ShareIcon style={{color: "#878787"}} />
            </Button>}
        </Box>
    );
}

export default Component;
