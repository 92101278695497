import React from "react";
import { TouchableOpacity } from "react-native-web";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";

import Text from "../Component/FontText"
import ProductSquare from "../Front/Component/ProductSquare"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/WishlistStyle"
import * as Constants from "../../Style/Constants"
import Paginator from "../Front/Component/Paginator";


function Component({navigation, token, user, wishlist, pagination, addToWishlist, fetchWishlist, emptyWishlist}) {
    let Styles = StylesFunc();
    let [page, setPage] = React.useState(1);

    React.useEffect(() => {
        if (token) {
            fetchWishlist(page, 9);
        }
    }, [token, page]);

    React.useEffect(() => {
        if (page > pagination.last) {
            setPage(pagination.last);
        } else if (page < pagination.first) {
            setPage(pagination.first);
        }
    }, [pagination]);

    return <Base
        allowAnon={true}
        content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Mes coups de coeur</Text>
            <Box className={Styles.descriptionWrapper}>
                <Text className={Styles.description}>Retrouvez tous vos articles favoris ici !<br /><br />
                    Attention, les articles étant de seconde main, l’offre est unique, nous vous conseillons donc de les
                    ajouter rapidement dans votre panier pour ne pas louper une bonne affaire.</Text>
            </Box>

            <TouchableOpacity onPress={() => emptyWishlist()} className={Styles.button} style={wishlist.length === 0 ? {backgroundColor: Constants.BACKGROUND_GRAY} : {}} disabled={wishlist.length === 0}>
                <Text className={Styles.buttonLabel}>{wishlist.length === 0 ? "Votre wishlist est vide" : "Vider ma wishlist"}</Text>
            </TouchableOpacity>

            <Box className={Styles.contentWrapper}>
                <Hidden xsDown>
                    {token && <LeftMenu currentPage={"WISHLIST"} />}
                </Hidden>
                <Grid container className={Styles.wishlistWrapper}>
                    {wishlist.map(product =>
                        <Grid item xs={12} sm className={Styles.productWrapperGrid}><ProductSquare product={product} currentWishlistPage={page} itemsPerPage={9} wrapperStyle={Styles.productWrapper}/></Grid>
                    )}
                </Grid>
            </Box>
            {pagination && <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE}/>}
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        wishlist: state.product.wishlist,
        pagination: state.product.wishlistPagination,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addToWishlist: (product) => {
            dispatch({type: 'ADD_TO_WISHLIST', product, subscribe: product.isWish})
        },
        fetchWishlist: (page, itemsPerPage) => {
            dispatch({type: 'FETCH_WISHLIST_REQUESTED', page, itemsPerPage})
        },
        emptyWishlist: () => {
            dispatch({type: 'EMPTY_WISHLIST_REQUESTED'})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
