import React from "react";
import Moment from "moment";
import clsx from 'clsx';
import axios from 'axios';

import { connect } from 'react-redux';
import {useParams, withRouter} from "react-router-dom";
import { CardCvcElement, CardNumberElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/OutlinedInput";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/CreateLiveStyle"
import ProductSquare from "../Front/Component/ProductSquare"
import {KeyboardDatePicker as DatePicker} from "@material-ui/pickers";
import {ErrorMessage} from "@hookform/error-message";
import * as Constants from "../../Style/Constants";
import {useForm} from "react-hook-form";
import ErrorStyleFunc from "../Styles/Error";
import LiveAddProductModal from "./Component/LiveAddProductModal";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";
import {FetchProduct} from "../../API/Endpoint/Product";
import CustomCheckbox from "../Component/CustomCheckbox";
import Link from "@material-ui/core/Link/Link";
import {InternalLink, LINKS} from "../Component/InternalLink";
import {getImageUrl} from "../../Utils/ImageUtils";
import Paginator from "../Front/Component/Paginator";
import CheckIcon from '@material-ui/icons/Check';

import ConfirmationModal from "../Front/Component/ConfirmationModal";
import LiveCreateExplanationModal from "../Front/Component/LiveCreateExplanationModal";

const ITEMS_PER_PAGE = 9;

const SLOTS = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

function Component({navigation, history, screenKey, token, me, startLoading, stopLoading, isLoading, fetchLive}) {

    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { clearErrors, setValue, setError, handleSubmit, register, errors } = useForm({shouldFocusError: true});

    let [page, setPage] = React.useState(1); // 0 = Paramètres / 1 = Paiement / 2 = Confirmation
    let [productsPage, setProductsPage] = React.useState(1);
    let [liveProducts, setLiveProducts] = React.useState([]);
    let [startAt, setStartAt] = React.useState(null);
    let [slot, setSlot] = React.useState(null);
    let [addProductModalVisibility, setAddProductModalVisibility] = React.useState(false);
    let [live, setLive] = React.useState(null);
    let [cgv, setCGV] = React.useState(false);
    let [selectedCard, setSelectedCard] = React.useState(-1);
    let [memorizeCard, setMemorizeCard] = React.useState(false);
    let [livePrice, setLivePrice] = React.useState(null);
    let [realPrice, setRealPrice] = React.useState(null);
    let [nbProductsMax, setNbProductsMax] = React.useState(30);

    let [errorModal, setErrorModal] = React.useState(null);

    let [livePromotionCode, setLivePromotionCode] = React.useState("");
    const [cancelPopupVisibility, setCancelPopupVisibility] = React.useState(false);
    const [createPopupVisibility, setCreatePopupVisibility] = React.useState(false);
    const [alreadyDisplayedCreatePopupVisibility, setAlreadyDisplayedCreatePopupVisibility] = React.useState(false);

    let params = useParams();

    React.useEffect(() => {
        if (me && !me.hideLiveCreatePopup && !alreadyDisplayedCreatePopupVisibility) {
            setAlreadyDisplayedCreatePopupVisibility(true);
            setCreatePopupVisibility(true);
        }
    }, [me])

    React.useEffect(() => {
        if (screenKey && screenKey === "EditLive" && !live) {
            setLive({id: params.id});
            startLoading();
            axios.get(
                getApiUrl('lives/' + params.id + '/live_products'),
                {headers: setDefaultHeaders()}
            ).then((response) => {
                setLiveProducts(response.data['hydra:member']);
                stopLoading();
            }).catch((error) => {
                stopLoading();
            });
        }
    }, [params.id]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        startLoading();
        axios.get(
            getApiUrl('params'),
            {headers: setDefaultHeaders()}
        ).then((response) => {
            response.data['hydra:member'].map(param => {
                if (param.name === "live_price") {
                    let realPrice = param.value;
                    setLivePrice(realPrice);
                    if (live && live.promotion) {
                        realPrice = Math.max(0, realPrice - live.promotion.value);
                    }
                    setRealPrice(realPrice);
                } else if (param.name === "live_max_products") {
                    setNbProductsMax(param.value);
                }
            })
            stopLoading();
        }).catch((error) => {
            stopLoading();
        });
    }, [page, live]);

    let minStartAt = new Date();
    let maxStartAt = new Date();
    maxStartAt.setDate(maxStartAt.getDate() + 29);

    React.useEffect(() => {
        if (startAt !== null) {
            clearErrors('startAt');
        }
        if (slot !== null) {
            clearErrors('slot');
        }
        if (liveProducts.length > 0) {
            clearErrors('liveProducts');
        }
    }, [startAt, slot, liveProducts]);

    let addLiveProduct = (product) => {
        setLiveProducts([...liveProducts, {product: product, position: liveProducts.length}]);
    }

    let addLiveProducts = (products) => {
        let newLiveProducts = [];
        for (const p of products) {
            newLiveProducts.push({product: p, position: liveProducts.length + newLiveProducts.length})
        }
        setLiveProducts([...liveProducts, ...newLiveProducts]);
    }

    let removeLiveProduct = (product) => {
        const index = liveProducts.findIndex((element) => element.product.id === product.id);
        if (index !== -1) {
            setLiveProducts([
                ...liveProducts.slice(0, index),
                ...liveProducts.slice(index + 1)
            ]);
        }
    }

    let clearLiveProducts = () => {
        setLiveProducts([]);
    }

    let setLiveFirstProduct = (product) => {
        const index = liveProducts.findIndex((element) => element.product.id === product.id);
        if (index !== -1) {
            const oldPosition = liveProducts[index].position;
            const duplicate = [...liveProducts];
            for (let i = 0; i < liveProducts.length; i++) {
                if (duplicate[i].position < oldPosition) {
                    duplicate[i].position += 1;
                } else if (duplicate[i].position == oldPosition) {
                    duplicate[i].position = 0;
                }
            }
            duplicate.sort((a, b) => a.position - b.position);
            console.log(duplicate);
            setLiveProducts(duplicate);
        }
    }

    const stripe = useStripe();
    const elements = useElements();

    const submitPayment = () => {
        let slotSoldOut = false;
        if (slotSoldOut === true) {
            history.push("/inscription_live", {error: "slot_sold_out"});
        } else {

            if (realPrice > 0) {
                if (!stripe || !elements) {
                    // Stripe.js has not yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return;
                }

                let data = {};

                if (memorizeCard) {
                    data["setup_future_usage"] = 'on_session';
                }

                if (selectedCard !== -1) {
                    data["payment_method"] = me.savedCardId[selectedCard];
                } else {
                    data["payment_method"] = {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: me.username
                        }
                    }
                }

                axios.put(
                    getApiUrl('lives/' + live.id),
                    {status: 1},
                    {headers: setDefaultHeaders({'Content-Type': 'application/json'})}
                ).then(async (response) => {
                    const result = await stripe.confirmCardPayment(live.stripeClientSecret, data);

                    if (result.error) {
                        // Show error to your customer (e.g., insufficient funds)
                        setError("card", {type: "invalid", message: result.error.message});
                        stopLoading();
                    } else {
                        // The payment has been processed!
                        if (result.paymentIntent.status === 'succeeded') {
                            setPage(3);
                            stopLoading();
                        }
                    }
                }).catch((error) => {
                    stopLoading();
                });
            } else { // Free live \o/

                // TODO : HERE
                startLoading();
                axios.put(
                    getApiUrl('lives/' + live.id + '/validate-free'),
                    {},
                    {headers: setDefaultHeaders({'Content-Type': 'application/json'})}
                ).then((response) => {
                    setPage(3);
                    stopLoading();
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    stopLoading();
                });

            }
        }
    };

    let savedCardsDom = [];
    if (me && me.savedCard) {
        for (let i = 0; i < me.savedCard.length; i++) {
            savedCardsDom.push(
                <CustomCheckbox
                    size={Constants.CHECKBOX_SIZE_BIG}
                    containerStyle={{marginBottom: Constants.GET_SIZE(10)}}
                    containerClass={Styles.checkboxContainer}
                    checked={selectedCard === i}
                    title={<Text className={Styles.checkboxLabel}>Carte enregistrée **** {me.savedCard[i]}</Text>}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    checkedIcon={"square"}
                    onPress={() => {setSelectedCard(i)}}
                />
            )
        }
    }

    let _getHeader = () => {
        if (screenKey && screenKey === "EditLive") {
            return null;
        } else {
            return <Box className={Styles.headerWrapper}>
                <Text className={clsx(Styles.headerStep, {[Styles.headerStepActive]: page === 1})}>1. Paramètres du
                    live</Text>
                <Text className={clsx(Styles.headerStep, {[Styles.headerStepActive]: page === 2})}>2. Paiement</Text>
                <Text className={clsx(Styles.headerStep, {[Styles.headerStepActive]: page === 3})}>3.
                    Confirmation</Text>
            </Box>
        }
    };

    let sameDayLives = [];
    if (startAt && me) {
        for (let i = 0; i < me.shop.lives.length; i++) {
            let live = me.shop.lives[i];
            let isSameDay = Moment(startAt).isSame(live.startAt, "day");
            if (isSameDay) {
                sameDayLives.push(live);
            }
        }
    }

    let _getSlotButton = (availableSlot, key) => {
        const isToday = Moment(startAt).isSame(new Date(), "day");
        const hour = Moment().get('hour');
        const from = availableSlot.toString() + "h";
        const to = ((availableSlot + 1) === 24 ? "00" : (availableSlot + 1).toString()) + "h";

        let disabled = false;
        for (let i = 0; i < sameDayLives.length; i++) {
            let live = sameDayLives[i];
            let availableSlotDate = Moment(startAt).set('hour', availableSlot.toString()).set('minute', 0).set('second', 0);
            if (availableSlotDate.unix() === Moment(live.startAt).unix()) {
                console.log("yes !", from);
                disabled = true;
            }
        }
        return <Button
            key={availableSlot}
            disableElevation
            disabled={(isToday && availableSlot <= hour) || disabled}
            className={clsx(Styles.slotButton, {[Styles.slotButtonUnselected]: key !== slot})}
            variant={"contained"}
            color={key === slot ? "primary" : ""}
            onClick={() => setSlot(key)}>
            {from + " - " + to}
        </Button>;
    }

    let _getDate = () => {
        if (screenKey && screenKey === "EditLive") {
            return null;
        } else {
            return <Grid className={clsx(Styles.section)}>
                <Text className={clsx(Styles.sectionTitle)}>Créneau</Text>
                <Text className={clsx(Styles.sectionDescription)}>Assurez-vous d’être bien disponible sur le créneau que
                    vous aurez choisis. Une fois réservé, vous pourrez l’annuler, mais vous ne pourrez plus le
                    modifier.</Text>
                <Box style={{marginBottom: 20, maxWidth: 430}}>
                    <DatePicker
                        value={startAt}
                        onChange={(value) => {
                            setSlot(null);
                            clearErrors("startAt");
                            setStartAt(value);
                        }}
                        format="DD/MM/YYYY"
                        showYearDropdown
                        yearDropdownItemNumber={5}
                        minDate={minStartAt}
                        maxDate={maxStartAt}
                        placeholder="Choisissez un jour*"
                        invalidDateMessage={"Format de date invalide"}
                        minDateMessage={"Vous ne pouvez pas vous inscrire à un créneau passé"}
                        maxDateMessage={"Vous ne pouvez pas vous inscrire à un créneau dans plus de 30 jours"}
                        inputVariant={"outlined"}
                        className={clsx(Styles.startAtPicker)}
                    />
                    <ErrorMessage name={"startAt"} errors={errors} as={Text} className={ErrorStyle.error}
                                  style={{marginBottom: 10}}/>

                    {startAt && <Grid container>
                        <Grid xs={12} sm={6} className={clsx(Styles.slotColumn1)}>
                            {SLOTS.slice(0, SLOTS.length / 2).map((availableSlot, key) => _getSlotButton(availableSlot, key))}
                        </Grid>
                        <Grid xs={12} sm={6} className={clsx(Styles.slotColumn2)}>
                            {SLOTS.slice(SLOTS.length / 2, SLOTS.length).map((availableSlot, key) => _getSlotButton(availableSlot, key + SLOTS.length / 2))}
                        </Grid>
                        <ErrorMessage name={"slot"} errors={errors} as={Text} className={ErrorStyle.error}/>
                    </Grid>}
                </Box>
            </Grid>
        }
    }

    let setMinPrice = (liveProduct, minPrice) => {
        let index = liveProducts.findIndex(elem => elem.product.id === liveProduct.product.id);
        if (index !== -1) {
            setLiveProducts([
                ...liveProducts.slice(0, index),
                Object.assign({}, liveProduct, {minPrice}),
                ...liveProducts.slice(index + 1),
            ])
        }
    }

    let _getProducts = () => {
        return <Grid className={clsx(Styles.section)}>
            <Text className={clsx(Styles.sectionTitle)}>Produits</Text>
            <Text className={clsx(Styles.sectionDescription)}>Choisissez les produits de votre marketplace que vous souhaitez présenter en Live. Ces produits ne seront plus disponible sur votre boutique normal durant votre live.</Text>
            <Text className={clsx(Styles.sectionDescription)}>Vous pourrez modifier cette liste avant le live.</Text>
            <Text className={clsx(Styles.sectionDescription)}>Indiquez un prix minimum de vos articles si vous le voulez.</Text>

            <Button
                color={"primary"} variant={"contained"} disableElevation size={"small"}
                onClick={() => setAddProductModalVisibility(true)}>
                Ajouter des produits
            </Button>
            <ErrorMessage name={"liveProducts"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: 10}} />

            <Grid container>
                {liveProducts.sort((a, b) => a.position - b.position).slice(ITEMS_PER_PAGE * (productsPage - 1), ITEMS_PER_PAGE * productsPage).map(liveProduct => <Grid xs={12} sm style={{maxWidth: 225}} className={Styles.productWrapperWrapper}>
                    <ProductSquare
                        key={"product-" + liveProduct.product.id}
                        wrapperStyle={Styles.productWrapper}
                        liveProduct={liveProduct}
                        minPrice={liveProduct.minPrice ?? null}
                        setMinPrice={minPrice => setMinPrice(liveProduct, minPrice)}
                        removeLiveProduct={removeLiveProduct}
                        smaller
                        setLiveFirstProduct={setLiveFirstProduct} />
                </Grid>)}
            </Grid>
            <Paginator firstPage={1} lastPage={Math.ceil(liveProducts.length / ITEMS_PER_PAGE)} page={productsPage} setPage={setProductsPage} backgroundColor={Constants.BACKGROUND_LIGHT_GRAY} />
        </Grid>
    }

    const createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    fontSize,
                    color: 'black',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    ...(padding ? {padding} : {}),
                },
                invalid: {
                    color: '#CA8A8F',
                },
            },
            hidePostalCode: true
        };
    };

    const validateCodePromo = () => {
        startLoading();
        axios.put(
            getApiUrl('lives/' + live.id + '/apply-promotion'),
            {code: livePromotionCode},
            {headers: setDefaultHeaders({'Content-Type': 'application/json'})}
        ).then((response) => {
            setLive(response.data);
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 404) {
                    setErrorModal('Le code promo est invalide.');
                } else if (error.response.status == 400) {
                    setErrorModal('Le code promo n\'est plus valide.');
                } else {
                    setErrorModal('Une erreur inconnue a eu lieu.');
                }
            } else {
                setErrorModal('Une erreur inconnue a eu lieu.');
            }
            console.error(error);
        }).finally(() => {
            stopLoading();
        });
    }

    let _getContent = () => {
        if (page === 1) {
            return <Grid className={clsx(Styles.grayBlock)}>
                {_getDate()}
                {_getProducts()}
            </Grid>
        } else if (page === 2) {
            return <Box className={Styles.wrapper}>
                <Text className={clsx(Styles.paymentTitle)}>Paiement</Text>
                {(!live || !live.promotion) && <Grid container justify={"space-between"} alignItems={"center"} className={Styles.promoWrapper}>
                    <Text className={clsx(Styles.paymentText, Styles.promoPaymentText)}>Code promotionnel</Text>
                    <TextField
                        variant={"outlined"}
                        className={Styles.promotionCodeInput}
                        inputProps={{style: {padding: 5, height: 20}}}
                        value={livePromotionCode}
                        onChange={e => setLivePromotionCode(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={validateCodePromo}
                                >
                                    <CheckIcon className={Styles.promotionCodeIcon}/>
                                </IconButton>
                            </InputAdornment>
                        }/>
                </Grid>}
                <Grid container justify={"space-between"}>
                    <Text className={clsx(Styles.paymentText)}>Créneau</Text>
                    <Text className={clsx(Styles.paymentText)}>{livePrice} €</Text>
                </Grid>
                {live && live.promotion && <Grid container justify={"space-between"}>
                    <Text className={clsx(Styles.paymentText)}>Promotion</Text>
                    <Text className={clsx(Styles.paymentText)}>- {live.promotion.value} €</Text>
                </Grid>}
                <Grid container justify={"space-between"}>
                    <Text className={clsx(Styles.paymentText)}>Total</Text>
                    <Text className={clsx(Styles.paymentText)}>{realPrice} €</Text>
                </Grid>

                <CustomCheckbox
                    containerClass={Styles.checkboxContainer}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={clsx(Styles.checkboxLabel, {[ErrorStyle.error]: errors["cgv"]})}>J'accepte les <Link target="_blank" href={LINKS["STATIC_CGV"]["web"]} style={{width: "max-content", color: Constants.PINK, textDecoration: "underline", textDecorationColor: Constants.PINK}}>
                        conditions générales de ventes.*
                    </Link></Text>}
                    checked={cgv}
                    onPress={() => {clearErrors("cgv"); setCGV(!cgv)}}
                    size={"large"}
                    error={errors['cgv']}
                />
                {realPrice > 0 && <InternalLink link={"HOMEPAGE"} label={"En savoir plus"} wrapperStyle={Styles.knowMoreText}/>}

                {realPrice > 0 && savedCardsDom}
                {realPrice > 0 && <CustomCheckbox
                    size={Constants.CHECKBOX_SIZE_BIG}
                    containerClass={Styles.checkboxContainer}
                    checked={selectedCard === -1}
                    title={<Text className={Styles.checkboxLabel}>Ajouter une autre carte</Text>}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    checkedIcon={"square"}
                    onPress={() => {setSelectedCard(-1)}}
                />}
                {realPrice > 0 && selectedCard === -1 &&
                <Box className={Styles.stripeFieldWrapper}>
                    <CardNumberElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>}
                {realPrice > 0 && selectedCard === -1 &&<Box className={Styles.stripeFieldWrapper}>
                    <CardExpiryElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>}
                {realPrice > 0 && selectedCard === -1 &&<Box className={Styles.stripeFieldWrapper}>
                    <CardCvcElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>}
                {realPrice > 0 && <ErrorMessage name={"card"} errors={errors} as={Text} className={ErrorStyle.error} />}
                {realPrice > 0 && selectedCard === -1 &&<CustomCheckbox
                    containerClass={Styles.checkboxContainer}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={Styles.checkboxLabel}>Mémoriser cette carte</Text>}
                    checked={memorizeCard}
                    onPress={() => setMemorizeCard(!memorizeCard)}
                    size={Constants.CHECKBOX_SIZE_BIG}
                />}
            </Box>
        } else if (page === 3) {
            if (screenKey && screenKey === 'EditLive') {
                return <Grid className={clsx(Styles.grayBlock)}>
                    <Text>
                        Félicitations, votre live a bien été modifié.
                    </Text>
                </Grid>
            } else {
                return <Grid className={clsx(Styles.grayBlock)}>
                    <Text>
                        Félicitations, ton inscription à ce live a bien été enregistrée.
                    </Text>
                </Grid>
            }
        } else if (page === 4) {
            return <Grid className={clsx(Styles.grayBlock)}>
                <Text>
                    Votre live a bien été annulé.
                </Text>
            </Grid>
        }
    }

    let upsertCallback = (response) => {
        if (response && response.data) {
            setLive(response.data);
            const liveId = '/api/lives/' + response.data['id'];
            const promises = [];
            for (const liveProduct of liveProducts) {
                let minPrice = liveProduct.minPrice ? parseFloat(liveProduct.minPrice.toString().replace(',', '.')) : 0;
                promises.push(axios.post(
                    getApiUrl('live_products'),
                    {minPrice: minPrice, position: liveProduct.position, live: liveId, product: 'api/products/' + liveProduct.product.id},
                    {headers: setDefaultHeaders({'Content-Type': 'application/json'})}
                ));
            }
            Promise.all(promises).then((responses) => {
                for (const response of responses) {
                    console.log(response.data);
                }
                if (screenKey && screenKey === 'EditLive') {
                    setPage(3);
                } else {
                    setPage(2);
                }
                stopLoading();
            }).catch((errors) => {
                for (const error of errors) {
                    console.log(error);
                }
                stopLoading();
            });
        } else {
            stopLoading();
            console.error(response);
        }
    }

    const askCancelLive = () => {
        setCancelPopupVisibility(true);
    }

    let cancelLive = () => {
        setCancelPopupVisibility(false);
        startLoading();
        axios.delete(
            getApiUrl('lives/' + live.id),
            {liveProducts: []},
            {headers: setDefaultHeaders({'Content-Type': 'application/json'})}
        ).then((response) => {
            setPage(4);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            stopLoading();
        });
    }

    let goToNextPage = () => {
        if (page === 1) {
            let error = false;
            if (!screenKey || screenKey !== 'EditLive') {
                if (startAt === null) {
                    setError('startAt', {type: "custom", message: "Vous devenez choisir un créneau."});
                    error = true;
                }
                if (slot === null) {
                    setError('slot', {type: "custom", message: "Vous devenez choisir un créneau."});
                    error = true;
                }
            }
            if (liveProducts.length < 1) {
                setError('liveProducts', {type: "custom", message: "Vous devenez choisir au moins 1 produit."});
                error = true;
            }
            if (!error) {
                startLoading();
                if (screenKey && screenKey === 'EditLive') {
                    axios.put(
                        getApiUrl('lives/' + live.id),
                        {liveProducts: []},
                        {headers: setDefaultHeaders({'Content-Type': 'application/json'})}
                    ).then((response) => {
                        upsertCallback(response)
                    }).catch((error) => {
                        stopLoading();
                        console.error(error);
                    });
                } else {
                    axios.post(
                        getApiUrl('lives'),
                        {startAt, slot},
                        {headers: setDefaultHeaders({'Content-Type': 'application/json'})}
                    ).then((response) => {
                        upsertCallback(response)
                    }).catch((error) => {
                        stopLoading();
                        console.error(error);
                    });
                }
            }
        } else if (page === 2) {
            if (!cgv) {
                setError("cgv", {type: "invalid", message: "Vous devez accepter les conditions générales de vente pour continuer"});
            } else {
                startLoading();
                submitPayment();
            }
        } else if (page === 3) {
            history.push('/dashboard');
        }
    }

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>{screenKey && screenKey === "EditLive" ? "Modifier le live" : "Créer un live" }</Text>
            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={screenKey && screenKey === "EditLive" ? "MY_LIVES" : "CREATE_LIVE"} />
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    {_getHeader()}
                    {_getContent()}
                    {screenKey && screenKey === "EditLive" && page < 3 &&
                        <Grid>
                            <Button
                                color={"primary"} variant={"contained"} disableElevation size={"small"}
                                onClick={() => goToNextPage()}
                                style={{marginTop: 10, marginRight: 10}}>
                                Enregistrer les modifications
                            </Button>
                            <Button
                                color={"secondary"} variant={"contained"} disableElevation size={"small"}
                                onClick={() => askCancelLive()}
                                style={{marginTop: 10}}>
                                Annuler le live
                            </Button>
                        </Grid>}
                    {(!screenKey || screenKey !== "EditLive" || page === 3) &&
                        <Button
                            color={"primary"} variant={"contained"} disableElevation size={"small"}
                            onClick={() => goToNextPage()}
                            style={{marginTop: 10}}>
                            {page === 2 ? "Valider et payer" : "Continuer"}
                        </Button>}
                </Box>
            </Grid>
            <LiveAddProductModal
                visible={addProductModalVisibility}
                setVisible={setAddProductModalVisibility}
                nbProductsMax={nbProductsMax}
                addLiveProduct={addLiveProduct}
                addLiveProducts={addLiveProducts}
                clearLiveProducts={clearLiveProducts}
                removeLiveProduct={removeLiveProduct}
                liveProducts={liveProducts}/>
            <ConfirmationModal
                visible={errorModal !== null}
                message={errorModal}
                onConfirm={() => setErrorModal(null)}
            />
            <ConfirmationModal
                visible={cancelPopupVisibility}
                message={"Êtes-vous sûr de vouloir annuler ce live ?"}
                onConfirm={() => cancelLive()}
                onCancel={() => setCancelPopupVisibility(false)}
            />
            <LiveCreateExplanationModal
                visible={createPopupVisibility}
                setVisible={setCreatePopupVisibility}
            />
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        me: state.user.me,
        isLoading: state.loading.isLoading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchLive: (id) => {
            dispatch({type: 'FETCH_LIVE_REQUESTED', id})
        },
        startLoading: () => {
            dispatch({type: 'START_LOADING_REQUESTED'})
        },
        stopLoading: () => {
            dispatch({type: 'START_LOADING_SUCCEEDED'})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
