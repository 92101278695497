import React from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MyProductsStyle"
import * as Constants from "../../Style/Constants"
import ProductSquare from "../Front/Component/ProductSquare"
import Paginator from "../Front/Component/Paginator";

const ITEMS_PER_PAGE = 15;

function Component({navigation, history, screenKey, token, user, productsList, pagination, fetchMyProducts}) {
    let Styles = StylesFunc();
    let [page, setPage] = React.useState(1);
    let [productsType, setProductsType] = React.useState(0); // 0 en vente / 1 vendus / 2 retirés

    React.useEffect(() => {
        if (!user || !user.shop) {
            history.push("/creer_ma_boutique");
        }
    }, [user]);

    React.useEffect(() => {
        fetchMyProducts(productsType, page, ITEMS_PER_PAGE, productsList && productsList.length > 0);
    }, [productsType, page]);

    let _getTabs = () => {
        return <Box className={Styles.tabsWrapper}>
            <Button variant={productsType === 0 ? "contained" : "outlined"} color={"primary"} disableElevation onClick={() => setProductsType(0)} size={"small"}>
                Produits en vente
            </Button>
            <Button variant={productsType === 1 ? "contained" : "outlined"} color={"primary"} disableElevation onClick={() => setProductsType(1)} size={"small"}>
                Produits vendus
            </Button>
            <Button variant={productsType === 2 ? "contained" : "outlined"} color={"primary"} disableElevation onClick={() => setProductsType(2)} size={"small"}>
                Produits retirés
            </Button>
        </Box>
    }

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Mes annonces</Text>
            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={"MY_PRODUCTS"} />
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    {_getTabs()}
                    {productsList && <Grid container className={Styles.productsWrapper}>
                        {productsList.map(product => <Grid item xs={12} sm style={{maxWidth: 350, marginBottom: 20}}><ProductSquare wrapperStyle={Styles.productWrapper} product={product} myProductsPage={true} currentPageParameters={{productsType, page, itemsPerPage: ITEMS_PER_PAGE}} /></Grid>)}
                    </Grid>}
                    <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE} />
                </Box>
            </Grid>
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        productsList: state.product.productsList,
        pagination: state.product.pagination
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchMyProducts: (productsType, page, itemsPerPage, hideLoader) => {
            dispatch({type: 'FETCH_MY_PRODUCTS_REQUESTED', productsType, page, itemsPerPage, hideLoader})
        },
        removeProduct: (product) => {
            dispatch({type: 'REMOVE_PRODUCT_REQUESTED', id: product.id})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
