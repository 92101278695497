import React from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import CardMedia from "@material-ui/core/CardMedia";

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/TutoLivesStyle"

import OBSTuto1 from "../../Assets/Images/OBS/obs_tuto_1.png"
import OBSTuto2 from "../../Assets/Images/OBS/obs_tuto_2.png"
import OBSTuto3 from "../../Assets/Images/OBS/obs_tuto_3.png"
import OBSTuto4 from "../../Assets/Images/OBS/obs_tuto_4.png"
import OBSTuto5 from "../../Assets/Images/OBS/obs_tuto_5.png"
import OBSTuto6 from "../../Assets/Images/OBS/obs_tuto_6.png"
import OBSTuto7 from "../../Assets/Images/OBS/obs_tuto_7.png"

function Component({user}) {
    let Styles = StylesFunc();

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Tuto Live</Text>
            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={"TUTO_LIVES"} />
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    <Box className={Styles.grayBackground}>
                        <Text className={Styles.subTitle}>
                            Introduction
                        </Text>
                        <Text className={Styles.text}>
                            Pour pouvoir streamer votre live à partir de votre webcam, vous devez utiliser un logiciel de streaming externe. Le logiciel le plus communément utilisé est OBS et ce tutorial se chargera de vous apprendre simplement à la configurer pour pouvoir streamer sur Violette Sauvage.
                        </Text>

                        <Text className={Styles.subTitle}>
                            Téléchargement et installation de l'application OBS
                        </Text>
                        <Text className={Styles.text}>
                            Pour télécharger OBS, veuillez vous rendre sur cette adresse : <Link href={"https://obsproject.com/fr/download"} target={"_blank"}>Cliquez ici</Link>
                        </Text>
                        <Text className={Styles.text}>
                            Veuillez ensuite suivre les instructions d'installation de l'application.
                        </Text>

                        <Text className={Styles.subTitle}>
                            Première configuration
                        </Text>
                        <Text className={Styles.text}>
                            Au premier lancement d'OBS, il y a quelques paramètrages à effectuer avant de pouvoir lancer votre live sur Violette Sauvage.
                        </Text>
                        <Grid container justify={"space-between"} className={Styles.imageLine}>
                            <Grid item xs={12} sm={6}>
                                <CardMedia component={"img"} src={OBSTuto1} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={Styles.verticalCenter}>
                                <Text className={Styles.text}>Au premier lancement, vous devriez voir apparaître cet écran</Text>
                            </Grid>
                        </Grid>

                        <Grid container justify={"space-between"} className={Styles.imageLine}>
                            <Grid item xs={12} sm={6}>
                                <CardMedia component={"img"} src={OBSTuto2} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={Styles.verticalCenter}>
                                <Text className={Styles.text}>Cliquez alors sur "Fichier" (dans le menu en haut), puis "Paramètres"</Text>
                            </Grid>
                        </Grid>

                        <Grid container justify={"space-between"} className={Styles.imageLine}>
                            <Grid item xs={12} sm={6}>
                                <CardMedia component={"img"} src={OBSTuto3} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={Styles.verticalCenter}>
                                <Box>
                                    <Text className={Styles.text}>Dans la nouvelle fenêtre qui s'ouvre, cliquez sur "Stream (flux)" dans le menu de gauche</Text>
                                    <Text className={Styles.text}>- Choisissez "<strong>Personnalisé</strong>" pour l'option "Service"</Text>
                                    <Text className={Styles.text}>- Mettez "<strong>rtmp://violettesauvage-live.cofondateur.fr:1935/live</strong>" pour l'option "Serveur"</Text>
                                    <Text className={Styles.text}>- Mettez "<strong>{user.shop.liveToken}</strong>" pour l'option "Clé de stream"</Text>
                                    <Text className={Styles.text}>- Validez en cliquant sur "Appliquer" puis "OK"</Text>
                                    <Text className={Styles.text}>Vous pouvez retrouver ces paramètres à tout moment dans votre dashboard.</Text>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container justify={"space-between"} className={Styles.imageLine}>
                            <Grid item xs={12} sm={6}>
                                <CardMedia component={"img"} src={OBSTuto4} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={Styles.verticalCenter}>
                                <Box>
                                    <Text className={Styles.text}>Il faut désormais configurer votre source vidéo.</Text>
                                    <Text className={Styles.text}>Pour cela, cliquez sur l'icône "+" dans la cadre "Sources" en bas de la fenêtre, puis choisissez "<strong>Périphérique de capture vidéo</strong>"</Text>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid container justify={"space-between"} className={Styles.imageLine}>
                            <Grid item xs={12} sm={6}>
                                <CardMedia component={"img"} src={OBSTuto5} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={Styles.verticalCenter}>
                                <Box>
                                    <Text className={Styles.text}>Sélectionnez "<strong>Créer une nouvelle source</strong>"</Text>
                                    <Text className={Styles.text}>Vous pouvez choisir un nom pour votre source vidéo (ici webcam)</Text>
                                    <Text className={Styles.text}>Cliquez ensuite sur OK</Text>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container justify={"space-between"} className={Styles.imageLine}>
                            <Grid item xs={12} sm={6}>
                                <CardMedia component={"img"} src={OBSTuto6} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={Styles.verticalCenter}>
                                <Box>
                                    <Text className={Styles.text}>Choisissez votre webcam dans le menu déroulant "Périphérique" (premier menu déroulant)</Text>
                                    <Text className={Styles.text}>L'image de votre webcam devrait s'afficher juste au dessus</Text>
                                    <Text className={Styles.text}>Cliquez ensuite sur OK</Text>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container justify={"space-between"} className={Styles.imageLine}>
                            <Grid item xs={12} sm={6}>
                                <CardMedia component={"img"} src={OBSTuto7} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={Styles.verticalCenter}>
                                <Box>
                                    <Text className={Styles.text}>Vous pouvez désormais lancer votre live en cliquant sur "Commencer le streaming" tout en bas à droite de l'écran.</Text>
                                    <Text className={Styles.text}>Attention cependant, vous ne pourrez lancer le stream que pendant l'un de vos créneaux réservés sur le site par le menu "Créer un live"</Text>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Grid>
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        livesList: state.live.livesList,
        pagination: state.live.pagination
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchMyLives: (tab, page, itemsPerPage, hideLoader) => {
            dispatch({type: 'FETCH_MY_LIVES_REQUESTED', tab, page, itemsPerPage, hideLoader})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
