import React from "react";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Favorite from '@material-ui/icons/Favorite';
import Person from '@material-ui/icons/Person';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import SendIcon from '@material-ui/icons/Send';

import { Image } from "react-native";
import { connect } from 'react-redux'
import logo from "../../Assets/Images/logo-violette-sauvage-final.svg"
import { InternalLink } from "../Component/InternalLink"
import DropDownMenu from "../Component/DropDownMenu"
import { withRouter } from "react-router-dom";
import HeaderStylesFunc from "../../Style/HeaderStyle"
import * as Constants from "../../Style/Constants"
import LeftMenu from "./LeftMenu"
import CardMedia from "@material-ui/core/CardMedia";
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";

function Component({history, navigation, fetchFamilies, fetchMe, familyTree, token, cart, logout, tokenExpired, resetTokenExpired, user}) {
    let HeaderStyles = HeaderStylesFunc();
    let [search, setSearch] = React.useState("");
    let [searchType, setSearchType] = React.useState(0);
    let [showSearchMenu, setShowSearchMenu] = React.useState(null);
    let [openMenu, setOpenMenu] = React.useState(null);
    let [liveLink, setLiveLink] = React.useState('a_venir');

    React.useEffect(() => {
        // if (!familyTree || familyTree.length === 0) {
            fetchFamilies();
        // }
    }, []);

    React.useEffect(() => {
        if (token) {
            fetchMe(token);
        }
    }, [token]);

    React.useEffect(() => {
        if (tokenExpired) {
            resetTokenExpired();
            history.push("/login", {redirectTo: window.location.pathname})
        }
    }, [tokenExpired]);

    history.listen((location, action) => {
        setOpenMenu(null);
    });
    // Menu items
    const familiesDom = [];
    if (familyTree != null) {
        for (const value of familyTree) {
            let deployLeft = familiesDom.length > (familyTree.length / 2);
            familiesDom.push(<DropDownMenu classes={HeaderStyles.secondRow} id={value["id"]} name={value["name"]} key={value["name"]} elements={value["children"]} deployLeft={deployLeft} opened={openMenu === value["id"]} onOpen={() => setOpenMenu(openMenu === value["id"] ? null : value["id"])} />)
        }
    }

    const cartValue = cart.reduce((acc, val) => acc + (val["quantity"] * val["product"]["realPrice"]), 0);

    let startSearch = () => {
        if (searchType === 0) {
            history.push("/search", {search: search});
        } else {
            history.push("/shops/search", {search: search});
        }
    };

    React.useEffect(() => {
        axios.get(
            getApiUrl('hasCurrentLive'),
            {headers: setDefaultHeaders()}
        ).then((response) => {
            if (response && response.data && response.data.status === 200) {
                setLiveLink('en_cours');
            } else {
                setLiveLink('a_venir');
            }
        }).catch((error) => {
        });
    }, []);

    return (
        <Grid container style={{zIndex: 999}}>
            <Grid container item xs={12} className={HeaderStyles.topRow.wrapper}>
                <Grid container item className={HeaderStyles.topRow.itemWrapper} direction={"column"} justify={"center"}>
                    <Grid item>
                        <InternalLink
                            label={"Coups de coeur"}
                            link={"WISHLIST"}
                            icon={<Favorite className={HeaderStyles.topRow.icon} />}
                            classes={HeaderStyles.topRow} />
                    </Grid>
                </Grid>
                <Grid container item className={HeaderStyles.topRow.itemWrapper} direction={"column"} justify={"center"} style={{position: "relative"}}>
                    <Grid item>
                        {!token ?
                            <InternalLink label={"Connexion"} link={"LOGIN"}  classes={HeaderStyles.topRow} icon={<Person className={HeaderStyles.topRow.icon} />} />
                            : <InternalLink label={"Mon compte"} link={"DASHBOARD"} classes={HeaderStyles.topRow} icon={<Person className={HeaderStyles.topRow.icon} />} />
                        }
                        {token && user && user.nbUnreadMessages > 0 && <span className={HeaderStyles.topRow.messagesNotification}>{user.nbUnreadMessages}</span>}
                    </Grid>
                </Grid>
                <Grid container item className={clsx(HeaderStyles.topRow.itemWrapper, HeaderStyles.topRow.grayItemWrapper)} direction={"column"} justify={"center"}>
                    <Grid item>
                        <InternalLink navigation={navigation} label={"€ " + cartValue.toFixed(2)} link={"CART"} classes={HeaderStyles.topRow} icon={<ShoppingCart className={HeaderStyles.topRow.icon} />} variant={"gray"}/>
                    </Grid>
                </Grid>
            </Grid>
                <Grid container item xs={12} className={HeaderStyles.secondRow.wrapper} style={{zIndex: 999, position: "relative"}} direction={"row"}>
                    <LeftMenu />
                    <Grid item xs={12} sm={2} className={HeaderStyles.secondRow.logoWrapper}>
                        <Link href={"/"}>
                            <CardMedia className={HeaderStyles.secondRow.logo} component={"img"} src={logo} />
                        </Link>
                    </Grid>
                    <Hidden xsDown>
                        <Grid container item sm>
                            {familiesDom}
                            <Box className={HeaderStyles.secondRow.item}>
                                <Link href={"/shops/createurs"}>
                                    <Typography variant={"overline"}>Créateurs</Typography>
                                </Link>
                            </Box>
                            <Box className={HeaderStyles.secondRow.item}>
                                <Link href={"/shops/bloggers"}>
                                    <Typography variant={"overline"}>Blogueurs</Typography>
                                </Link>
                            </Box>
                            <Box className={HeaderStyles.secondRow.item}>
                                <Link href={"/shops/responsables"}>
                                    <Typography variant={"overline"}>Eco-Responsable</Typography>
                                </Link>
                            </Box>
                            <Box className={HeaderStyles.secondRow.item}>
                                <Link href={"https://www.violettesauvage.fr/inscription-vide-dressing"} target="_blank">
                                    <Typography variant={"overline"}>Nos vide-dressings</Typography>
                                </Link>
                            </Box>
                            <Box className={HeaderStyles.secondRow.item}>
                                <Link href={"/lives/" + liveLink}>
                                    <Typography variant={"overline"}>Live</Typography>
                                </Link>
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>
            <Grid container item xs={12} className={HeaderStyles.thirdRow.wrapper} direction={"row-reverse"} justify={"flex-end"}>
                <Grid item sm xs={12} className={HeaderStyles.thirdRow.searchInputWrapper}>
                    <TextField
                        autoComplete='nope'
                        type={'search'}
                        placeholder={"Recherche"}
                        variant={"outlined"}
                        onChange={(event) => setSearch(event.target.value)}
                        onKeyDown={(event) => event.key === "Enter" && startSearch()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{fontSize: Constants.FONT_SIZE_16}}/>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={startSearch}>
                                        <SendIcon style={{fontSize: Constants.FONT_SIZE_16, color: "black"}}/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            className: HeaderStyles.thirdRow.searchInput,
                            classes: {
                                notchedOutline: HeaderStyles.thirdRow.searchInputNotchedOutline
                            }
                        }}
                    />
                </Grid>
                <Grid item sm xs={12} className={HeaderStyles.thirdRow.itemWrapper}>
                    <Link href={"#"} onClick={(event) => setShowSearchMenu(showSearchMenu === null ? event.currentTarget : null)}>
                        <Typography variant={"overline"} className={HeaderStyles.thirdRow.item}>{searchType === 0 ? "Chercher par article" : "Chercher par boutique"}  <KeyboardArrowDown style={{fontSize: Constants.FONT_SIZE_28, color: "white", verticalAlign: "middle"}}/></Typography>
                    </Link>
                    <Menu
                        id="seach-menu"
                        anchorEl={showSearchMenu}
                        keepMounted
                        open={Boolean(showSearchMenu)}
                        onClose={() => setShowSearchMenu(null)}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <MenuItem onClick={() => {setSearchType(0); setShowSearchMenu(null)}} className={HeaderStyles.thirdRow.searchMenuItem}>Chercher par article</MenuItem>
                        <MenuItem onClick={() => {setSearchType(1); setShowSearchMenu(null)}} className={HeaderStyles.thirdRow.searchMenuItem}>Chercher par boutique</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        familyTree: state.family.familyTree,
        token: state.login.token,
        user: state.user.me,
        cart: state.product.cart,
        tokenExpired: state.login.tokenExpired
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchFamilies: () => {
            dispatch({type: 'FETCH_FAMILIES_REQUESTED'})
        },
        fetchMe: (token) => {
            dispatch({type: 'FETCH_ME_REQUESTED', payload: {token}})
        },
        logout: () => {
            dispatch({type: 'LOGOUT'})
        },
        resetTokenExpired:() => {
            dispatch({type: 'RESET_TOKEN_EXPIRED'})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
