import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"


export default makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: 1440,
        margin: "auto",
        [theme.breakpoints.down('xs')]: {
            width: "90%",
        }
    },
    row: {
        ...GlobalStyle.row,
    },
    title: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        width: "max-content",
        marginBottom: Constants.GET_SIZE(15),

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    descriptionWrapper: {
        maxWidth: Constants.GET_SIZE(625),
        marginBottom: Constants.GET_SIZE(50),
    },
    description: {

        ...GlobalStyle.uppercaseText,
        textAlign: "center"

    },
    button: {
        ...GlobalStyle.button,
        marginBottom: Constants.GET_SIZE(50),
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },

    contentWrapper: {
        ...GlobalStyle.row,
        marginBottom: Constants.GET_SIZE(50),
        width: "100%"
    },
    wishlistWrapper: {
        // ...GlobalStyle.row,
        // display: "flex",
        // flexWrap: "wrap",
        // justifyContent: "space-around",
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        marginLeft: Constants.GET_SIZE(25),
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        }
    },
    productWrapper: {
        marginLeft: "auto",
        marginRight: "auto",
        height: 500,
        [theme.breakpoints.down('xs')]: {
            height: "auto",
        }
    },
    productWrapperGrid: {
        maxWidth: 330,
        [theme.breakpoints.down('xs')]: {
            maxWidth: "initial"
        }
    }

}));
