import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        width: "100%",
        maxWidth: 1440,
        margin : "auto"
    },
    subContainer: {
        alignItems: 'stretch',
        marginLeft: Constants.GET_SIZE(40),
        marginRight: Constants.GET_SIZE(40),
        [theme.breakpoints.down('xs')]: {
            margin: "auto",
            width: "90%"
        }
    },
    arianeText: {
        ...GlobalStyle.uppercaseText
    },
    title: {
        fontSize: Constants.FONT_SIZE_BIGGER,
        ...GlobalStyle.uppercaseText,
        marginTop: 50,
        marginBottom: 50,
        fontWeight: "bold",
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
            marginTop: 20,
            marginBottom: 10,
        }
    },
    criteriaWrapper: {
        flex: 5,
        flexDirection: "column",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            display: "none"
        }
    },
    criteriaWrapperDisplay: {
        display: "flex"
    },
    displayFiltersButton: {
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    row: {
        ...GlobalStyle.row,
        flexWrap: "wrap"
    },
    familyTree: {
        flex: 1.5,
        backgroundColor: Constants.GRAY_FOOTER,
        marginTop: 70,
        marginRight: 50,
        marginBottom: 20,
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    familyTreeInner: {
        padding: 50,
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%"
    },
    familyTreeFamily: {
        ...GlobalStyle.uppercaseText,
        marginLeft: 0,
        fontWeight: "bold",
        // marginBottom: Constants.GET_SIZE(10),
    },
    familyTreeCategory: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        marginLeft: Constants.GET_SIZE(10),
        marginTop: Constants.GET_SIZE(5),
        marginBottom: Constants.GET_SIZE(5),
    },
    familyTreeSubCategory: {
        marginLeft: Constants.GET_SIZE(10),
        // marginBottom: Constants.GET_SIZE(5),
    },
    familyTreeSubCategory2: {
        marginLeft: Constants.GET_SIZE(20),
    },
    familyTreeSubCategory2Wrapper: {
        marginTop: Constants.GET_SIZE(5),
        marginBottom: Constants.GET_SIZE(5),
    },
    familyTreeSubCategoryWrapper: {
        marginTop: Constants.GET_SIZE(5),
        marginBottom: Constants.GET_SIZE(5),
    },
    familyTreeCategoryWrapper: {
    },
    familyTreeText: {
        color: Constants.WHITE
    },
    selected: {
        color: Constants.PINK
    },
    checkBox: {
        backgroundColor: "transparent",
        borderWidth: 0,
        paddingTop: 0,
        marginTop: 0,
        marginLeft: 10,
        textAlign: "center",
        marginBottom: Constants.GET_SIZE(60)
    },
    checkboxLabel: {
        color: Constants.LIGHT_GRAY
    },
    productsWrapper: {
        ...GlobalStyle.row,
        width: "100%",
        flexWrap: "wrap",
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        paddingBottom: 50,
        paddingLeft: 18,
        paddingRight: 18,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 4,
            paddingRight: 4,
        }
    },
    productWrapper: {
        marginLeft: "auto",
        marginRight: "auto",
        height: 350,
        width: '95%'
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 10
        }
    },
    whiteText: {
        color: Constants.WHITE,
        fontWeight: "bold"
    },
    pinkButton: {
        backgroundColor: Constants.PINK,
        width: Constants.GET_SIZE(235),
        paddingTop: Constants.GET_SIZE(20),
        paddingBottom: Constants.GET_SIZE(20),
    },
    greyButton: {
        backgroundColor: Constants.BACKGROUND_GRAY,
        width: Constants.GET_SIZE(235),
        paddingTop: Constants.GET_SIZE(20),
        paddingBottom: Constants.GET_SIZE(20),
    },
    buttonWrapper: {
        marginLeft: Constants.GET_SIZE(30),
        marginBottom: Constants.GET_SIZE(30),
        justifyContent: "flex-end"
    },
    noProductsText: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        textAlign: "center",
        width: "100%",
        marginTop: Constants.GET_SIZE(30),
        marginBottom: Constants.GET_SIZE(30),
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    nbResultsText: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        color: Constants.PINK,
        width: "100%",
        marginTop: Constants.GET_SIZE(10),
        marginLeft: Constants.GET_SIZE(14),
        marginBottom: Constants.GET_SIZE(5),
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },

    pickerWrapper: {
        ...GlobalStyle.rowCentered,
        width: Constants.GET_SIZE(150),
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderRadius: Constants.GET_SIZE(10),
        height: Constants.GET_SIZE(58),
        backgroundColor: Constants.WHITE
    },
}));
