import React from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"

import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import MomentUtils from '@date-io/moment'; // choose your lib
import Moment from 'moment';
import 'moment/locale/fr';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import StylesFunc from "../Style/GeneralStyle"
import ReactGA from 'react-ga';

// FRONT OFFICE
import Login from './Front/Login'
import Homepage from './Front/Homepage'
import Contact from './Front/Contact'
import Register from './Front/Register'
import ForgotPassword from './Front/ForgotPassword'
import VideDressing from './Front/VideDressing'
import CategoryList from './Front/CategoryList'
import Product from './Front/Product'
import ShopList from './Front/ShopList'
import Shop from './Front/Shop'
import ShoppingCart from './Front/ShoppingCart'
import BuyingTunnel from './Front/BuyingTunnel'
import LiveShow from './Front/LiveShow'
import MeetingVS from './Front/Static/MeetingVS'
import CGV from './Front/Static/CGV'
import ConfidentialityDisclaimer from './Front/Static/ConfidentialityDisclaimer'
import DeliveryConditions from './Front/Static/DeliveryConditions'
import ClickAndCollectDelivery from './Front/Static/ClickAndCollectDelivery'
import Values from './Front/Static/Values'
import OpenShop from './Front/Static/OpenShop'
import SellerManual from './Front/Static/SellerManual'
import FAQ from './Front/Static/FAQ'
import LegalMentions from './Front/Static/LegalMentions'
import IOSPWAModal from './Front/Component/IOSPWAModal'

// MIDDLE OFFICE
import Dashboard from './Middle/Dashboard'
import Wishlist from './Middle/Wishlist'
import Alerts from './Middle/Alerts'
import Purchases from './Middle/Purchases'
import Messaging from './Middle/Messaging'
import MessageThread from './Middle/MessageThread'
import ProductReturn from './Middle/ProductReturn'
import MyShop from './Middle/MyShop'
import MyWallet from './Middle/MyWallet'
import MyInfo from './Middle/MyInfo'
import MyProducts from './Middle/MyProducts'
import MySales from './Middle/MySales'
import CreateProduct from './Middle/CreateProduct'
import MyLives from './Middle/MyLives'
import CreateLive from './Middle/CreateLive'

// BACK OFFICE
import UserList from './Back/UserList'
import CriteriaList from './Back/CriteriaList'
import ShopEdit from './Back/ShopEdit'
import ProductEdit from './Back/ProductEdit'
import UserEdit from './Back/UserEdit'
import MoneyDashboard from './Back/MoneyDashboard'
import AdminDashboard from './Back/AdminDashboard'
import PushManagement from './Back/PushManagement'
import LitigeEdit from './Back/LitigeEdit'
import LivePromotionList from './Back/LivePromotionList'
import AssociationList from "./Back/AssociationList";
import AssociationEdit from "./Back/AssociationEdit";

import {
    Router,
    Switch,
    Route
} from "react-router-dom";
import Footer from "./Partial/Footer";
import connect from "react-redux/es/connect/connect";
import Header from "./Partial/Header";
import Loader from "./Component/Loader";
import History from '../History';
import * as Constants from "../Style/Constants";
import firebase from "firebase";
import LiveList from "./Front/LiveList";
import TutoLives from "./Middle/TutoLives";
import Dialog from "@material-ui/core/Dialog";
import Text from "./Component/FontText";
import ConfirmationModalStylesFunc from "../Style/ConfirmationModalStyle";
import CustomCheckbox from "./Component/CustomCheckbox";
import {STRIPE_PUBLIC_KEY} from "../Config/API";
import AssociationCreate from "./Back/AssociationCreate";
import Associations from "./Front/Associations";

const Stack = createStackNavigator();


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);


Moment.locale("fr");

function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const firebaseConfig = {
    apiKey: "AIzaSyBzSb2KzVixdSHsEi-fFOwRo0kk4eGSl3M",
    authDomain: "violettesauvage.firebaseapp.com",
    projectId: "violettesauvage",
    storageBucket: "violettesauvage.appspot.com",
    messagingSenderId: "526247064655",
    appId: "1:526247064655:web:e8fda6875e0c74cfaca63a",
    measurementId: "G-SHZ59SJBX1"
};

function App({isLoadingGeneral, RGPDValidated, setRPGDValidated, iOSPWAIgnore, setIOSPWAIgnore, token, subscribeToFirebase, cookieAnalytics}) {
    let Styles = StylesFunc();
    let ConfirmationModalStyles = ConfirmationModalStylesFunc();
    let [iosModal, setIosModal] = React.useState(!iOSPWAIgnore);
    let [cookieModal, setCookieModal] = React.useState(false);
    let [analyticsCookie, setAnalyticsCookie] = React.useState(true);

    if (cookieAnalytics) {
        ReactGA.initialize('G-E9C7GNYYWC');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    React.useEffect(() => {
        if (token) {
            // firebase.initializeApp(firebaseConfig);

            console.log("Logged in. Do we have service worker ?");
            if ("serviceWorker" in navigator) {
                console.log("Service worker found.");
                if (firebase.apps.length === 0) {
                    console.log("Initializing firebase...")
                    firebase.initializeApp(firebaseConfig);
                } else {
                    console.log("Firebase already initialized !")
                }
                if (firebase.messaging.isSupported()) {
                    console.debug("Messaging supported on this browser. Getting token....");
                    let messaging = firebase.messaging();
                    messaging.getToken().then((currentToken) => {
                        console.debug("Firebase token obtained. Subscribing !");
                        subscribeToFirebase(currentToken);
                    }).catch(err => console.debug('User refused notifications :\'('));
                    console.debug("Firebase initialization done");
                } else {
                    console.debug("Messaging not supported on this browser. Ignoring.");
                }
            } else {
                console.debug("No service worker found. Aborting.");
            }

        }
    },[token]);

    return (
        <Elements stripe={stripePromise}>
            <Loader visible={isLoadingGeneral > 0} general={true}  />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router history={History}>
                    <Header />
                    <Box style={{minHeight: "100vh"}}>
                        <Switch>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/register">
                                <Register />
                            </Route>
                            <Route path="/forgot-password">
                                <ForgotPassword />
                            </Route>
                            <Route path="/contact">
                                <Contact />
                            </Route>
                            <Route path="/vide-dressing/:id">
                                <VideDressing />
                            </Route>
                            <Route path="/category/:familyId/:familySlug/:categoryId/:categorySlug/:subCategoryId/:subCategorySlug/:subCategory2Id/:subCategory2Slug/:page?">
                                <CategoryList />
                            </Route>
                            <Route path="/category/:familyId/:familySlug/:categoryId/:categorySlug/:subCategoryId/:subCategorySlug/:page?">
                                <CategoryList />
                            </Route>
                            <Route path="/category/:familyId/:familySlug/:categoryId/:categorySlug/:page?">
                                <CategoryList />
                            </Route>
                            <Route path="/category/:familyId/:familySlug/:page?">
                                <CategoryList />
                            </Route>
                            {/*<Route path="/category/:familyId/:familySlug/:categoryId?/:categorySlug?/:subCategoryId?/:subCategorySlug?/:subCategory2Id?/:subCategory2Slug?">*/}
                                {/*<CategoryList />*/}
                            {/*</Route>*/}
                            <Route path="/search/:page?">
                                <CategoryList />
                            </Route>
                            <Route path="/products">
                                <CategoryList />
                            </Route>
                            <Route path="/product/:id">
                                <Product />
                            </Route>
                            <Route path="/shops/:type/:page?">
                                <ShopList />
                            </Route>
                            <Route path="/lives/:tab?">
                                <LiveList />
                            </Route>
                            <Route path="/shop/:id">
                                <Shop />
                            </Route>
                            <Route path="/cart">
                                <ShoppingCart />
                            </Route>
                            <Route path="/order">
                                <BuyingTunnel />
                            </Route>
                            <Route path="/meetingVS">
                                <MeetingVS title={"A propos"}/>
                            </Route>
                            <Route path="/cgv">
                                <CGV title="CGV"/>
                            </Route>
                            <Route path="/politique_de_confidentialite">
                                <ConfidentialityDisclaimer title="Politique de confidentialité" />
                            </Route>
                            <Route path="/conditions_de_livraison">
                                <DeliveryConditions title={"Tarifs et Livraisons"} />
                            </Route>
                            <Route path="/livraison_click_and_collect">
                                <ClickAndCollectDelivery title={"Livraison click and collect"}/>
                            </Route>
                            <Route path="/nos_valeurs">
                                <Values title={"Nos valeurs"} />
                            </Route>
                            <Route path="/ouvrir_une_boutique">
                                <OpenShop title={"Ouvrir une boutique"} />
                            </Route>
                            <Route path="/manuel_du_vendeur">
                                <SellerManual title={"Manuel du vendeur"}/>
                            </Route>
                            <Route path="/faq">
                                <FAQ title={"FAQ"}/>
                            </Route>
                            <Route path="/mentions_legales">
                                <LegalMentions title={"Mentions légales"}/>
                            </Route>
                            <Route path="/voir_live/:id">
                                <LiveShow />
                            </Route>
                            <Route path="/associations">
                                <Associations />
                            </Route>


                            <Route path="/dashboard">
                                <Dashboard />
                            </Route>
                            <Route path="/wishlist">
                                <Wishlist />
                            </Route>
                            <Route path="/mes_alertes">
                                <Alerts />
                            </Route>
                            <Route path="/mes_commandes">
                                <Purchases />
                            </Route>
                            <Route path="/messages">
                                <Messaging />
                            </Route>
                            <Route path="/message/:id">
                                <MessageThread />
                            </Route>
                            <Route path="/return_product/:id">
                                <ProductReturn />
                            </Route>
                            <Route key="CreateMyShop" path="/creer_ma_boutique/:page">
                                <MyShop screenKey={"CreateMyShop"} />
                            </Route>
                            <Route key="CreateMyShop" path="/creer_ma_boutique">
                                <MyShop screenKey={"CreateMyShop"} />
                            </Route>
                            <Route key="MyShop" path="/ma_boutique">
                                <MyShop screenKey={"MyShop"} />
                            </Route>
                            <Route key="MyWallet" path="/mon_porte_monnaie">
                                <MyWallet screenKey={"MyWallet"} />
                            </Route>
                            <Route key="MyBankInfo" path="/mes_coordonnées_bancaires">
                                <MyShop screenKey={"MyBankInfo"} />
                            </Route>
                            <Route key="MyInfo" path="/mes_informations">
                                <MyInfo screenKey={"MyInfo"} />
                            </Route>
                            <Route key="MyProducts" path="/mes_annonces">
                                <MyProducts screenKey={"MyProducts"} />
                            </Route>
                            <Route key="MySales" path="/mes_transactions">
                                <MySales screenKey={"MySales"} />
                            </Route>
                            <Route key="CreateProduct" path="/ajouter_produit">
                                <CreateProduct screenKey={"CreateProduct"} />
                            </Route>
                            <Route key="EditProduct" path="/editer_produit/:id">
                                <CreateProduct screenKey={"EditProduct"} />
                            </Route>
                            <Route key="ProductPreview" path="/preview">
                                <Product screenKey={"ProductPreview"}/>
                            </Route>
                            <Route key="CreateLive" path="/inscription_live">
                                <CreateLive screenKey={"CreateLive"}/>
                            </Route>
                            <Route key="EditLive" path="/editer_live/:id">
                                <CreateLive screenKey={"EditLive"}/>
                            </Route>
                            <Route key="MyLives" path="/mes_lives">
                                <MyLives screenKey={"MyLives"}/>
                            </Route>
                            <Route key="TutoLives" path="/tuto_live">
                                <TutoLives screenKey={"TutoLives"}/>
                            </Route>


                            <Route key="AdminList" path="/admin/liste">
                                <UserList screenKey={"AdminList"}/>
                            </Route>
                            <Route key="CriteriaList" path="/admin/criteria">
                                <CriteriaList screenKey={"CriteriaList"}/>
                            </Route>
                            <Route key="EditProduct" path="/admin/editer_produit/:id">
                                <ProductEdit screenKey={"EditProduct"} admin={true}/>
                            </Route>
                            <Route path="/admin/editer_boutique/:id">
                                <ShopEdit />
                            </Route>
                            <Route path="/admin/editer_utilisateur/:id">
                                <UserEdit />
                            </Route>
                            <Route path="/admin/suivi_transactions">
                                <MoneyDashboard />
                            </Route>
                            <Route path="/admin/administration_manuelle">
                                <AdminDashboard />
                            </Route>
                            <Route path="/admin/gestion_notifications">
                                <PushManagement />
                            </Route>
                            <Route path="/admin/litige/:id">
                                <LitigeEdit />
                            </Route>
                            <Route path="/admin/live_promo">
                                <LivePromotionList />
                            </Route>
                            <Route path="/admin/associations">
                                <AssociationList />
                            </Route>
                            <Route path="/admin/editer_association/:id">
                                <AssociationEdit />
                            </Route>
                            <Route path="/admin/creer_association">
                                <AssociationCreate />
                            </Route>

                            <Route path="/">
                                <Homepage />
                            </Route>
                        </Switch>
                    </Box>
                    {!RGPDValidated && <Grid container className={Styles.rpgdWrapper} justify={"space-between"}>
                        <Typography className={Styles.rgpdText}>
                            En poursuivant votre navigation sur ce site, vous acceptez l'utilisation des cookies pour vous proposer la meilleure expérience utilisateur. <Link onClick={() => setCookieModal(true)} className={Styles.rpgdLink}>Gérer les cookies</Link>
                        </Typography>
                        <Grid item>
                            <Button variant={"contained"} color={"secondary"} disableElevation onClick={() => setRPGDValidated(analyticsCookie)} className={Styles.rgpdButton}>
                                Accepter les cookies
                            </Button>
                            <Button variant={"contained"} color={"secondary"} disableElevation onClick={() => setRPGDValidated(false)} className={Styles.rgpdButton2}>
                                Refuser les cookies
                            </Button>
                        </Grid>
                        <Dialog open={cookieModal} fullWidth>
                            <Box className={ConfirmationModalStyles.modalContent}>
                                <Text className={ConfirmationModalStyles.modalTitle}>Paramètre des cookies</Text>

                                <Text>Les cookies sont de très petits fichiers texte qui sont stockés sur votre ordinateur lorsque vous visitez un site Web. Nous utilisons des cookies à diverses fins et pour améliorer votre expérience en ligne sur notre site Web (par exemple, pour mémoriser les informations de connexion de votre compte).</Text>
                                <Text style={{marginBottom: 20}}>Vous pouvez modifier vos préférences et refuser l'enregistrement de certains types de cookies sur votre ordinateur lors de la navigation sur notre site. Vous pouvez également supprimer les cookies déjà stockés sur votre ordinateur, mais gardez à l'esprit que leur suppression peut vous empêcher d'utiliser des éléments de notre site Web.</Text>

                                <CustomCheckbox
                                    containerClass={ConfirmationModalStyles.checkBox}
                                    checkedColor={Constants.PINK}
                                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                                    disabled={true}
                                    title={<Text className={ConfirmationModalStyles.checkboxLabel}>Cookies essentiels (obligatoire)</Text>}
                                    checked={true} onPress={() => null}
                                    size={Constants.CHECKBOX_SIZE_BIG} />

                                <CustomCheckbox
                                    containerClass={ConfirmationModalStyles.checkBox}
                                    checkedColor={Constants.PINK}
                                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                                    title={<Text className={ConfirmationModalStyles.checkboxLabel}>Cookies de suivi et de performance</Text>}
                                    checked={analyticsCookie} onPress={() => setAnalyticsCookie(!analyticsCookie)}
                                    size={Constants.CHECKBOX_SIZE_BIG} />

                                <Grid container justify={"space-between"} style={{marginTop: 20}}>
                                    <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={() => setRPGDValidated(analyticsCookie)}>
                                        Confirmer
                                    </Button>
                                    <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={() => setCookieModal(false)}>
                                        Annuler
                                    </Button>
                                </Grid>
                            </Box>
                        </Dialog>
                    </Grid>}
                    {iOS() && <IOSPWAModal onConfirm={ignore => {setIOSPWAIgnore(ignore); setIosModal(false)}} visible={iosModal}/>}
                    <Footer />
                </Router>
            </MuiPickersUtilsProvider>
        </Elements>
    );
}

const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoadingGeneral: state.loading.isLoadingGeneral,
        RGPDValidated: state.login.RGPDValidated,
        cookieAnalytics: state.login.cookieAnalytics,
        iOSPWAIgnore: state.login.iOSPWAIgnore,
        token: state.login.token
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setRPGDValidated: (analytics) => dispatch({type: "SET_RGPD_VALIDATED", valid: true, analytics: analytics}),
        setIOSPWAIgnore: (ignore) => dispatch({type: "SET_IOS_PWA_IGNORE", ignore: ignore}),
        subscribeToFirebase: (token) => {
            dispatch({type: 'SUBSCRIBE_TO_FIREBASE_REQUESTED', token, hideLoader: true})
        },
    }
};

const VisibleApp = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

export default VisibleApp
