import React from "react";
import clsx from 'clsx';

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Text from "../../Component/FontText"

import StylesFunc from '../../../Style/MiddleOffice/LeftMenuStyle'
import menuLinks from '../../../Config/ConnectedMenuLinks'
import { InternalLink } from "../../Component/InternalLink";
import { TouchableOpacity } from "react-native-web";
import ListItem from "@material-ui/core/ListItem/ListItem";
import HeaderStylesFunc from "../../../Style/HeaderStyle";

function Component({navigation, history, logout, token, me, content, currentPage, wrapperStyle, wrapperClass}) {
    let Styles = StylesFunc();
    let HeaderStyles = HeaderStylesFunc();
    let defaultOpenedCategories = [];

    for (const menuLink of menuLinks) {
        for (const link of menuLink.links) {
            if (link.link === currentPage) {
                defaultOpenedCategories.push(menuLink.label);
                break;
            }
        }
        if (defaultOpenedCategories.length > 0) {
            break;
        }
    }
    let [openedCategories, setOpenedCategories] = React.useState(defaultOpenedCategories);

    let onClickCategory = (cat) => {
        const index = openedCategories.indexOf(cat.label);
        if (index !== -1) {
            setOpenedCategories([...openedCategories.slice(0, index), ...openedCategories.slice(index + 1)])
        } else {
            setOpenedCategories([...openedCategories, cat.label])
        }
    };

    let _getLink = (link) => {
        if (link.type === "link") {
            let secondary = null;
            if (link.label === 'Messagerie') {
                secondary = (token && me && me.nbUnreadMessages > 0 ? <span className={HeaderStyles.secondRow.messagesNotification} style={{display: "block"}}>{me.nbUnreadMessages}</span> : null);
            }
            return <InternalLink
                key={"key-" + link.label}
                wrapperClass={Styles.grayCardLinkWrapper}
                labelClass={clsx(Styles.link, {[Styles.selected]: currentPage === link.link})}
                linkStyle={{textDecoration: "none"}}
                label={link.label}
                link={link.link}
                secondary={secondary}
            />

        } else if (link.type === "csv") {

            return <Link href={link.link} target={"_blank"} className={Styles.grayCardLinkWrapper} >
                    <Text className={clsx(Styles.link, {[Styles.selected]: currentPage === link.link})}>{link.label}</Text>
                </Link>

        } else {
            return <TouchableOpacity
                    key={"key-" + link.label}
                    onPress={link.action === "logout" ? logout : null}
                    className={Styles.grayCardLinkWrapper}>
                    <Text className={Styles.link}>{link.label}</Text>
                </TouchableOpacity>

        }
    }

    return (
        <Box className={clsx(Styles.container, wrapperClass)} style={wrapperStyle ? wrapperStyle : {}}>
            {menuLinks.map((cat) => (!cat.admin || (me && me.roles.indexOf('ROLE_ADMIN') !== -1)) &&
                <Box className={clsx(Styles.categoryWrapper, {[Styles.categoryAdminWrapper]: cat.admin})}>
                    <TouchableOpacity onPress={() => onClickCategory(cat)}>
                        <Text className={Styles.title}>{cat.label}</Text>
                    </TouchableOpacity>
                    {openedCategories.indexOf(cat.label) !== -1 && <Box className={Styles.linksWrapper}>
                        {cat.links.map(_getLink)}
                    </Box>}
                </Box>
            )}
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        me: state.user.me,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch({type: 'LOGOUT'})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
