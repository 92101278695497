import React from "react";

import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActionArea from "@material-ui/core/CardActionArea"

import Text from "../../Component/FontText";
import { withRouter } from "react-router-dom";
import clsx from 'clsx';

import ShopSquareStylesFunc from "../../../Style/ShopSquareStyle"
import * as Constants from "../../../Style/Constants"
import {getImageUrl} from "../../../Utils/ImageUtils";

import UserIcon from "../../../Assets/Images/user_icon.svg";

function ShopSquare({history, shop, overlayDescription, wrapperClass}) {
    let ShopSquareStyles = ShopSquareStylesFunc();

    return (
        <Card
            elevation={0}
            className={wrapperClass}>
            <CardActionArea
                className={ShopSquareStyles.cardActionArea}
                href={"/shop/" + shop.id}>
                <CardMedia image={shop.picture ? getImageUrl(shop.picture.contentUrl, "medium") : UserIcon} className={ShopSquareStyles.image} />
                <Box className={clsx(ShopSquareStyles.overlay)}>
                    {overlayDescription === true ? <Text>{shop.description}</Text> : <Box />}
                </Box>
                <Text className={clsx(ShopSquareStyles.shopName)}>{shop.name}</Text>
            </CardActionArea>
        </Card>
    );
}

export default withRouter(ShopSquare);