import React from "react";
import clsx from "clsx";
import Moment from "moment";
import ReactPlayer from 'react-player'
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import Text from "../Component/FontText";
import { connect } from 'react-redux'
import {useParams, withRouter} from "react-router-dom";

import StylesFunc from '../../Style/LiveShowStyle'
import * as Constants from '../../Style/Constants'
import axios from "axios";
import {getApiUrl, getLiveChatUrl, getLiveStreamUrl, setDefaultHeaders} from "../../Utils/QueryUtils";
import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import {getImageUrl} from "../../Utils/ImageUtils";
import UserIcon from "../../Assets/Images/user_icon.svg";
import Rating from "@material-ui/lab/Rating";
import {Image} from "react-native";
import LogoFB from "../../Assets/Images/SocialMedia/f_logo_RGB-Blue_58.png";
import LogoTwitter from "../../Assets/Images/SocialMedia/Twitter_Social_Icon_Circle_Color.png";
import LogoInsta from "../../Assets/Images/SocialMedia/glyph-logo_May2016.png";
import LogoPinterest from "../../Assets/Images/SocialMedia/badgeRGB.svg";
import ShopSubscribeButton from "./Component/ShopSubscribeButton";
import Map from "../Component/Map";
import ShopStylesFunc from "../../Style/LiveShopStyle";
import ProductSquare from "./Component/ProductSquare";
import Paginator from "./Component/Paginator";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";
import LiveMessage from "./Component/LiveMessage";
import TextInput from "../Component/FontInput";
import {FontAwesome} from "../../FontAwesome";
import {useNodeChat} from "../../API/Hook/LiveChat";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {
    Navigation, Autoplay
} from 'swiper';
import "swiper/swiper.min.css";
import {useLive, useLiveProducts} from "../../API/Hook/Live";
import ConfirmationModal from "./Component/ConfirmationModal";
import LiveBuyModal from "./Component/LiveBuyModal";
import LiveExplanationModal from "./Component/LiveExplanationModal";
import HelpIcon from '@material-ui/icons/HelpOutline';
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import Link from "@material-ui/core/Link";
import Countdown from "react-countdown";

const SHOP_PRODUCTS_ITEMS_PER_PAGE = 12;
const SHOP_PRODUCTS_ITEMS_PER_PAGE_MOBILE = 5000;

SwiperCore.use([Navigation, Autoplay]);

function Component({history, navigation, token, me, startLoading, stopLoading, width, getParams}) {
    const params = useParams();
    let Styles = StylesFunc();
    let ShopStyles = ShopStylesFunc();
    let [shop, setShop] = React.useState(null);
    let [liveProducts, isLoadingLiveProducts, setNotBuyable, reserveProduct, releaseProduct, refreshReservations, decrementProductQuantity, updateProductMinPrice] = useLiveProducts(params.id);
    let [live, updateLiveState, updateLiveAdminState, updateLiveVideoState, subscribeToLive, updateLiveNbConnected, isLoadingLive] = useLive(params.id);
    let [shopProducts, setShopProducts] = React.useState([]);
    let [shopProductsPage, setShopProductsPage] = React.useState(1);
    let [shopProductsPagination, setShopProductsPagination] = React.useState({first: 1, last: 1});
    let [tab, setTab] = React.useState(0);
    let [currentMessagePopup, setCurrentMessagePopup] = React.useState(null);
    let [anchorAdminPopup, setAnchorAdminPopup] = React.useState(null);
    let [positionAdminPopup, setPositionAdminPopup] = React.useState({mouseX: null, mouseY: null});
    let [redirectionModalVisible, setRedirectionModalVisible] = React.useState(false);
    let [redirectionModalMessage, setRedirectionModalMessage] = React.useState("");
    const [explanationPopupAlreadyShown, setExplanationPopupAlreadyShown] = React.useState(false);
    const [explanationPopupVisible, setExplanationPopupVisible] = React.useState(false);
    const [autoExplanationPopup, setAutoExplanationPopup] = React.useState(false);

    const playerRef = React.useRef();

    let [buyingTunnelModal, setBuyingTunnelModal] = React.useState(false);
    let [buyingTunnelModalProduct, setBuyingTunnelModalProduct] = React.useState(null);
    let [buyingTunnelModalProposition, setBuyingTunnelModalProposition] = React.useState(null);

    const [filterProducts, setFilterProducts] = React.useState(-1);

    const [scroll, setScroll] = React.useState(false);
    React.useEffect(() => {
        getParams();
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 233);
        })
    }, [])

    React.useEffect(() => {
        if (!token) {
            history.replace("/login", {redirectTo: history.location.pathname})
        }
    }, [token]);

    let messagesEndRef = React.useRef(null);
    let messagesListRef = React.useRef(null);
    let chatAutoScrollRef = React.useRef(true);

    const onMessageReceived = (message) => {
        if (chatAutoScrollRef.current) {
            messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight - messagesListRef.current.offsetHeight;
        }
    }

    const onMessageSent = () => {
    }

    const onFirstLoad = () => {
        if (messagesListRef.current) {
            messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight - messagesListRef.current.offsetHeight;
        }
    }

    const onBanned = () => {
        setRedirectionModalMessage('Vous avez été banni de ce live par un administrateur.')
        setRedirectionModalVisible(true);
    }

    const onLiveEnded = () => {
        setRedirectionModalMessage('Le live est désormais terminé.')
        setRedirectionModalVisible(true);
    }

    const onMyPropositionAccepted = (result) => {
        console.log('onMyPropositionAccepted', result, liveProducts);
        let index = liveProducts.findIndex(elem => elem.product.id === result.product.id);
        if (index !== -1) {
            setBuyingTunnelModalProposition(result);
            setBuyingTunnelModalProduct(liveProducts[index]);
            setBuyingTunnelModal(true);
        }
    }

    const onConnectionRefused = error => {
        switch (error.message) {
            case 'error-banned':
                setRedirectionModalMessage('Vous avez été banni de ce live par un administrateur.')
                setRedirectionModalVisible(true);
                break;
            case 'error-late':
                setRedirectionModalMessage('Vous arrivez trop tard pour participer à ce live.')
                setRedirectionModalVisible(true);
                break;
            case 'error-ended':
                setRedirectionModalMessage('Le live est désormais terminé.')
                setRedirectionModalVisible(true);
            default:
                setRedirectionModalMessage('Une erreur inconnue a eu lieu.')
                setRedirectionModalVisible(true);
                break;
        }
    }

    const onProductBought = (productId) => {
        decrementProductQuantity(productId);
    }

    let [getLiveAdminButtonBar, chatMessages, currentMessage, setCurrentMessage, sendMessage, sendProposition, deleteMessage, banMessage, acceptProposition, refuseProposition, isChatLoading, isLoadingSendMessage, sendReserveProduct, sendReleaseProduct, buyProduct, propositionInProgress, setPropositionInProgress] = useNodeChat(getLiveChatUrl(), token, me, onBanned, params.id, onMessageSent, onMessageReceived, onFirstLoad, onMyPropositionAccepted, onConnectionRefused, live ? live.streamStatus : 0, updateLiveState, updateLiveAdminState, updateLiveVideoState, reserveProduct, releaseProduct, onProductBought, updateLiveNbConnected, updateProductMinPrice);

    const isLive = live && live.streaming;
    const isAdmin = me && me.shop && live && live.owner && me.shop.id === live.owner.id;


    let productsPerPage = SHOP_PRODUCTS_ITEMS_PER_PAGE;
    if (isWidthDown('xs', width)) {
        productsPerPage = SHOP_PRODUCTS_ITEMS_PER_PAGE_MOBILE;
    }

    React.useEffect(() => {
        let notBuyables = [];
        let reservations = {};
        for (const m of chatMessages) {
            if (!!m.product) {
                if ((!!m.price && m.bidding_status === 1) || m.bidding_status === 0) {
                    notBuyables.push(m.product.id);
                }
                if (!m.price && m.bidding_status === 0) {
                    if (!reservations[m.product.id]) {
                        reservations[m.product.id] = 0;
                    }
                    reservations[m.product.id]++;
                }
            }
        }
        setNotBuyable(notBuyables);
        refreshReservations(reservations);
    }, [chatMessages])

    const displayAdminPopupDesktop = (event, message) => {
        if (event.preventDefault) event.preventDefault();
        if (message.user.id !== me.id) {
            setPositionAdminPopup({mouseX: event.clientX - 2, mouseY: event.clientY - 4});
            setCurrentMessagePopup(message);
        }
    }

    const closeAdminPopup = () => {
        setPositionAdminPopup({mouseX: null, mouseY: null});
        setCurrentMessagePopup(null);
        setAnchorAdminPopup(null);
    }

    const handleDeleteMessage = () => {
        deleteMessage(currentMessagePopup.id);
        setCurrentMessagePopup(null);
        closeAdminPopup();
    }

    const handleBanMessage = () => {
        banMessage(currentMessagePopup.user.id);
        setCurrentMessagePopup(null);
        closeAdminPopup();
    }

    React.useEffect(() => {
        if (isLoadingLive) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoadingLive])

    React.useEffect(() => {
        if (isLoadingLiveProducts) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoadingLiveProducts])

    const instantBuy = (liveProduct) => {
        setBuyingTunnelModalProduct(liveProduct);
        setBuyingTunnelModal(true);
        setBuyingTunnelModalProposition(null);
    }

    React.useEffect(() => {
        if (messagesListRef.current && tab === 0) {
            messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight - messagesListRef.current.offsetHeight;
        }
    }, [tab]);

    React.useEffect(() => {
        if (messagesListRef.current) {
            messagesListRef.current.addEventListener('scroll', (event) => {
                if (messagesListRef.current.scrollTop === (messagesListRef.current.scrollHeight - messagesListRef.current.offsetHeight)) {
                    chatAutoScrollRef.current = true;
                } else {
                    chatAutoScrollRef.current = false;
                }
            });
            messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight - messagesListRef.current.offsetHeight;
        }
    }, [messagesListRef.current]);

    React.useEffect(() => {
        if (me && live) {
            if (live.bannedUsers.includes(me.id)) {
                setRedirectionModalMessage('Vous avez été banni de ce live par un administrateur.')
                setRedirectionModalVisible(true);
            } else if (me.hideLivePopup === false && !explanationPopupAlreadyShown && live.isFirstLoad) {
                setExplanationPopupAlreadyShown(true);
                setAutoExplanationPopup(true);
                setExplanationPopupVisible(true);
            }
        }
    }, [live, me]);

    React.useEffect(() => {
        if (live && live.isFirstLoad) {
            // startLoading();
            axios.get(
                getApiUrl('shops/' + live.owner.id),
                {headers: setDefaultHeaders()}
            ).then((response) => {
                setShop(response.data);
                // stopLoading();
            }).catch((error) => {
                // stopLoading();
            });
            if (!isLive) {
                // startLoading();
                axios.get(
                    getApiUrl('products', {page: shopProductsPage, itemsPerPage: productsPerPage, shop: live.owner.id}),
                    {headers: setDefaultHeaders()}
                ).then((response) => {
                    setShopProducts(response.data['hydra:member']);
                    const first = new URLSearchParams(response.data['hydra:view']["hydra:first"]).get("page");
                    const last  = new URLSearchParams(response.data['hydra:view']["hydra:last"]).get("page");
                    setShopProductsPagination({first: first, last: last});
                    // stopLoading();
                }).catch((error) => {
                    // stopLoading();
                });
            }
        }
    }, [live, shopProductsPage]);

    const setSubscribedLive = (product, subscribed) => {
        const index = shopProducts.findIndex((element) => element.id === product.id);
        if (index !== -1) {
            setShopProducts([
                ...shopProducts.slice(0, index),
                Object.assign({}, shopProducts[index], {
                    isLiveRequest: subscribed
                }),
                ...shopProducts.slice(index + 1)
            ]);
        }
    }

    let _getTabs = () => {
        return <Grid container className={clsx(Styles.tabsWrapper, Styles.mobile)}>
            <Grid item xs={6}>
                <Button variant={tab === 0 ? "contained" : "outlined"} color={"primary"} disableElevation onClick={() => setTab(0)} size={"small"} style={{width: '100%'}}>
                    Chat
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant={tab === 1 ? "contained" : "outlined"} color={"primary"} disableElevation onClick={() => setTab(1)} size={"small"} style={{width: '100%'}}>
                    Produits
                </Button>
            </Grid>
        </Grid>
    }

    const onClickSubscribe = () => {
        subscribeToLive();
    }

    let _getPlayer = () => {
        let displayMessage = "Vous ne pouvez pas voir ce live.";
        let canSubscribe = false;
        if (isLive) {
            if (isAdmin) {
                if (live.streamStatus === 3) {
                    if (!redirectionModalVisible) {
                        setRedirectionModalMessage('Le live est désormais terminé.')
                        setRedirectionModalVisible(true);
                    }
                    displayMessage = "Le live est désormais terminé.";
                } else {
                    displayMessage = "Veuillez connecter votre logiciel de stream à la plateforme pour pouvoir démarrer votre live.";
                }
            } else {
                if (live.streamStatus === 0) {
                    displayMessage = "Le live va bientôt démarrer.";
                } else if (live.streamStatus === 2) {
                    displayMessage = "Le live a été mis en pause et va bientôt redémarrer.";
                } else if (live.streamStatus === 3) {
                    if (!redirectionModalVisible) {
                        setRedirectionModalMessage('Le live est désormais terminé.')
                        setRedirectionModalVisible(true);
                    }
                    displayMessage = "Le live est désormais terminé.";
                } else if (live.videoStatus === 0) {
                    displayMessage = "Une erreur technique a eu lieu. Veuillez patienter...";
                }
            }
        } else {
            if (live.streamStatus === 3 && !redirectionModalVisible) {
                setRedirectionModalMessage('Le live est désormais terminé.')
                setRedirectionModalVisible(true);
            } else {
                displayMessage = 'Le live aura lieu ' + Moment(live.startAt).calendar(null, {sameElse: '[le] dddd Do MMMM [à] HH:mm'}).toLowerCase();
                if (!isAdmin) {
                    canSubscribe = true;
                }
            }
        }
        return [<Box className={clsx(Styles.playerWrapperScrollPlaceholder)} style={{display: scroll ? "block": "none"}}></Box>,
            <Grid className={clsx(Styles.playerWrapper, scroll ? Styles.playerWrapperScroll : {})} item container xs={12} md={8} justify={"center"} alignItems={"center"}>
            {isLive && live.videoStatus === 1 && !redirectionModalVisible && (live.streamStatus === 1 || isAdmin) ?
                <ReactPlayer url={getLiveStreamUrl(live.owner.liveToken)} controls={true} muted={true} playing={true} playsinline={true} width={"100%"} height={"100%"} options={{file: {forceFLV: true, hlsOptions: {backBufferLength: 30}}}}
                             ref={playerRef}
                             onProgress={(e) => {
                                 if (playerRef && playerRef.current) {
                                     if (e.playedSeconds + 10 < e.loadedSeconds) {
                                         playerRef.current.seekTo(e.loadedSeconds - 7, 'seconds');
                                     }
                                 }
                             }}
                /> :
                <Grid container alignItems={"center"} justify={"center"} className={Styles.playerErrorWrapper}><Text className={Styles.noPlayerMessage}>
                    { displayMessage }
                    {canSubscribe && <br />}
                    {canSubscribe && <Button color={"primary"} variant={live.amISubscribed ? "outlined": "contained"} disableElevation size={"small"} onClick={onClickSubscribe}>{live.amISubscribed ? "Je ne suis plus interessé par ce live" : "Je suis interessé par ce live"}</Button>}
                </Text></Grid>
            }
            {isAdmin && isLive && getLiveAdminButtonBar()}
        </Grid>]
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            onLiveEnded();
            return <span>Le live est terminé</span>;
        } else {
            // Render a countdown
            return <span>Temps restant : {hours}:{minutes}:{seconds}</span>;
        }
    };

    let _getChat = () => {
        return <Grid className={clsx(Styles.chatWrapper, Styles.mobileTab, {[Styles.mobileActiveTab]:tab === 0})} container item xs={12} md={4}>
            <Grid item xs={12} className={clsx(Styles.chatHeaderWrapper, Styles.desktop)}>
                <Box className={Styles.chatAdminStatus}><div className={clsx(Styles.chatAdminStatusCircle, {[Styles.chatAdminStatusCircleActive]: live.adminConnectedToChat})} /><Text>Vendeur {live.adminConnectedToChat ? "online" : "offline"}</Text></Box>
                <Text className={Styles.chatHeader}>Chat ({live.nbConnected})</Text>
                {isLive && <Box className={Styles.chatTimeLeft}><Text><Countdown date={(Moment(live.startAt).unix() * 1000) + (1*3600*1000)} renderer={renderer} /></Text></Box>}
            </Grid>
            <Grid item xs={12} className={Styles.chatMessagesWrapper} ref={messagesListRef}>
                {isAdmin && <Text className={Styles.chatHelpText}>Si vous souhaitez supprimer dans le chat un message que vous considérez comme non admis, faites un clic droit pour le supprimer (ou bannir tous les messages de l'utilisateur)</Text>}
                {isAdmin && <Text className={clsx(Styles.chatHelpText, Styles.chatHelpTextMobile)}>Si vous souhaitez supprimer dans le chat un message que vous considérez comme non admis, restez appuyé sur le message pour le supprimer (ou bannir tous les messages de l'utilisateur)</Text>}
                {chatMessages.map(chatMessage => <LiveMessage
                    message={chatMessage}
                    onLongPress={(e) => displayAdminPopupDesktop(e, chatMessage)}
                    onRightClick={(e) => displayAdminPopupDesktop(e, chatMessage)}
                    isAdmin={isAdmin}
                    handleAccept={() => acceptProposition(chatMessage)}
                    handleRefuse={() => refuseProposition(chatMessage)}
                />)}
                <div style={{ float:"left", clear: "both" }}
                     ref={messagesEndRef}>
                </div>
            </Grid>
            <Grid item xs={12} className={Styles.chatSendWrapper}>
                <Box className={Styles.chatSendInputWrapper}>
                    <TextInput
                        className={Styles.chatSend}
                        placeholder={"Votre message"}
                        value={currentMessage}
                        disabled={!isLive}
                        onChange={(event) => setCurrentMessage(event.target.value)}
                        inputProps={{
                            enterkeyhint: "done"
                        }}
                        InputProps={{ disableUnderline: true }}
                        onPressEnter={() => {chatAutoScrollRef.current = true; sendMessage();}}/>
                    <Box className={Styles.chatSendDivider} />
                    <Button disabled={!isLive} disableElevation onClick={() => {chatAutoScrollRef.current = true; sendMessage();}} className={Styles.chatSendButton}>
                        <FontAwesome name={"paper-plane"} size={20} color={Constants.BLACK} className={Styles.chatSendInputIcon}/>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    }


    let _getShop = () => {
        if (!shop || redirectionModalVisible) {
            return null;
        }

        const availableFilters = [];
        let productsDom = [];
        if (isLive) {
            for (const lp of liveProducts) {
                if (filterProducts === -1 || filterProducts === lp.product.category.id) {
                    productsDom.push(<ProductSquare shop={shop} sendProposition={sendProposition}
                                                    instantBuy={instantBuy} liveProduct={lp}
                                                    wrapperStyle={ShopStyles.productWrapper} isLivePage
                                                    isLiveAdmin={isAdmin}
                                                    propositionInProgress={propositionInProgress}
                                                    setPropositionInProgress={setPropositionInProgress} />)
                }

                if (availableFilters.findIndex(f => f.id === lp.product.category.id) === -1) {
                    availableFilters.push(lp.product.category);
                }
            }
        } else {
            for (const product of shopProducts) {
                if (filterProducts === -1 || filterProducts === product.category.id) {
                    productsDom.push(
                        <ProductSquare shop={shop} product={product} wrapperStyle={ShopStyles.productWrapper} isLivePage isLiveAdmin={isAdmin}
                                       setSubscribedLive={setSubscribedLive}/>)
                }
                if (availableFilters.findIndex(f => f.id === product.category.id) === -1) {
                    availableFilters.push(product.category);
                }
            }
        }

        let osnDom = [];
        for (const key in shop.socialNetworks) {
            if (key.startsWith("other_")) {
                osnDom.push(
                    <Box className={ShopStyles.otherSocialMediaWrapper}>
                        <Text className={ShopStyles.otherSocialMedia} accessibilityRole={"link"} href={shop.socialNetworks[key]} target={'_blank'}
                              resizeMode={"stretch"}>W</Text>
                    </Box>
                );
            }
        }

        return <Grid item container className={clsx(ShopStyles.wrapper, ShopStyles.mobileTab, ShopStyles.liveWrapper, {[ShopStyles.mobileActiveTab]:tab === 1})}>
            <Grid item container xs={12} md={3} className={ShopStyles.leftColumn}>
                <Box className={ShopStyles.grayBlock}>
                    <Link href={"/shop/" + live.owner.id} target={"_blank"}>
                        {(shop && shop.picture) && <CardMedia component={"img"} src={getImageUrl(shop.picture.contentUrl, "medium")} className={ShopStyles.shopImage}/>}
                        {(!shop || !shop.picture) && <CardMedia component={"img"} src={UserIcon} className={ShopStyles.shopImage} />}
                    </Link>
                    <Text className={ShopStyles.memberSinceText}>Membre depuis le {shop ? Moment(shop.createdAt).format("DD/MM/YYYY") : ''}</Text>
                    {shop && <Text className={ShopStyles.shopType}>{shop.type === 0 ? "Boutique particulier / professionnel de la seconde main" : shop.type === 1 ? "Boutique eco-responsable" : shop.type === 2 ? "Boutique créateur" : shop.type === 3 ? "Boutique blogueur" : ""}</Text>}
                    <Text className={ShopStyles.description}>{shop ? shop.description : ""}</Text>
                </Box>
                <Box className={ShopStyles.grayBlock} style={{alignItems: "start"}}>
                    <Text className={ShopStyles.secondBlockText} style={{textTransform: "uppercase"}}>{shop ? shop.facturationCity : ""}</Text>
                    <Text className={ShopStyles.secondBlockText}>-</Text>
                    <Text className={ShopStyles.secondBlockText}>{shop ? shop.nbProducts : 0} produit{shop && shop.nbProducts > 1 ? "s" : ""} en vente</Text>
                    <Text className={ShopStyles.secondBlockText}>{shop ? shop.nbSoldProducts : 0} produit{shop && shop.nbSoldProducts > 1 ? "s" : ""} vendu{shop && shop.nbSoldProducts > 1 ? "s" : ""}</Text>
                </Box>
                <Box className={ShopStyles.grayBlock}>
                    <Rating name="read-only" value={shop && shop.note ? shop.note : 0} readOnly/>
                    {/*{shop && shop.canIVote && <Button onClick={() => setShowVote(!showVote)} variant={"contained"} color={"primary"} disableElevation fullWidth style={{marginTop: 20, marginBottom: 20}}>Noter cette boutique</Button>}*/}
                    {/*{shop && shop.canIVote && <Rating name={"vote-for-shop"} value={shop && shop.myShopEval ? shop.myShopEval.note : 0} onChange={(event, value) => {voteForShop(shop.id, value); setShowVote(false);}} style={{visibility: showVote ? "visible" : "hidden"}}/>}*/}
                </Box>
                <Grid container justify={"center"} alignItems={"center"} direction={"column"}>
                    <Grid container justify={"center"} className={ShopStyles.socialMediaRow}>
                        {shop && "facebook" in shop.socialNetworks && shop.socialNetworks.facebook !== "" &&
                        <Box className={ShopStyles.socialMediaWrapper}>
                            <Image source={LogoFB} className={ShopStyles.socialMedia}  accessibilityRole={"link"} href={shop.socialNetworks.facebook} target={'_blank'} resizeMode={"stretch"}/>
                        </Box>
                        }
                        {shop && "twitter" in shop.socialNetworks && shop.socialNetworks.twitter !== "" &&
                        <Box className={ShopStyles.socialMediaWrapper}>
                            <Image source={LogoTwitter} className={ShopStyles.socialMedia}  accessibilityRole={"link"} href={shop.socialNetworks.twitter} target={'_blank'} resizeMode={"stretch"} />
                        </Box>
                        }
                        {shop && "instagram" in shop.socialNetworks && shop.socialNetworks.instagram !== "" &&
                        <Box className={ShopStyles.socialMediaWrapper}>
                            <Image source={LogoInsta} className={ShopStyles.socialMedia}  accessibilityRole={"link"} href={shop.socialNetworks.instagram} target={'_blank'} resizeMode={"stretch"} />
                        </Box>
                        }
                        {shop && "pinterest" in shop.socialNetworks && shop.socialNetworks.pinterest !== "" &&
                        <Box className={ShopStyles.socialMediaWrapper}>
                            <Image source={LogoPinterest} className={ShopStyles.socialMedia}  accessibilityRole={"link"} href={shop.socialNetworks.pinterest} target={'_blank'} resizeMode={"stretch"} />
                        </Box>
                        }
                        {osnDom.length > 0 ? osnDom : null}
                    </Grid>
                    <ShopSubscribeButton shop={shop} variant={"outlined"} wrapperStyle={{marginBottom: 25, marginTop: 25, width: "100%", maxWidth: 200}}/>
                    <Button onClick={() => history.push("/message/nouveau", {shopId: shop.id})} variant={"contained"} color={"primary"} disableElevation size={"small"} style={{marginBottom: 60, width: "100%", maxWidth: 200}}>Contacter</Button>

                    {shop !== null && shop.latitude && shop.longitude &&
                    <Map
                        center={[shop.latitude, shop.longitude]}
                        zoom={13}
                        visible={true}
                        mapStyle={{
                            width: Constants.GET_SIZE(323),
                            height: Constants.GET_SIZE(230),
                            maxWidth: "100%",
                            marginBottom: 40
                        }}
                        fixedViewpoint={true}
                        markers={[{position: [shop.latitude, shop.longitude], text: null}]}
                    />
                    }
                </Grid>
            </Grid>
            <Grid item container xs={12} md={9} className={clsx(ShopStyles.rightColumn, Styles.desktop)}>
                <Grid container item xs={12} justify={"space-between"} style={{marginBottom: 10}}>
                    <Grid>
                        <Select variant={"outlined"} value={filterProducts} onChange={(event) => setFilterProducts(event.target.value)}>
                            <MenuItem value={-1}>Catégorie</MenuItem>
                            {availableFilters.map(f => <MenuItem value={f.id}>{f.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid>
                        <Button variant={"outlined"} color={"secondary"} onClick={() => setTab(tab === 1 ? 0 : 1)}>Vue en {tab === 1 ? "liste" : "grille"}</Button>
                    </Grid>
                </Grid>
                {tab === 0 && <Swiper
                    className={clsx(Styles.desktop, Styles.swipeableViews)}
                    slidesPerView={Math.min(3.2, productsDom.length)}
                    navigation
                    autoplay
                    spaceBetween={20}>
                    {productsDom.map(elem => <SwiperSlide>{elem}</SwiperSlide>)}
                </Swiper>}
                {tab === 1 && productsDom.map(elem => <Grid item xs={12} md className={ShopStyles.productWrapperWrapper}>
                    {elem}
                </Grid>)}
            </Grid>
            {tab === 1 && <Swiper
                className={clsx(Styles.mobile, Styles.swipeableViews)}
                slidesPerView={1.5}
                spaceBetween={20}>
                {productsDom.map(elem => <SwiperSlide>{elem}</SwiperSlide>)}
            </Swiper>}
            <Paginator firstPage={shopProductsPagination.first} lastPage={shopProductsPagination.last} page={shopProductsPage} setPage={setShopProductsPage} backgroundColor={Constants.WHITE} className={clsx(Styles.desktop)} />
        </Grid>
    }

    let handleClickHelp = () => {
        setAutoExplanationPopup(false);
        setExplanationPopupVisible(true);
    }

    let _getPage = () => {
        return live ? <Grid container direction={"column"} >
            <Grid item container>
                {_getPlayer()}
                <Text className={clsx(Styles.pageTitle, Styles.mobile)}>
                    <Link href={"/shop/" + live.owner.id} target={"_blank"}>
                        {live.owner.picture && <CardMedia component={"img"} src={getImageUrl(live.owner.picture.contentUrl, "medium")} className={Styles.shopImageSmall}/>}
                        {!live.owner.picture && <CardMedia component={"img"} src={UserIcon} className={Styles.shopImageSmall} />}
                    </Link>
                    <span>Live de la boutique <Link href={"/shop/" + live.owner.id} target={"_blank"}>{live.owner.name}</Link></span>
                    <IconButton onClick={handleClickHelp}>
                        <HelpIcon  />
                    </IconButton>
                </Text>
                {_getTabs()}
                {_getChat()}
            </Grid>
            <Grid item container>
                {_getShop()}
            </Grid>
        </Grid> : null

    }

    return (
        <Grid className={Styles.container}>
            {live && <Text className={clsx(Styles.pageTitle, Styles.desktop)}>
                <span>Live de la boutique <Link href={"/shop/" + live.owner.id} target={"_blank"}>{live.owner.name}</Link></span>
                <IconButton onClick={handleClickHelp}>
                    <HelpIcon  />
                </IconButton>
            </Text>}
            {_getPage()}
            <Menu open={Boolean(anchorAdminPopup)} anchorEl={anchorAdminPopup} onClose={closeAdminPopup}>
                <MenuItem onClick={closeAdminPopup}>Supprimer</MenuItem>
                <MenuItem onClick={closeAdminPopup}>Bannir</MenuItem>
            </Menu>
            <Menu
                open={positionAdminPopup.mouseY !== null}
                anchorReference={'anchorPosition'}
                anchorPosition={
                    positionAdminPopup.mouseY !== null && positionAdminPopup.mouseX !== null
                        ? { top: positionAdminPopup.mouseY, left: positionAdminPopup.mouseX }
                        : undefined
                }
                onClose={closeAdminPopup}>
                <MenuItem onClick={handleDeleteMessage}>Supprimer</MenuItem>
                <MenuItem onClick={handleBanMessage}>Bannir</MenuItem>
            </Menu>
            <LiveExplanationModal
                isAdmin={isAdmin}
                visible={explanationPopupVisible}
                setVisible={setExplanationPopupVisible}
                showCheckbox={autoExplanationPopup}
            />
            <ConfirmationModal
                visible={redirectionModalVisible}
                setVisible={setRedirectionModalVisible}
                title={redirectionModalMessage}
                message={redirectionModalMessage}
                onConfirm={() => {history.push('/')}}/>
            <LiveBuyModal
                visible={buyingTunnelModal}
                setVisible={setBuyingTunnelModal}
                live={live}
                sendReserveProduct={sendReserveProduct}
                sendReleaseProduct={sendReleaseProduct}
                liveProduct={buyingTunnelModalProduct}
                proposition={buyingTunnelModalProposition}
                onCancel={() => {}}
                onFinished={(productId) => buyProduct(productId)} />
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        me: state.user.me
    }
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => {
            dispatch({type: 'START_LOADING_REQUESTED'})
        },
        stopLoading: () => {
            dispatch({type: 'START_LOADING_SUCCEEDED'})
        },
        getParams: () => {
            dispatch({type: 'FETCH_PARAMS_REQUESTED'})
        },
    }
};

const ConnectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Component));

export default withWidth()(ConnectedComponent)
