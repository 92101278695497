import React from "react";
import clsx from 'clsx';

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MyInfoStyle"
import TextInputIcon from "../Front/Component/TextInputIcon";
import ErrorStyleFunc from "../Styles/Error";
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import axios from "axios";
import {getApiUrl} from "../../Utils/QueryUtils";
import TextInputIconStylesFunc from "../../Style/TextInputIconStyle";
import TextField from "@material-ui/core/TextField/TextField";
import ListItemText from "@material-ui/core/ListItemText";


function Component({navigation, location, history, screenKey, token, user, saveUser, startLoading, stopLoading, registerErrors, modalVisible, setModalVisible}) {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { setValue, setError, handleSubmit, register, control, errors, watch } = useForm();

    const params = new URLSearchParams(location.search);
    const fromShop = params.get('fromShop');

    let [fromShopDialog, setFromShopDialog] = React.useState(Boolean(fromShop));
    let [facturationSameAsShipping, setFacturationSameAsShipping] = React.useState(user ? user.facturationSameAsShipping: true);
    let [displayPasswordChange, setDisplayPasswordChange] = React.useState(false);
    let [ cityAutocomplete, setCityAutocomplete] = React.useState([]);
    let [ allowAutocomplete, setAllowAutocomplete] = React.useState(false);
    let civility = watch('civility', -1);

    let loadUser = () => {
        if (user) {
            // Global info
            setValue("civility", user.civility !== null ? user.civility : -1);
            setValue("name", user.name);
            setValue("firstname", user.firstname);
            setValue("email", user.email);
            setValue("phone", user.phone);
            setValue("shippingAddress", user.shippingAddress);
            setValue("shippingCity", user.shippingCity);
            setValue("shippingZipcode", user.shippingZipCode);
            setValue("facturationSameAsShipping", user.facturationSameAsShipping);
            setValue("facturationAddress", user.facturationAddress);
            setValue("facturationCity", user.facturationCity);
            setValue("facturationZipCode", user.facturationZipCode);
        }
    };

    React.useEffect(() => {
        loadUser();
    }, [user]);

    React.useEffect(() => {
        if (registerErrors) {
            for (let i = 0; i < registerErrors.length; i++) {
                setError(registerErrors[i]["propertyPath"], {type: "custom", message: registerErrors[i]["message"]});
            }
        }
    }, [registerErrors]);


    let handleSaveUser = (data) => {

        console.log(data);
        let newUser = {
            id: user.id,
            civility: parseInt(data.civility).toString(),
            name: data.name,
            firstname: data.firstname,
            email: data.email,
            phone: data.phone,
            shippingAddress: data.shippingAddress,
            shippingZipCode: data.shippingZipcode,
            shippingCity: data.shippingCity,
            facturationSameAsShipping: data.facturationSameAsShipping,
            facturationAddress: data.facturationAddress,
            facturationZipCode: data.facturationZipCode,
            facturationCity: data.facturationCity

        };

        startLoading();
        axios.get(getApiUrl('shop/validate_address', {
            city: data.shippingCity,
            zipcode: data.shippingZipcode,
            address: data.shippingAddress
        })).then((response) => {
            if (data.password && data.password2) {
                newUser["plainPassword"] = data.password;
            }
            setDisplayPasswordChange(false);

            saveUser(newUser);
            // setModalVisible(true);
            stopLoading();
        }).catch((error) => {
            setError("shippingAddress", {type: "custom"});
            setError("shippingZipcode", {type: "custom"});
            setError("shippingCity", {type: "custom", message: "L'adresse est invalide"});
            stopLoading();
        });
    };

    let handleClickContinue = (data) => {
        handleSaveUser(data);
    };
    let TextInputIconStyles = TextInputIconStylesFunc();



    let enteredZipcode = watch('shippingZipcode');

    React.useEffect(() => {
        setCityAutocomplete([]);
        if (allowAutocomplete && enteredZipcode.length === 5) {
            axios.get(getApiUrl('shop/get_city_from_zipcode', {zipcode: enteredZipcode})).then((response) => {
                if (response && response.data) {
                    if (response.data.length >= 1) {
                        setCityAutocomplete(response.data);
                    }
                }
            }).catch((error) => {

            });
        }
    }, [enteredZipcode, allowAutocomplete]);

    let _getPage = () => {
        if (!user) {
            return null;
        }
        return <Grid container xs={12} justify={"space-between"}>
            <Grid item xs={12} sm={6} direction={"column"} className={Styles.column}>
                <Grid container item className={TextInputIconStyles.wrapper}>
                    <Grid item xs={12}>
                        <Controller
                            as={
                                <Select variant={"outlined"} className={Styles.picker} error={errors && errors["civility"]}>
                                    <MenuItem value={-1} disabled>Civilité</MenuItem>
                                    <MenuItem value={0}>Mr.</MenuItem>
                                    <MenuItem value={1}>Mme.</MenuItem>
                                </Select>
                            }
                            control={control}
                            rules={{validate: (value) => value !== -1 || "Ce champ est obligatoire"}}
                            name={"civility"}
                            defaultValue={user.civility !== null ? parseInt(user.civility) : -1}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorMessage name={"civility"} errors={errors} as={Text} className={ErrorStyle.error} />
                    </Grid>
                </Grid>
                <TextInputIcon
                    errors={errors}
                    register={register}
                    defaultValue={user.name}
                    placeholder={"Nom*"}
                    rules={{
                        required: "Ce champ est obligatoire",
                    }}
                    name={"name"}
                />
                <TextInputIcon
                    errors={errors}
                    register={register}
                    defaultValue={user.firstname}
                    placeholder={"Prénom*"}
                    rules={{
                        required: "Ce champ est obligatoire",
                    }}
                    name={"firstname"}
                />
                <TextInputIcon
                    errors={errors}
                    register={register}
                    defaultValue={user.email}
                    placeholder={"E-mail*"}
                    rules={{
                        required: "Ce champ est obligatoire",
                        pattern: {
                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Format de l'adresse e-mail invalide"
                        }
                    }}
                    name={"email"}
                />
                <Button onClick={() => setDisplayPasswordChange(!displayPasswordChange)} color={"primary"} variant={"outlined"} size={"small"} disableElevation className={Styles.buttonWhite}>
                    Modifier mon mot de passe
                </Button>
                {displayPasswordChange &&
                <TextInputIcon
                    errors={errors}
                    register={register}
                    placeholder={"Mon nouveau mot de passe*"}
                    rules={{
                        validate: (value) => value === watch('password2') || "Les deux mots de passe doivent être identiques"
                    }}
                    name={"password"}
                    secure
                />}
                {displayPasswordChange &&
                <TextInputIcon
                    errors={errors}
                    register={register}
                    placeholder={"Confirmation du nouveau mot de passe*"}
                    rules={{
                        validate: (value) => value === watch('password') || "Les deux mots de passe doivent être identiques"
                    }}
                    name={"password2"}
                    secure
                />
                }
                <TextInputIcon
                    errors={errors}
                    register={register}
                    defaultValue={user.phone}
                    placeholder={"Téléphone portable*"}
                    rules={{
                        required: "Ce champ est obligatoire",
                        pattern: {
                            value: /^0[0-9]{9}$/,
                            message: "Format du numéro de téléphone invalide"
                        }
                    }}
                    name={"phone"}
                />
                <Hidden xsDown>
                    <Button onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                        Enregistrer
                    </Button>
                </Hidden>
            </Grid>
            <Grid item xs={12} sm={6} direction={"column"} className={Styles.column} style={{position: "relative"}}>
                <TextInputIcon
                    errors={errors}
                    register={register}
                    defaultValue={user.shippingAddress}
                    placeholder={"Adresse de livraison*"}
                    rules={{
                        required: "Ce champ est obligatoire",
                    }}
                    name={"shippingAddress"}
                />
                <TextInputIcon
                    errors={errors}
                    register={register}
                    defaultValue={user.shippingZipcode}
                    onFocus={() => setAllowAutocomplete(true)}
                    onBlur={() => setTimeout(() => setAllowAutocomplete(false), 500)}
                    placeholder={"Code postal*"}
                    rules={{
                        required: "Ce champ est obligatoire",
                        pattern: {
                            value: /^[0-9]{5}$/,
                            message: "Format du code postal invalide"
                        }
                    }}
                    name={"shippingZipcode"}
                />
                {cityAutocomplete.length > 0 && <List className={Styles.cityAutocomplete}>
                    {cityAutocomplete.map((c) => <ListItemText className={Styles.cityAutocompleteItem} onClick={() => {setAllowAutocomplete(false); setValue('shippingCity', c);}}>{c}</ListItemText>)}
                </List>}
                <TextInputIcon
                    errors={errors}
                    register={register}
                    defaultValue={user.shippingCity}
                    readOnly={true}
                    placeholder={"Ville*"}
                    rules={{
                        required: "Ce champ est obligatoire",
                    }}
                    name={"shippingCity"}
                />

                <Grid container item className={TextInputIconStyles.wrapper}>
                    <Grid item xs={12}>
                        <Select variant={"outlined"} value={facturationSameAsShipping} onChange={event => setFacturationSameAsShipping(event.target.value)} className={Styles.picker}>
                            <MenuItem value={true}>Même adresse de facturation</MenuItem>
                            <MenuItem value={false}>Choisir son adresse de facturation</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                {!facturationSameAsShipping && <Box>
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        defaultValue={user.facturationAddress}
                        placeholder={"Adresse de facturation*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"facturationAddress"}
                    />
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        defaultValue={user.facturationZipCode}
                        placeholder={"Code postal*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"facturationZipCode"}
                    />
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        defaultValue={user.facturationCity}
                        placeholder={"Ville*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"facturationCity"}
                    />
                </Box>}
                <Hidden smUp>
                    <Button onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                        Enregistrer
                    </Button>
                </Hidden>
            </Grid>
        </Grid>
    };

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Mes informations personnelles</Text>
            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={"MY_INFO"} />
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    <Box className={Styles.contentWrapper}>
                        {_getPage()}
                    </Box>
                </Box>
            </Grid>
            <ConfirmationModal
                visible={modalVisible}
                setVisible={setModalVisible}
                title={"Mes informations personnelles"}
                message={"Vos informations ont bien été modifiées"}
                onConfirm={() => {setModalVisible(false); if (fromShop) { history.push("/creer_ma_boutique")}}} />
            <ConfirmationModal
                visible={fromShopDialog}
                setVisible={setFromShopDialog}
                title={"Informations"}
                message={"Vous devez remplir vos informations personnelles avant de pouvoir créer votre boutique."}
                onConfirm={() => {setFromShopDialog(false)}} />
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        registerErrors: state.user.registerErrors,
        modalVisible: state.user.confirmationModalVisible
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveUser: (user) => {
            dispatch({type: 'SAVE_USER_REQUESTED', user})
        },
        startLoading: (user) => {
            dispatch({type: 'START_LOADING_REQUESTED'})
        },
        stopLoading: (user) => {
            dispatch({type: 'START_LOADING_SUCCEEDED'})
        },
        setModalVisible: (display) => {
            if (display) {
                dispatch({type: 'DISPLAY_USER_CONFIRMATION_MODAL'})
            } else {
                dispatch({type: 'HIDE_USER_CONFIRMATION_MODAL'})
            }
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
