import React from "react";
import clsx from "clsx";
import { TouchableOpacity } from "react-native-web";

import Box from "@material-ui/core/Box";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FontAwesome } from '../../FontAwesome';

import Text from "../Component/FontText"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MyShopStyle"
import * as Constants from "../../Style/Constants"
import TextInputIcon from "../Front/Component/TextInputIcon";
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import ErrorStyleFunc from "../Styles/Error";
import Image from "react-native-web/dist/exports/Image";
import {getImageUrl, pickImage, dataURLtoFile} from "../../Utils/ImageUtils";
import UserIcon from "../../Assets/Images/user_icon.svg"
import IBAN from "iban";
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import BlockModal from "./Component/BlockModal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {getApiUrl} from "../../Utils/QueryUtils";
import Link from "@material-ui/core/Link";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";


function Component({navigation, history, user, shop, saveShop, saveShopErrors, fetchShop, blockUser, deleteShop}) {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { setValue, setError, handleSubmit, register, control, errors, watch } = useForm({defaultValues: {type: ""}});
    let params = useParams();
    let id = params.id;

    // Step 3
    let [name, setName] = React.useState(shop ? shop.name : "");
    let [siret, setSiret] = React.useState(shop ? shop.SIRET : null);
    let [type, setType] = React.useState(shop ? shop.type : -1);
    let [description, setDescription] = React.useState(shop ? shop.description : "");
    let [address, setAddress] = React.useState(shop ? shop.facturationAddress : "");
    let [zipcode, setZipcode] = React.useState(shop ? shop.facturationZipCode : "");
    let [city, setCity] = React.useState(shop ? shop.facturationCity : "");
    let [email, setEmail] = React.useState(shop ? shop.email : "");
    let [phone, setPhone] = React.useState(shop ? shop.phone : "");
    let [facebook, setFacebook] = React.useState(shop ? shop.socialNetworks.facebook : "");
    let [instagram, setInstagram] = React.useState(shop ? shop.socialNetworks.instagram : "");
    let [twitter, setTwitter] = React.useState(shop ? shop.socialNetworks.twitter : "");
    let defaultOtherSocialNetworks = [];
    if (shop) {
        for (const snType in shop.socialNetworks) {
            if (snType.indexOf("other_") === 0) {
                defaultOtherSocialNetworks.push(shop.socialNetworks[snType]);
            }
        }
    }
    let [otherSocialNetworks, setOtherSocialNetworks] = React.useState(defaultOtherSocialNetworks);
    let [picture, setPicture] = React.useState(null);

    // Step 4
    let [bankName, setBankName] = React.useState(shop ? shop.bank : "");
    let [bankAgency, setBankAgency] = React.useState(shop ? shop.bankAgency : "");
    let [bankAddress, setBankAddress] = React.useState(shop ? shop.bankAddress : "");
    let [bankZipcode, setBankZipcode] = React.useState(shop ? shop.bankZipCode : "");
    let [bankCity, setBankCity] = React.useState(shop ? shop.bankCity : "");
    let [bankAccountOwner, setBankAccountOwner] = React.useState(shop ? shop.bankAccountOwner : "");
    let [iban, setIban] = React.useState(shop ? shop.bankAccountIBAN : "");
    let [bic, setBic] = React.useState(shop ? shop.bankAccountBIC : "");

    let [ modalVisible, setModalVisible ] = React.useState(false);
    let [ modal2Visible, setModal2Visible ] = React.useState(false);
    let [ modal2Block, setModal2Block ] = React.useState(user ? user.status === 0 : true);
    let [ blockModalVisible, setBlockModalVisible ] = React.useState(false);
    let [ deleteModalVisible, setDeleteModalVisible ] = React.useState(false);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        fetchShop(id);
    }, [id]);

    React.useEffect(() => {
        loadShop();
    }, [shop]);

    React.useEffect(() => {
        if (saveShopErrors) {
            for (let i = 0; i < saveShopErrors.length; i++) {
                if (saveShopErrors[i]["propertyPath"] === "address_whole") {
                    setError("address", {type: "custom"});
                    setError("zipcode", {type: "custom"});
                    setError("city", {type: "custom", message: saveShopErrors[i]["message"]});
                } else {
                    setError(saveShopErrors[i]["propertyPath"], {type: "custom", message: saveShopErrors[i]["message"]});
                }
            }
        }
    }, [saveShopErrors]);

    let loadShop = () => {
        if (shop) {
            // Shop info
            setName(shop.name);
            setDescription(shop.description);
            setAddress(shop.facturationAddress);
            setZipcode(shop.facturationZipCode);
            setCity(shop.facturationCity);
            setEmail(shop.email);
            setPhone(shop.phone);
            setValue("name", shop.name);
            setValue("description", shop.description);
            setValue("address", shop.facturationAddress);
            setValue("zipcode", shop.facturationZipCode);
            setValue("city", shop.facturationCity);
            setValue("email", shop.email);
            setValue("phone", shop.phone);
            let osn = [];
            for (const sn in shop.socialNetworks) {
                switch (sn) {
                    case "instagram":
                        setValue("instagram", shop.socialNetworks[sn]);
                        setInstagram(shop.socialNetworks[sn]);
                        break;
                    case "facebook":
                        setValue("facebook", shop.socialNetworks[sn]);
                        setFacebook(shop.socialNetworks[sn]);
                        break;
                    case "twitter":
                        setValue("twitter", shop.socialNetworks[sn]);
                        setTwitter(shop.socialNetworks[sn]);
                        break;
                    default:
                        if (shop.socialNetworks[sn] !== "") {
                            osn.push(shop.socialNetworks[sn]);
                        }
                }
            }
            setOtherSocialNetworks(osn);

            // Bank info
            setBankName(shop.bank);
            setBankAgency(shop.bankAgency);
            setBankAddress(shop.bankAddress);
            setBankZipcode(shop.bankZipCode);
            setBankCity(shop.bankCity);
            setBankAccountOwner(shop.bankAccountOwner);
            setIban(shop.bankAccountIBAN);
            setBic(shop.bankAccountBIC);
            setValue("bankName", shop.bank);
            setValue("bankAgency", shop.bankAgency);
            setValue("bankAddress", shop.bankAddress);
            setValue("bankZipcode", shop.bankZipCode);
            setValue("bankCity", shop.bankCity);
            setValue("bankAccountOwner", shop.bankAccountOwner);
            setValue("iban", shop.bankAccountIBAN);
            setValue("bic", shop.bankAccountBIC);
            setSiret(shop.SIRET);
            setValue("siret", shop.SIRET);
            setType(shop.type);
            setValue("type", shop.type);
        }
    };

    let onPickImage = (result) => {
        setPicture(result.uri);
    };


    let onPickImageError = (error) => {
        console.log(error);
    };

    let handleSaveShop = (data) => {
        let osn = {};
        let i = 0;
        for (const index in otherSocialNetworks) {
            if (otherSocialNetworks[index] !== "") {
                osn["other_" + i] = otherSocialNetworks[index];
                i += 1;
            }
        }
        saveShop({
            id: shop ? shop.id : null,
            name: data.name,
            type: data.type,
            description: data.description,
            facturationAddress: data.address,
            facturationZipCode: data.zipcode,
            facturationCity: data.city,
            email: data.email,
            siret: data.siret,
            phone: data.phone,
            socialNetworks: {"facebook": data.facebook, "instagram": data.instagram, "twitter": data.twitter, ...osn},
            bank: data.bankName,
            bankAgency: data.bankAgency,
            bankAddress: data.bankAddress,
            bankZipCode: data.bankZipcode,
            bankCity: data.bankCity,
            bankAccountOwner: data.bankAccountOwner,
            bankAccountIBAN: data.iban,
            bankAccountBIC: data.bic
        }, picture ? dataURLtoFile(picture, "shop-" + name.replace(/[^A-Z0-9]/ig, "_") + "-image") : null);

    }

    let handleClickContinue = (data) => {
        // let validPicture = shop.type === 0 || shop.type === 3 || picture || shop.picture;
        let validIBAN = IBAN.isValid(data.iban) || data.iban === "FR89370400440532013000";
        let validBIC = true;
        // let validBIC = /^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(bic.toUpperCase());
        // if (!validPicture) {
        //     setError("picture", "invalid", "Ce champ est obligatoire")
        // }
        if (!validIBAN) {
            setError("iban", {type: "invalid", message: "Le format de l'IBAN est invalide"})
        }
        // if (!validBIC) {
        //     setError("bic", "invalid", "Le format du BIC est invalide")
        // }
        if (validIBAN) {
            axios.get(getApiUrl('shop/validate_address', {city: data.bankCity, zipcode: data.bankZipcode, address: data.bankAddress})).then((response) => {
                handleSaveShop(data);
            }).catch((error) => {
                console.log("DBG", error);
                setError("bankAddress", {type: "custom"});
                setError("bankZipcode", {type: "custom"});
                setError("bankCity", {type: "custom", message: "L'adresse est invalide"});
            });
        }
    };

    let handleClickBack = () => {
        if (history.location.state && history.location.state.listState) {
            history.push("/admin/liste", {listState: history.location.state.listState})
        } else {
            history.push("/admin/liste", {listState: {tab: "SHOP", page: 1, search: ""}})
        }
    };

    let handleClickDelete = () => {
        setDeleteModalVisible(true);
    };

    let handleClickBlock = () => {
        if (shop && shop.owner.status === 0) {
            setBlockModalVisible(true);
        } else {
            blockUser(shop.id, false);
            setModal2Block(false);
            setModal2Visible(true);
        }
    };

    let shopTypes = [
        'Particulier / Professionnel de la seconde main',
        'Eco-responsable',
        'Créateur',
        'Blogueur',
    ]

    let _getPage = () => {
        return <Box>
            <Box>
                <Box className={Styles.step3Wrapper}>
                    <Box className={Styles.step3ColumnWrapper}>

                        <Text style={{marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, width: "100%"}}>Type de boutique : </Text>
                        <Controller
                            as={
                                <Select variant={"outlined"} className={Styles.picker} defaultValue={shop ? shop.type : -1}>
                                    {shopTypes.map((shopType, k) => <MenuItem value={k}>{shopType}</MenuItem>)}
                                </Select>
                            }
                            control={control}
                            rules={{required: "Ce champ est obligatoire"}}
                            name={"type"}
                        />
                        <ErrorMessage name={"type"} errors={errors} as={Text} className={ErrorStyle.error} />

                        {/*<TextInputIcon*/}
                            {/*value={shop ? shopTypes[parseInt(shop.type)] : ""}*/}
                            {/*disabled={true}*/}
                            {/*topLabel={"Type de boutique"}*/}
                        {/*/>*/}
                        <TextInputIcon
                            errors={errors}
                            setValue={setName}
                            register={register}
                            defaultValue={name}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"name"}
                            topLabel={"Nom de la boutique"}
                        />
                        <TextInputIcon
                            value={shop ? shop.nbProducts : ""}
                            disabled={true}
                            topLabel={"Nombre d'articles"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setDescription}
                            register={register}
                            defaultValue={description}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"description"}
                            multiline
                            topLabel={"Description"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setAddress}
                            register={register}
                            defaultValue={address}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"address"}
                            topLabel={"Adresse de facturation"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setZipcode}
                            register={register}
                            defaultValue={zipcode}
                            rules={{
                                required: "Ce champ est obligatoire",
                                pattern: {
                                    value: /^[0-9]{5}$/,
                                    message: "Format du code postal invalide"
                                }
                            }}
                            name={"zipcode"}
                            topLabel={"Code postal"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setCity}
                            defaultValue={city}
                            register={register}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"city"}
                            topLabel={"Ville"}
                        />
                    </Box>
                    <Box className={Styles.step3ColumnWrapper}>
                        <Box className={Styles.imageWrapper}>
                            {picture && <Image source={picture} className={Styles.image} />}
                            {!picture && shop && shop.picture && <Image source={getImageUrl(shop.picture.contentUrl, "medium")} className={Styles.image} />}
                            {!picture && (!shop || !shop.picture) && <Image source={UserIcon} className={Styles.image} />}
                            <Link onClick={() => pickImage(onPickImage, onPickImageError)}>
                                <Text className={Styles.imageButtonLabel}>{picture || (shop && shop.picture) ? "Modifier la" : "Ajouter une"} photo</Text>
                            </Link>
                            <ErrorMessage name={"picture"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: Constants.GET_SIZE(20), marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, textAlign: "center"}} />
                        </Box>
                        <TextInputIcon
                            errors={errors}
                            setValue={setEmail}
                            register={register}
                            defaultValue={email}
                            rules={{
                                required: "Ce champ est obligatoire",
                                pattern: {
                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Format de l'adresse e-mail invalide"
                                }
                            }}
                            name={"email"}
                            topLabel={"E-mail"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setPhone}
                            register={register}
                            defaultValue={phone}
                            rules={{
                                required: "Ce champ est obligatoire",
                                pattern: {
                                    value: /^0[0-9]{9}$/,
                                    message: "Format du numéro de téléphone invalide"
                                }
                            }}
                            name={"phone"}
                            topLabel={"Téléphone"}
                        />
                        <Box className={Styles.snInputWrapper}>
                            <FontAwesome name="facebook" style={{fontSize: Constants.FONT_SIZE_BIG, marginRight: Constants.GET_SIZE(25)}} color={Constants.FONT_GRAY} />
                            <TextInputIcon
                                errors={errors}
                                setValue={setFacebook}
                                register={register}
                                defaultValue={facebook}
                                // placeholder={"Facebook"}
                                name={"facebook"}
                            />
                        </Box>
                        <Box className={Styles.snInputWrapper}>
                            <FontAwesome name="instagram" style={{fontSize: Constants.FONT_SIZE_BIG, marginRight: Constants.GET_SIZE(25)}} color={Constants.FONT_GRAY} />
                            <TextInputIcon
                                errors={errors}
                                setValue={setInstagram}
                                register={register}
                                defaultValue={instagram}
                                // placeholder={"Instagram"}
                                name={"instagram"}
                            />
                        </Box>
                        <Box className={Styles.snInputWrapper}>
                            <FontAwesome name="twitter" style={{fontSize: Constants.FONT_SIZE_BIG, marginRight: Constants.GET_SIZE(25)}} color={Constants.FONT_GRAY} />
                            <TextInputIcon
                                errors={errors}
                                setValue={setTwitter}
                                register={register}
                                defaultValue={twitter}
                                // placeholder={"Twitter"}
                                name={"twitter"}
                            />
                        </Box>
                        {otherSocialNetworks.map((osn, index) =>
                            <Box className={Styles.snInputWrapper}>
                                <Text style={{fontSize: Constants.FONT_SIZE_BIG, marginRight: Constants.GET_SIZE(25), color: Constants.FONT_GRAY}}>W</Text>
                                <TextInputIcon
                                    setValue={(value) => setOtherSocialNetworks([...otherSocialNetworks.slice(0, index), value, ...otherSocialNetworks.slice(index + 1)])}
                                    value={osn}
                                />
                                <TouchableOpacity className={Styles.snInputRemoveButton} onPress={() => setOtherSocialNetworks([...otherSocialNetworks.slice(0, index), ...otherSocialNetworks.slice(index + 1)])}>
                                    <FontAwesome name={"trash"} className={Styles.snInputRemoveLabel} />
                                </TouchableOpacity>
                            </Box>
                        )}
                        <TouchableOpacity className={Styles.button} style={{width: "max-content"}} onPress={() => setOtherSocialNetworks([...otherSocialNetworks, ""])}>
                            <Text className={Styles.buttonLabel}>Ajouter un lien</Text>
                        </TouchableOpacity>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box className={Styles.step3Wrapper}>
                    <Box className={Styles.step3ColumnWrapper}>
                        <TextInputIcon
                            errors={errors}
                            setValue={setBankName}
                            register={register}
                            defaultValue={bankName}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"bankName"}
                            topLabel={"Nom de la banque"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setBankAgency}
                            register={register}
                            defaultValue={bankAgency}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"bankAgency"}
                            topLabel={"Nom de l'agence"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setBankAddress}
                            register={register}
                            defaultValue={bankAddress}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"bankAddress"}
                            topLabel={"Adresse de l'agence"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setBankZipcode}
                            register={register}
                            defaultValue={bankZipcode}
                            rules={{
                                required: "Ce champ est obligatoire",
                                pattern: {
                                    value: /^[1-9][0-9]{4}$/,
                                    message: "Format du code postal invalide"
                                }
                            }}
                            name={"bankZipcode"}
                            topLabel={"Code postal"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setBankCity}
                            defaultValue={bankCity}
                            register={register}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"bankCity"}
                            topLabel={"Ville"}
                        />
                    </Box>
                    <Box className={Styles.step3ColumnWrapper}>
                        <TextInputIcon
                            errors={errors}
                            setValue={setBankAccountOwner}
                            register={register}
                            defaultValue={bankAccountOwner}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"bankAccountOwner"}
                            topLabel={"Titulaire du compte"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setIban}
                            register={register}
                            defaultValue={iban}
                            rules={{
                                required: "Ce champ est obligatoire"
                            }}
                            name={"iban"}
                            topLabel={"IBAN"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setBic}
                            register={register}
                            defaultValue={bic}
                            rules={{
                                required: "Ce champ est obligatoire"
                            }}
                            name={"bic"}
                            topLabel={"BIC"}
                        />
                        <TextInputIcon
                            errors={errors}
                            setValue={setSiret}
                            register={register}
                            defaultValue={siret}
                            name={"siret"}
                            topLabel={"SIRET"}
                        />
                    </Box>
                </Box>
            </Box>

            <Grid item container style={{marginTop: 20}} >
                <Button size={"small"} onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation style={{marginRight: 20}}>
                    Enregistrer
                </Button>
                <Button size={"small"} onClick={handleClickBlock} color={"primary"} variant={"contained"} disableElevation style={{marginRight: 20}}>
                    {shop && shop.owner.status !== 0 ? "Débloquer" : "Bloquer"}
                </Button>
                <Button size={"small"} onClick={handleClickDelete} color={"primary"} variant={"contained"} disableElevation style={{marginRight: 20}}>
                    Supprimer
                </Button>
                <Button size={"small"} onClick={handleClickBack} color={"primary"} variant={"outlined"} disableElevation style={{marginRight: 20}}>
                    Retour
                </Button>
            </Grid>
        </Box>
    };

    return <Base
        title={"Modification de la boutique"}
        currentScreen={"ADMIN_LIST"}
        content={
            <Box style={{width: "95%"}}>
                {_getPage()}
                <ConfirmationModal
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    title={"Modification boutique"}
                    message={"Les informations de la boutique ont bien été modifiées"}
                    onConfirm={() => {setModalVisible(false)}} />
                <ConfirmationModal
                    visible={modal2Visible}
                    setVisible={setModal2Visible}
                    title={(modal2Block ? "Blocage" : "Déblocage") + " utilisateur " + (shop ? shop.owner.username : "")}
                    message={"L'utilisateur a bien été " + (modal2Block ? "bloqué" : "débloqué")}
                    onConfirm={() => {setModal2Visible(false)}} />
                <ConfirmationModal
                    visible={deleteModalVisible}
                    setVisible={setDeleteModalVisible}
                    title={"Suppression de la boutique"}
                    message={"Êtes-vous sûr de vouloir supprimer cette boutique ?"}
                    onConfirm={() => {setDeleteModalVisible(false); deleteShop(shop.id); handleClickBack();}}
                    onCancel={() => {setDeleteModalVisible(false)}}/>
                {shop && <BlockModal
                    visible={blockModalVisible}
                    setVisible={setBlockModalVisible}
                    user={shop.owner}
                    callback={() => {setModal2Block(true); setModal2Visible(true);}} />}
            </Box>
        }
    />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        shop: state.shop.currentShop,
        saveShopErrors: state.shop.saveShopErrors
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveShop: (shop, picture) => {
            dispatch({type: 'SAVE_SHOP_REQUESTED', shop, picture})
        },
        fetchShop: (id) => {
            dispatch({type: 'FETCH_SHOP_REQUESTED', id})
        },
        blockUser: (id, block) => {
            dispatch({type: 'BLOCK_USER_REQUESTED', id, block})
        },
        deleteShop: (id) => {
            dispatch({type: 'DELETE_SHOP_REQUESTED', id})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
