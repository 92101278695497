import React from "react";
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
// import { Link } from "react-router-dom";
import Text from "../../Component/FontText";
import MeetingVSLastImage from "../../../Assets/Images/static/static_meetingvs_last_image.svg"
import StylesFunc from "../../../Style/BecomeSellerBlockStyle";
import * as Constants from "../../../Style/Constants";
import {InternalLink} from "../../Component/InternalLink";

function Component({hideLinks}) {
    let Styles = StylesFunc();
    return (
        <Grid container className={Styles.block}>
            <Grid item xs={12} sm={4} className={Styles.blockPinkCard}>
                <Text className={Styles.blockPinkCardTitle} component={"h3"}>
                    Livraison click and collect
                </Text>
                <Text className={Styles.blockPinkCardContent}>
                    Récupérez vos articles directement sur nos vide-dressings
                </Text>
                {!hideLinks && <InternalLink className={Styles.blockPinkCardLink} link={"STATIC_CAC_DELIVERY"} label={"Plus d'infos"} labelClass={Styles.blockPinkCardLink} linkStyle={{textDecorationColor: Constants.WHITE}} center/>}
            </Grid>
            <Grid item xs={12} sm className={Styles.blockMiddleCard} style={{backgroundImage: "url(" + MeetingVSLastImage + ")"}}>
                <Box className={Styles.blockMiddleCardContent}>
                    <Text className={Styles.blockMiddleCardTitle} component={"h3"}>
                        Devenez vendeur avec Violette Sauvage
                    </Text>
                    <Text className={Styles.blockMiddleCardText}>
                        Deviens vendeur et ambassadeur
                    </Text>
                    <Box className={Styles.blockMiddleCardLinkWrapper}>
                        <Link href={"/ma_boutique"} style={{textDecoration: "none"}} color={"inherit"}>
                            <Text className={Styles.blockMiddleCardLink}>
                                Devenir vendeur
                            </Text>
                        </Link>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={4} className={Styles.blockPinkCard}>
                <Text className={Styles.blockPinkCardTitle} component={"h3"}>
                    Partagez nos valeurs
                </Text>
                <Text className={Styles.blockPinkCardContent}>
                    Adoptez de nouveaux mode de consommation et développez le recyclage dans votre quotidien
                </Text>
                {!hideLinks && <InternalLink className={Styles.blockPinkCardLink} link={"STATIC_VALUES"} label={"Plus d'infos"} labelClass={Styles.blockPinkCardLink} linkStyle={{textDecorationColor: Constants.WHITE}} center/>}
            </Grid>
        </Grid>
    );
}

export default Component
