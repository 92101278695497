import React from "react";
import clsx from 'clsx';
import { TouchableOpacity } from "react-native-web";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FontAwesome } from '../../FontAwesome';

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MySalesStyle"
import * as Constants from "../../Style/Constants"
import Paginator from "../Front/Component/Paginator";
import Image from "react-native-web/dist/exports/Image";
import {getImageUrl, getPdfUrl} from "../../Utils/ImageUtils";
import Moment from "moment";
import DisputeModal from "./Component/DisputeModal";
import TextInputIcon from "../Front/Component/TextInputIcon";
import ErrorStyleFunc from "../Styles/Error";
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import MenuItem from "@material-ui/core/MenuItem";


function Component({navigation, history, token, user, purchases, validateError, validateStatus, validateStatusType, pagination, validateDelivery, fetchSales}) {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    let [page, setPage] = React.useState(1);
    let [sort, setSort] = React.useState(0);
    let [sortDir, setSortDir] = React.useState(0);
    let [tab, setTab] = React.useState(0);
    let [closedPurchases, setClosedPurchases] = React.useState([]);
    let [disputeModalsVisiblity, setDisputeModalsVisiblity] = React.useState([]);
    let [returnConfirmationModalVisible, setReturnConfirmationModalVisible] = React.useState(false);
    let [codeValidationModalVisible, setCodeValidationModalVisible] = React.useState(false);
    let [code, setCode] = React.useState([]);

    React.useEffect(() => {
        if (!user || !user.shop) {
            history.push("/creer_ma_boutique");
        }
    }, [user]);

    React.useEffect(() => {
        fetchSales(page, 4, sort, sortDir, tab);
    }, [token, page, tab, sort, sortDir]);

    React.useEffect(() => {
        if (page > pagination.last) {
            setPage(pagination.last);
        } else if (page < pagination.first) {
            setPage(pagination.first);
        }
    }, [pagination]);

    let getDeliveryName = (deliveryType) => {
        switch (deliveryType) {
            case 0:
                return "Chronopost (livraison)";
            case 1:
                return "Chronopost (relais)";
            case 2:
                return "Mondial relay";
            case 3:
                return "Click & Collect";
            case 4:
                return "en main propre";
        }
    };

    let handleDeliveryButton = (purchase, purchaseProduct) => {
        validateDelivery(purchase, purchaseProduct, null);
    };

    let handleCodeInput = (value, purchaseProductId) => {
        let newCode = Object.assign({}, code);
        newCode[purchaseProductId] = value;
        setCode(newCode);
    };

    let handleCodeValidate = (purchase, purchaseProduct) => {
        validateDelivery(purchase, purchaseProduct, code[purchaseProduct.id]);
    }

    React.useEffect(() => {
        if (validateStatus === "OK" && validateStatusType === 4) {
            setCodeValidationModalVisible(true);
        }
    }, [validateStatus])

    let handlePressSort = (sortId) => {
        if (sort === sortId) {
            if (sortDir === 0) {
                setSortDir(1);
            } else {
                setSortDir(0);
            }
        } else {
            setSort(sortId);
            setSortDir(0)
        }
    }

    let _getTabs = () => {
        return <Grid container justify={"center"} alignItems={"center"} className={Styles.tabsWrapper}>
            <Button variant={tab === 0 ? "contained" : "outlined"} color={"primary"} disableElevation onClick={() => setTab(0)} size={"small"}>
                Ventes en cours
            </Button>
            <Button variant={tab === 1 ? "contained" : "outlined"} color={"primary"} disableElevation onClick={() => setTab(1)} size={"small"}>
                Historique des ventes
            </Button>
        </Grid>
    }

    let _getPurchaseProduct = (purchase, purchaseProduct, shop) => {
        let hasDispute = false;
        let isDisputeResolved = false;
        let currentDispute = null;
        for (const dispute of purchase.disputes) {
            console.log(dispute, purchaseProduct.product);
            hasDispute = hasDispute || (dispute.shop.id === shop.shop.id && dispute.product.id === purchaseProduct.product.id);
            if (hasDispute) {
                currentDispute = dispute;
                isDisputeResolved = dispute.deleted || (dispute.status !== 0);
                break;
            }
        }

        let canHaveDispute = false;
        if (purchaseProduct.deliveryType <= 2) {
            canHaveDispute = true;
        }

        if (purchase.status === 2 || purchase.status === 4) {
            canHaveDispute = false;
        }

        return <Box className={Styles.productAndButtonWrapper}>
            <Grid container className={Styles.productWrapper}>
                <Image source={getImageUrl(purchaseProduct.product.pictures[0] ? purchaseProduct.product.pictures[0].contentUrl : null, "medium")}
                       className={Styles.productImage}/>
                <Box className={Styles.productTextWrapper}>
                    <Text className={Styles.productName}>{purchaseProduct.product.name}</Text>
                    <Text className={Styles.productBrand}>{purchaseProduct.product.brand ? purchaseProduct.product.brand.value : ""}</Text>
                    <Text
                        className={Styles.productCategory}>{purchaseProduct.product.category.name} {purchaseProduct.size ? purchaseProduct.size.value : ""}</Text>
                    <Text className={Styles.productPrice}>{purchaseProduct.paidPrice ? purchaseProduct.paidPrice.toFixed(2) : "N/A"} €</Text>
                </Box>
                <Box className={Styles.productAddressWrapper}>
                    {purchaseProduct.deliveryType < 3 && <Box>
                        <Text className={Styles.productAddressTitle}>Adresse de livraison :</Text>
                        <Text className={Styles.productAddress}>{purchase.deliveryAddress}</Text>
                        <Text
                            className={Styles.productAddress}>{purchase.deliveryZipcode} {purchase.deliveryCity}</Text>
                    </Box>}
                    {purchaseProduct.deliveryType >= 3 && <Box>
                        <Text className={Styles.productAddressTitle}>Coordonnées de l'acheteur :</Text>
                        <Text className={Styles.productAddress}>{purchase.user.firstname} {purchase.user.name}</Text>
                        <Text className={Styles.productAddress}>{purchase.user.phone}</Text>
                    </Box>}
                    <Text className={Styles.productAddressTitle}>Adresse de facturation :</Text>
                    <Text className={Styles.productAddress}>{purchase.facturationAddress}</Text>
                    <Text
                        className={Styles.productAddress}>{purchase.facturationZipcode} {purchase.facturationCity}</Text>
                </Box>
                <Box className={Styles.productDeliveryInfoWrapper}>
                    <Text
                        className={Styles.productDeliveryInfoTitle}>Livraison {getDeliveryName(purchaseProduct.deliveryType)}</Text>
                    <Text className={Styles.productDeliveryTrackingNumber}>Statut
                        : {hasDispute ? (isDisputeResolved ? "Litige résolu" : "Litige en cours") : purchaseProduct.status === 0 ? "Commande validée" : purchaseProduct.status === 1 ? "Commande finalisée" : purchaseProduct.status === 2 ? "Retour demandé" : purchaseProduct.status === 3 ? "Retour confirmé" : "Commande annulée"}</Text>
                    {purchaseProduct.deliveryType < 3 &&
                    <Box>
                        <Box className={Styles.productDeliveryTrackingWrapper} style={{display: "flex", flexDirection: "row"}}>
                            <Text className={Styles.productDeliveryTrackingNumber}>N° de suivi :&nbsp;</Text>
                            <Link
                                className={Styles.productDeliveryTrackingLink}
                                href={purchaseProduct.deliveryType === 2 ? "https://www.mondialrelay.com/public/permanent/tracking.aspx?ens=BDTEST13&exp=" + purchaseProduct.trackingNumber: "http://www.chronopost.fr/expedier/inputLTNumbersNoJahia.do?listeNumeros=" + purchaseProduct.trackingNumber}
                                target={'_blank'}>
                                <Text>{purchaseProduct.trackingNumber}</Text>
                            </Link>
                        </Box>
                        {/*{purchaseProduct.status === 2 ?*/}
                            {/*<Button disableElevation variant={"contained"} color={"primary"} size={"small"} className={Styles.productDeliveryButton}*/}
                                              {/*onClick={() => handleDeliveryButton(purchase, purchaseProduct)}>*/}
                                {/*Valider le retour*/}
                            {/*</Button> : null}*/}
                    </Box>
                    }
                    {purchaseProduct.deliveryType >= 3 && purchaseProduct.status < 1 &&
                    <Box>
                        <Box className={Styles.productDeliveryTrackingWrapper} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            {purchaseProduct.status !== -1 && <Text className={Styles.productDeliveryCodeLabel}>Code : </Text>}
                            {purchaseProduct.status !== -1 && <TextInputIcon
                                inputWrapperClass={Styles.inputWrapper}
                                wrapperClass={clsx(Styles.inputWrapper, {[ErrorStyle.error]: validateError && validateError.id === purchaseProduct.id})}
                                inputClass={clsx(Styles.input, {[ErrorStyle.error] : validateError && validateError.id === purchaseProduct.id})}
                                inputComponentClass={Styles.inputComponent}
                                setValue={value => handleCodeInput(value, purchaseProduct.id)}
                                value={purchaseProduct.id in code ? code[purchaseProduct.id] : ""}
                                placeholder={"Code*"}/>}
                            {purchaseProduct.status !== -1 && <TouchableOpacity onPress={() => handleCodeValidate(purchase, purchaseProduct)}>
                                <FontAwesome name={"check"} className={Styles.inputButtonIcon}/>
                            </TouchableOpacity>}
                            {purchaseProduct.status === -1 && <Text className={Styles.productDeliveryCodeLabel}>Commande annulée</Text>}
                        </Box>
                        {validateError && validateError.id === purchaseProduct.id &&
                        <Text className={ErrorStyle.error}>{validateError.error}</Text>
                        }
                    </Box>}
                </Box>
            </Grid>
            {purchaseProduct.product.returnPossible && purchaseProduct.status === 2 &&
            <Box className={Styles.productButtonWrapper}>
                <Button disableElevation variant={"outlined"} color={"primary"} size={"small"} onClick={() => {handleDeliveryButton(purchase, purchaseProduct); setReturnConfirmationModalVisible(true)}}>
                    Confirmer le retour du produit
                </Button>
            </Box>}
            {canHaveDispute &&
            <Box className={Styles.productButtonWrapper} style={{marginBottom: purchaseProduct.parcel && purchaseProduct.status === 0 ? '10px' : 0}}>
                <Button disabled={isDisputeResolved} disableElevation variant={"contained"} color={"primary"} size={"small"} onClick={() => !hasDispute ? history.push("/message/nouveau", {shopId: user.shop.id, recipient: purchase.user, purchaseProduct, purchase, dispute: true}) : history.push("/message/" + currentDispute.messageThread.id)} className={Styles.disputeButton}>
                    {hasDispute ? (isDisputeResolved ? "Litige résolu" : "Litige en cours") : "Déclarer un litige"}
                </Button>
            </Box>}
            {purchaseProduct.parcel && purchaseProduct.status === 0 &&
            <Box className={Styles.productButtonWrapper}>
                <Button disableElevation variant={"outlined"} color={"primary"} size={"small"} href={getPdfUrl(purchaseProduct.parcel.contentUrl)} target={'_blank'}>
                    Télécharger le bordereau d'envoi
                </Button>
            </Box>}
            <DisputeModal shop={shop.shop} purchase={purchase} product={purchaseProduct.product}
                          visible={disputeModalsVisiblity.indexOf(purchase.id + '-' + shop.shop.id + '-' + purchaseProduct.product.id) !== -1}
                          setVisible={() => toggleDisputeModal(purchase, shop.shop, purchaseProduct.product)}/>
        </Box>
    };

    let toggleDisputeModal = (purchase, shop, product) => {
        const index = disputeModalsVisiblity.indexOf(purchase.id + '-' + shop.id + '-' + product.id);
        if (index !== -1) {
            setDisputeModalsVisiblity([
                ...disputeModalsVisiblity.slice(0, index),
                ...disputeModalsVisiblity.slice(index + 1)
            ]);
        } else {
            setDisputeModalsVisiblity([
                ...disputeModalsVisiblity,
                purchase.id + '-' + shop.id + '-' + product.id
            ]);
        }
    };

    let _getPurchaseShop = (purchase, shop, isLast) => {
        const productsDom = shop.products.map(purchaseProduct => _getPurchaseProduct(purchase, purchaseProduct, shop));

        let invoice;
        if (shop.products[0].invoice) {
            invoice = shop.products[0].invoice;
        }

        return <Box className={Styles.shopWrapper}>
            <Box className={Styles.shopHeader}>
                <Text className={Styles.shopHeaderLabel}>{shop.shop.name}</Text>
            </Box>
            {productsDom}
            <Grid container className={clsx(Styles.shopFooter, {[Styles.shopFooterLast]: isLast})}>
                <Button disabled={!invoice} disableElevation variant={"contained"} color={"primary"} size={"small"} target={"_blank"} href={invoice ? getPdfUrl(invoice.contentUrl) : '#'} className={Styles.disputeButton}>
                    {invoice ? "Télécharger la facture": "Facture indisponible"}
                </Button>
                <Button disableElevation variant={"contained"} color={"primary"} size={"small"} onClick={() => history.push("/message/nouveau", {shopId: user.shop.id, recipient: purchase.user})}>
                    Contacter l'acheteur
                </Button>
            </Grid>
        </Box>;
    };

    let getProductsByShop = (purchase) => {
        let shops = {};
        for (const purchaseProduct of purchase.purchaseProducts) {
            if (purchaseProduct.product.shop.id === user.shop.id) {
                if (!shops[purchaseProduct.product.shop.id]) {
                    shops[purchaseProduct.product.shop.id] = {
                        "shop": purchaseProduct.product.shop,
                        "products": []
                    }
                }
                if ((tab === 0 && (purchaseProduct.status === 0 || purchaseProduct.status === 2)) || (tab === 1 && (purchaseProduct.status === 1 || purchaseProduct.status === 3 || purchaseProduct.status === 5))) {
                    shops[purchaseProduct.product.shop.id].products.push(purchaseProduct);
                }
            }
        }

        let filteredShops = [];
        for (const key of Object.keys(shops)) {
            if (shops[key].products.length > 0) {
                filteredShops.push(shops[key]);
            }
        }
        return filteredShops;
    };

    let clickHeader = (purchase) => {
        let index = closedPurchases.indexOf(purchase.id);
        if (index !== -1) {
            setClosedPurchases([...closedPurchases.slice(0, index), ...closedPurchases.slice(index + 1)]);
        } else {
            setClosedPurchases([...closedPurchases, purchase.id]);
        }
    }

    let _getPurchase = (purchase) => {
        let productsByShops = getProductsByShop(purchase);
        if (productsByShops.length > 0) {
            return <Box className={Styles.purchaseWrapper}>
                <Button onClick={() => clickHeader(purchase)} className={Styles.purchaseHeader}>
                    <Text className={Styles.purchaseHeaderLabel1}><FontAwesome name="chevron-down" className={Styles.purchaseHeaderCaret}/> {purchase.id}</Text>
                    <Text className={Styles.purchaseHeaderLabel2}>{Moment(purchase.payedAt).format("DD/MM/YY")}</Text>
                </Button>
                {closedPurchases.indexOf(purchase.id) === -1 &&
                <Box className={Styles.productsWrapper}>
                    {productsByShops && productsByShops.map((shop, index) => _getPurchaseShop(purchase, shop, index === (productsByShops.length - 1)))}
                </Box>}
            </Box>
        }
    };

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title} style={{marginBottom: Constants.GET_SIZE(50)}}>Mes transactions</Text>

            <Box className={Styles.contentWrapper}>
                <Hidden xsDown>
                    <LeftMenu currentPage={"MY_SALES"} />
                </Hidden>
                <Box style={{width: "100%"}}>
                    <Grid container justify={"center"} alignItems={"center"} className={Styles.totalSumWrapper}>
                        <Text className={Styles.totalSumLabel}>Somme d'argent gagnée depuis la création de mon compte :&nbsp;</Text>
                        <Text className={Styles.totalSumValue}>{user && user.shop && user.shop.totalMoney} €</Text>
                    </Grid>
                    {_getTabs()}
                    <Box className={Styles.contentHeaderMobile}>
                        <Select variant={"outlined"} className={Styles.picker} value={sort} onChange={(event) => handlePressSort(event.target.value)}>
                            <MenuItem value={0}>Trier par n° de commande</MenuItem>
                            <MenuItem value={1}>Trier par date de paiement</MenuItem>
                        </Select>
                    </Box>
                    <Box className={Styles.purchasesWrapper}>
                        <Box className={Styles.contentHeader}>
                            <TouchableOpacity className={Styles.sortableWrapper} onPress={() => handlePressSort(0)}>
                                {sort === 0 && <FontAwesome name={sortDir === 0 ? "chevron-down" : "chevron-up"} className={Styles.sortableChevron} />}
                                <Text className={clsx(Styles.contentHeaderLabel, Styles.contentHeaderLabelSortable)}>Commande n°</Text>
                            </TouchableOpacity>
                            <TouchableOpacity className={Styles.sortableWrapper} onPress={() => handlePressSort(1)}>
                                {sort === 1 && <FontAwesome name={sortDir === 0 ? "chevron-down" : "chevron-up"} className={Styles.sortableChevron} />}
                                <Text className={clsx(Styles.contentHeaderLabel, Styles.contentHeaderLabelSortable)}>Date de paiement</Text>
                            </TouchableOpacity>
                            <Text className={Styles.contentHeaderLabel}> Adresses </Text>
                            <Text className={Styles.contentHeaderLabel}>Informations de livraison</Text>
                        </Box>
                        {purchases.map(_getPurchase)}
                    </Box>
                </Box>
            </Box>
            {pagination && <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE}/>}
            <ConfirmationModal
                visible={returnConfirmationModalVisible}
                setVisible={setReturnConfirmationModalVisible}
                title={"Confirmation de la reception"}
                message={"Votre produit peut être retrouvé dans l'historique des ventes."}
                onConfirm={() => {setReturnConfirmationModalVisible(false)}} />
            <ConfirmationModal
                visible={codeValidationModalVisible}
                setVisible={setCodeValidationModalVisible}
                title={""}
                message={"La transaction a été finalisée. Vous allez recevoir votre paiement dans les plus brefs délais."}
                onConfirm={() => {setCodeValidationModalVisible(false)}} />
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        purchases: state.purchase.purchasesList,
        validateError: state.purchase.validateError,
        validateStatus: state.purchase.validateStatus,
        validateStatusType: state.purchase.validateStatusType,
        pagination: state.purchase.purchasesListPagination
    }
};

const mapDispatchToProps = dispatch => {
    return {
        validateDelivery: (purchase, purchaseProduct, code) => {
            dispatch({type: 'VALIDATE_DELIVERY_REQUESTED', purchaseId: purchase.id, id: purchaseProduct.id, code})
        },
        fetchSales: (page, itemsPerPage, sort, sortDir, tab) => {
            dispatch({type: 'FETCH_SALES_REQUESTED', page, itemsPerPage, sort, sortDir, tab})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
