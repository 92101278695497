import React from "react";
import clsx from 'clsx';
import { TouchableOpacity } from "react-native-web";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withWidth, isWidthUp } from "@material-ui/core";

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Rating from '@material-ui/lab/Rating';
import Collapse from "@material-ui/core/Collapse";

import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

import Text from "../Component/FontText"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/DashboardStyle"
import * as Constants from "../../Style/Constants"
import Checkbox from "../Component/CustomCheckbox"
import {InternalLink} from "../Component/InternalLink";
import Moment from "moment"
import grayBlocksLinks from '../../Config/ConnectedMenuLinks'
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";
import SocialShareBarStylesFunc from "../../Style/SocialShareBarStyle";
import MailLogo from "../../Assets/Images/mail_logo.svg";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import MessengerLogo from "../../Assets/Images/facebook_logo.svg";
import LinkedinLogo from "../../Assets/Images/linkedin_logo.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

function Component({navigation, token, user, events, logout, fetchEvents, subscribeToEvent, saveShop, width, startLoading, stopLoading}) {
    let SocialShareBarStyles = SocialShareBarStylesFunc();
    let Styles = StylesFunc();
    let theme = useTheme();
    let [displayEvents, setDisplayEvents] = React.useState(true);
    let [videDressingSeller, setVideDressingSeller] = React.useState(user && user.shop ? user.shop.videDressingSeller : false);
    let [openedCategory, setOpenedCategory] = React.useState(null);
    let [nextLive, setNextLive] = React.useState(null);


    React.useEffect(() => {
        fetchEvents();
    }, [token]);

    React.useEffect(() => {
        if (user && user.shop) {
            setVideDressingSeller(user.shop.videDressingSeller);
            // /lives/my_current

            startLoading();
            setNextLive(null);
            axios.get(
                getApiUrl('lives/my_next'),
                {headers: setDefaultHeaders()}
            ).then((response) => {
                if (response.data && response.data['hydra:member'] && response.data['hydra:member'].length > 0) {
                    setNextLive(response.data['hydra:member'][0]);
                }
                stopLoading();
            }).catch((error) => {
                stopLoading();
            });
        }

    }, [user]);

    let handleClickOnEvent = (event) => {
        subscribeToEvent(event, !event.isSubscribed);
    };

    let handleClickVideDressingSeller = () => {
        if (user && user.shop) {
            saveShop({
                id: user.shop.id,
                videDressingSeller: !videDressingSeller
            }, null);
            setVideDressingSeller(!videDressingSeller);
        } else {
            // Display popup
        }
    };

    let _getGrayBlockLinks = (grayBlock) => {
        let result = [];

        for (const link of grayBlock.links) {
            if (link.type === "link") {
                if (link.link === "MESSAGE_THREADS" && user && user.nbUnreadMessages > 0) {
                    result.push(
                        <div style={{position: "relative"}}>
                            <InternalLink
                                key={"key-" + link.label}
                                center={!isWidthUp('sm', width)}
                                linkStyle={{textDecoration: "none"}}
                                label={link.label}
                                link={link.link}
                                labelClass={Styles.grayCardLinkLabel}
                            />
                            <span className={Styles.messagesNotification}>{user.nbUnreadMessages}</span>
                        </div>
                    )
                } else {
                    result.push(
                        <InternalLink
                            key={"key-" + link.label}
                            center={!isWidthUp('sm', width)}
                            linkStyle={{textDecoration: "none"}}
                            label={link.label}
                            link={link.link}
                            labelClass={Styles.grayCardLinkLabel}
                        />
                    )
                }
            } else if (link.type === "csv") {
                result.push(
                    <Link
                        key={"key-" + link.label}
                        href={link.link}
                        target={"_blank"}
                        style={{width: "max-content", display: "block", margin: !isWidthUp('sm', width) ? "auto" : "initial", cursor:"pointer"}}>
                        <Text className={Styles.grayCardLinkLabel}>
                            {link.label}
                        </Text>
                    </Link>
                );
            } else {
                result.push(
                    <Link
                        key={"key-" + link.label}
                        onClick={link.action === "logout" ? logout : null}
                        style={{width: "max-content", display: "block", margin: !isWidthUp('sm', width) ? "auto" : "initial", cursor:"pointer"}}>
                        <Text className={Styles.grayCardLinkLabel}>
                            {link.label}
                        </Text>
                    </Link>
                )
            }
        }

        return result;
    };

    let _getGrayBlocks = () => {
        let result = [];

        for (let i = 0; i < grayBlocksLinks.length; i++) {
            if (!grayBlocksLinks[i].admin || (user && user.roles.indexOf('ROLE_ADMIN') !== -1)) {
                result.push(
                    <Grid item key={"key-" + grayBlocksLinks[i].label} className={clsx(Styles.grayCard, {[Styles.grayCardAdmin]: grayBlocksLinks[i].admin})}>
                        <Button onClick={() => openedCategory === grayBlocksLinks[i].label ? setOpenedCategory(null) : setOpenedCategory(grayBlocksLinks[i].label)} className={Styles.mobileOnly}>
                            <Text className={Styles.grayCardTitle}>{grayBlocksLinks[i].label}</Text>
                            <KeyboardArrowDown className={clsx(Styles.categoryCaret, {[Styles.categoryCaretOpen]: openedCategory === grayBlocksLinks[i].label})}/>
                        </Button>
                        <Text className={clsx(Styles.grayCardTitle, Styles.desktopOnly)}>{grayBlocksLinks[i].label}</Text>
                        <Collapse in={openedCategory === grayBlocksLinks[i].label || isWidthUp('sm', width)}>
                            {_getGrayBlockLinks(grayBlocksLinks[i])}
                        </Collapse>
                    </Grid>
                )
            }
        }

        return <Grid container xs={12} justify={"center"} style={{maxWidth: 900}}>{result}</Grid>
    };

    // if (!user) {
    //     return null;
    // }

    let getTimeUntilNextLive = () => {
        if (nextLive) {
            const now = Moment();
            const startAt = Moment(nextLive.startAt);
            const diff = Moment.duration(startAt.diff(now));

            const nbDays = Math.floor(diff.asDays());
            const nbHours = diff.hours();
            const nbMinutes = diff.minutes();
            let result = ['Dans'];
            if (nbDays > 0) {
                result.push(nbDays + ' jour' + (nbDays > 1 ? 's' : ''));
            }
            if (nbHours > 0 || (nbDays > 0 && nbMinutes > 0)) {
                result.push(nbHours + ' heure' + (nbHours > 1 ? 's' : ''));
            }
            if (nbMinutes > 0) {
                result.push(nbMinutes + ' minute' + (nbMinutes > 1 ? 's' : ''));
            }
            return result.join(' ');
        }
        return null;
    }

    let handleClickShare = (method) => {
        let url = window.location.hostname + '/voir_live/' + nextLive.id;
        if (!method) {
            navigator.share({
                title: 'Violette Sauvage - Live',
                text: 'Venez assister au live de ma boutique',
                url: url,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            if (method === 'mail') {
                let mailto = 'mailto:?subject=' + encodeURIComponent('Violette Sauvage - Live') + '&body=' + encodeURIComponent('Venez assister au live de ma boutique : ' + url);
                window.open(mailto);
            } else {
                let shareUrl = '';
                if (method === 'fb') {
                    shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);
                } else if (method === 'linkedin') {
                    shareUrl = "https://www.linkedin.com/shareArticle?url=" + encodeURIComponent(url);
                }

                if (shareUrl) {
                    window.open(shareUrl, '_blank');
                }
            }
        }
    }

    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    let displayShareButton = mobileScreen && !!navigator.share;

    const _getPageContent = () => {
        return <Box className={Styles.container}>
            <Text className={Styles.title}>Mon tableau de bord</Text>
            <Text className={Styles.welcome}>Bonjour {user.firstname} {user.name}</Text>
            <Box className={Styles.descriptionWrapper}>
                <Text className={Styles.description}>Ceci est ton espace personnel. Tu peux modifier les paramètres de ton compte,</Text>
                <Text className={Styles.description2}>suivre tes commandes, mettre en vente un article et gérer tes ventes, répondre à un message.</Text>
            </Box>
            {user && user.status === 1 && <Box className={Styles.descriptionWrapper}>
                <Text className={Styles.blockageReason}>Votre compte a été bloqué pour la raison suivante :</Text>
                <Text className={Styles.blockageReason}>"{user.blockReason}"</Text>
            </Box>}

            <Grid container xs={12} className={clsx(Styles.firstRow)}>
                <Grid xs={12} sm={6} className={Styles.memberSinceWrapper}>
                    <Text className={Styles.memberSinceTitle}>Membre depuis le</Text>
                    <Text className={Styles.memberSinceValue}>{Moment(user.createdAt).format("DD/MM/YYYY")}</Text>
                </Grid>
                {/* Display only if shop ? */}
                <Grid xs={12} sm={6} className={Styles.noteWrapper}>
                    <Text className={Styles.noteTitle}>Ta note vendeur</Text>
                    <Rating value={user && user.shop ? user.shop.note : 0} readOnly />
                </Grid>
            </Grid>
            {/* Display only if shop ? */}
            {user.shop && <Grid container xs={12} className={clsx(Styles.row, Styles.secondRow)}>
                <Grid xs={12} sm={6} container className={Styles.clickAndCollectWrapper}>
                    <Checkbox
                        size={"large"}
                        checked={videDressingSeller}
                        onPress={handleClickVideDressingSeller}
                        title={<Text className={Styles.clickAndCollectLabel}>Vendeur au vide-dressing (en cochant la case, tu actives le Click and Collect)</Text>}
                    />
                </Grid>
                {videDressingSeller &&
                <Grid xs={12} sm={6}>
                    <Box className={Styles.deliveryModesSuperWrapper}>
                        <Box className={clsx(Styles.deliveryModesWrapper)}>
                            <Link onClick={() => setDisplayEvents(!displayEvents)} style={{width: "100%", cursor: "pointer", color: "black"}}>
                                <Grid container xs={12} justify={"space-between"} className={Styles.deliveryModesTitleWrapper}>
                                    <Text className={clsx(Styles.deliveryModesTitle)}>Date du vide dressing</Text>
                                    <KeyboardArrowDown className={clsx(Styles.deliveryModeCaret, {[Styles.deliveryModeCaretOpen]: displayEvents})}/>
                                </Grid>
                            </Link>
                            {events && <Collapse in={displayEvents} className={Styles.deliveryModesList}>
                                <Box className={Styles.deliveryModesListContent}>
                                    {events.map((event) =>
                                        <Checkbox
                                            key={"checkbox-" + event.id}
                                            rightIcon
                                            size={"small"}
                                            checked={event.isSubscribed}
                                            onPress={() => handleClickOnEvent(event)}
                                            title={<Text className={Styles.videDressingLabel}>{event.city} le {Moment(event.datetime).format("DD/MM/YY")}</Text>}
                                        />
                                    )}
                                </Box>
                            </Collapse>}
                        </Box>
                        <Box className={clsx(Styles.upwardTriangleBorder)} />
                        <Box className={Styles.upwardTriangle} />
                    </Box>
                </Grid>}
            </Grid>}

            {nextLive && <Grid container className={Styles.currentLiveBlock} justify={"center"} alignItems={"center"} direction={"column"}>
                <Text className={Styles.currentLiveTitle}>Prochain live</Text>
                <Text className={Styles.currentLiveTime}>{nextLive.streaming ? 'En cours' : getTimeUntilNextLive()}</Text>
                {nextLive.streaming ? <Button disableElevation variant={"contained"} color={"primary"} href={"/voir_live/" + nextLive.id} size={"small"}>
                    Participer au live
                </Button> : <Button disableElevation variant={"contained"} color={"secondary"} href={"/editer_live/" + nextLive.id} size={"small"}>
                    Modifier le live
                </Button>}
                {displayShareButton ? <Button disableElevation variant={"contained"} color={"primary"} onClick={() => handleClickShare()} size={"small"} style={{marginTop: 10}}>
                    Partager mon live
                </Button> : null}
                {!displayShareButton ? <Grid style={{marginTop: 10}}>
                    <Button className={SocialShareBarStyles.iconWrapper} onClick={() => handleClickShare('fb')} style={{marginRight: 10}}>
                        <CardMedia component={"img"} src={MessengerLogo} className={SocialShareBarStyles.messengerIcon}/>
                    </Button>
                    <Button className={SocialShareBarStyles.iconWrapper} onClick={() => handleClickShare('linkedin')} style={{marginRight: 10}}>
                        <CardMedia component={"img"} src={LinkedinLogo} className={SocialShareBarStyles.linkedinIcon}/>
                    </Button>
                    <Button className={SocialShareBarStyles.iconWrapper} onClick={() => handleClickShare('mail')}>
                        <CardMedia component={"img"} src={MailLogo} className={SocialShareBarStyles.mailIcon}/>
                    </Button>
                </Grid> : null}
            </Grid>}
            {user && user.shop && user.shop.liveToken && <Box className={Styles.descriptionWrapper}>
                <Text className={Styles.description}>Paramètres de connexion au live</Text>
                <Text className={Styles.description}>Service : Personnalisé</Text>
                <Text className={Styles.description}>Serveur : rtmp://violettesauvage-live.cofondateur.fr:1935/live</Text>
                <Text className={Styles.description}>Clé de stream : {user.shop.liveToken}</Text>
            </Box>}

            {_getGrayBlocks()}
        </Box>
    }
    return <Base content={user ? _getPageContent() : null} />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        events: state.videDressing.videDressingsList,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch({type: 'LOGOUT'})
        },
        fetchEvents: () => {
            dispatch({type: 'FETCH_VIDE_DRESSINGS_REQUESTED'})
        },
        subscribeToEvent: (event, subscribe) => {
            dispatch({type: 'SUBSCRIBE_TO_VIDE_DRESSING_REQUESTED', id: event.id, subscribe})
        },
        saveShop: (shop, picture) => {
            dispatch({type: 'SAVE_SHOP_REQUESTED', shop, picture, hideLoader: true})
        },
        startLoading: () => {
            dispatch({type: 'START_LOADING_REQUESTED'})
        },
        stopLoading: () => {
            dispatch({type: 'START_LOADING_SUCCEEDED'})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default withWidth()(VisibleComponent)
