import React from "react";
import clsx from 'clsx';
import Image from "react-native-web/dist/exports/Image";
import { connect } from 'react-redux';
import { withRouter, useParams } from "react-router-dom";
import { FontAwesome } from '../../FontAwesome';
import axios from 'axios';
import { useStripe } from '@stripe/react-stripe-js';
import { KeyboardDatePicker as DatePicker } from '@material-ui/pickers';
import Moment from 'moment';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MyShopStyle"
import * as Constants from "../../Style/Constants"
import TextInputIcon from "../Front/Component/TextInputIcon";
import {useForm} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"
import Checkbox from "../Component/CustomCheckbox"
import ErrorStyleFunc from "../Styles/Error";
import {getImageUrl, pickImage, dataURLtoFile, imageURLtoFile} from "../../Utils/ImageUtils";
import UserIcon from "../../Assets/Images/user_icon.svg"
import {InternalLink, LINKS} from "../Component/InternalLink";
import IBAN from "iban";
import {getApiUrl} from "../../Utils/QueryUtils";
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import {STRIPE_PUBLIC_KEY} from "../../Config/API";

function Component({navigation, history, screenKey, token, user, previewShop, saveShop, saveShopErrors, isSavingShop, savePreviewShop, resetPreviewShop, fetchMe, startLoading, stopLoading}) {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { clearErrors, setValue, setError, handleSubmit, register, errors, watch } = useForm({defaultValues: {city: ''}, shouldFocusError: true});
    let params = useParams();
    let defaultPage = 1;
    if (screenKey === "CreateMyShop" && params.page) {
        defaultPage = params.page;
    } else if (screenKey === "MyBankInfo") {
        defaultPage = 4;
    } else if (user && user.shop) {
        defaultPage = 3;
    }
    let [page, setPage] = React.useState(defaultPage);
    let familyRef = React.useRef(null);

    // Step 1
    let [type, setType] = React.useState(previewShop ? previewShop.type : null);

    // Step 2
    let [SIRET, setSIRET] = React.useState(previewShop && previewShop.SIRET ? previewShop.SIRET : "");
    let [cgv, setCgv] = React.useState(false);
    let [cgu, setCgu] = React.useState(false);

    // Step 3
    let [name, setName] = React.useState(previewShop && previewShop.name ? previewShop.name : user && user.shop ? user.shop.name : "");
    let [description, setDescription] = React.useState(user && user.shop ? user.shop.description : "");
    let [address, setAddress] = React.useState(user && user.shop ? user.shop.facturationAddress : "");
    let [zipcode, setZipcode] = React.useState(user && user.shop ? user.shop.facturationZipCode : "");
    let [city, setCity] = React.useState(user && user.shop ? user.shop.facturationCity : "");
    let [email, setEmail] = React.useState(user && user.shop ? user.shop.email : "");
    let [phone, setPhone] = React.useState(user && user.shop ? user.shop.phone : "");
    let [dob, setDob] = React.useState(user && user.shop && user.shop.birthdate ? new Date(user.shop.birthdate) : previewShop && previewShop.birthdate ? previewShop.birthdate : null);
    let [idCard1, setIdCard1] = React.useState(user && user.shop ? user.shop.idCard1 : previewShop && previewShop.idCard1 ? previewShop.idCard1 : null);
    let [idCard2, setIdCard2] = React.useState(user && user.shop ? user.shop.idCard2 : previewShop && previewShop.idCard2 ? previewShop.idCard2 : null);
    let [addressValidation, setAddressValidation] = React.useState(user && user.shop ? user.shop.addressValidation : previewShop && previewShop.addressValidation ? previewShop.addressValidation : null);
    let [facebook, setFacebook] = React.useState(user && user.shop ? user.shop.socialNetworks.facebook : "");
    let [instagram, setInstagram] = React.useState(user && user.shop ? user.shop.socialNetworks.instagram : "");
    let [twitter, setTwitter] = React.useState(user && user.shop ? user.shop.socialNetworks.twitter : "");
    let defaultOtherSocialNetworks = [];
    if (user && user.shop) {
        for (const snType in user.shop.socialNetworks) {
            if (snType.indexOf("other_") === 0) {
                defaultOtherSocialNetworks.push(user.shop.socialNetworks[snType]);
            }
        }
    }
    let [otherSocialNetworks, setOtherSocialNetworks] = React.useState(defaultOtherSocialNetworks);
    let [picture, setPicture] = React.useState(null);

    // Step 4
    let [bankName, setBankName] = React.useState(user && user.shop ? user.shop.bank : "");
    let [bankAgency, setBankAgency] = React.useState(user && user.shop ? user.shop.bankAgency : "");
    let [bankAddress, setBankAddress] = React.useState(user && user.shop ? user.shop.bankAddress : "");
    let [bankZipcode, setBankZipcode] = React.useState(user && user.shop ? user.shop.bankZipCode : "");
    let [bankCity, setBankCity] = React.useState(user && user.shop ? user.shop.bankCity : "");
    let [bankAccountOwner, setBankAccountOwner] = React.useState(user && user.shop ? user.shop.bankAccountOwner : "");
    let [bankAccountIBAN, setBankAccountIBAN] = React.useState(user && user.shop ? user.shop.bankAccountIBAN : "");
    let [bankAccountBIC, setBankAccountBIC] = React.useState(user && user.shop ? user.shop.bankAccountBIC : "");

    let [handledStripeDocuments, setHandledStripeDocuments] = React.useState(false);

    let [ modalVisible, setModalVisible ] = React.useState(false);
    let [ saveStarted, setSaveStarted ] = React.useState(false);

    let [ cityAutocomplete, setCityAutocomplete] = React.useState([]);
    let [ allowAutocomplete, setAllowAutocomplete] = React.useState(false);

    const stateSetters = {
        type: setType,
        SIRET: setSIRET,
        name: setName,
        description: setDescription,
        address: setAddress,
        zipcode: setZipcode,
        city: setCity,
        email: setEmail,
        phone: setPhone,
        dob: setDob,
        facebook: setFacebook,
        instagram: setInstagram,
        twitter: setTwitter,
        otherSocialNetworks: setOtherSocialNetworks,
        picture: setPicture,
        bankName: setBankName,
        bankAgency: setBankAgency,
        bankAddress: setBankAddress,
        bankZipcode: setBankZipcode,
        bankCity: setBankCity,
        bankAccountOwner: setBankAccountOwner,
        bankAccountIBAN: setBankAccountIBAN,
        bankAccountBIC: setBankAccountBIC
    };

    const stripe = useStripe();

    React.useEffect(() => {
        if (screenKey === "CreateMyShop") {
            if (params.page) {
                setPage(parseInt(params.page));
            } else {
                setPage(1);
            }
        }
    }, [params]);

    let enteredZipcode = watch('zipcode', zipcode);

    React.useEffect(() => {
        setCityAutocomplete([]);
        if (allowAutocomplete && enteredZipcode.length === 5) {
            axios.get(getApiUrl('shop/get_city_from_zipcode', {zipcode: enteredZipcode})).then((response) => {
                if (response && response.data) {
                    if (response.data.length >= 1) {
                        setCityAutocomplete(response.data);
                    }
                }
            }).catch((error) => {

            });
        }
    }, [enteredZipcode, allowAutocomplete]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    React.useEffect(() => {
        loadShop();
    }, [user]);

    React.useEffect(() => {
        fetchMe();
    }, []);


    React.useEffect(() => {
        if (errors.picture) {
            familyRef.current.focus();
        }
    }, [errors]);

    React.useEffect(() => {
        if (saveShopErrors) {
            for (let i = 0; i < saveShopErrors.length; i++) {
                if (saveShopErrors[i]["message"].indexOf("You must use a test bank account") !== -1) {
                    setError("bankAccountIBAN", {type: "custom", message: "Vous ne pouvez pas utiliser un IBAN réel sur le serveur de test"});
                } else if (saveShopErrors[i]["propertyPath"] === "address_whole") {
                    setError("address", {type: "custom"});
                    setError("zipcode", {type: "custom"});
                    setError("city", {type: "custom", message: saveShopErrors[i]["message"]});
                } else {
                    setError(saveShopErrors[i]["propertyPath"], {type: "custom", message: saveShopErrors[i]["message"]});
                }
            }
        }
    }, [saveShopErrors]);

    React.useEffect(() => {
        if (saveStarted && !isSavingShop) {
            setSaveStarted(false);
            if ((!saveShopErrors || saveShopErrors.length === 0) && screenKey !== "CreateMyShop") {
                setModalVisible(true);
            }
        }
    }, [isSavingShop]);

    let handleSavePreviewShop = (data) => {
        let newPreviewShop = Object.assign({}, previewShop, data, {type, dob, picture, city: data.city ? data.city : city});
        savePreviewShop(newPreviewShop)
    };

    let loadShop = () => {
        if (user) {
            if (user.shop) {
                // Global info
                setType(user.shop.type);
                // Shop info
                if (user.shop.birthdate) {
                    const birthdate = new Date(user.shop.birthdate);
                    birthdate.setHours(0);
                }
                setValue("name", user.shop.name);
                setValue("description", user.shop.description);
                setValue("address", user.shop.facturationAddress);
                setValue("zipcode", user.shop.facturationZipCode);
                setValue("city", user.shop.facturationCity);
                setValue("email", user.shop.email);
                setValue("phone", user.shop.phone);
                let osn = [];
                for (const sn in user.shop.socialNetworks) {
                    switch (sn) {
                        case "instagram":
                            setValue("instagram", user.shop.socialNetworks[sn]);
                            // setInstagram(user.shop.socialNetworks[sn]);
                            break;
                        case "facebook":
                            setValue("facebook", user.shop.socialNetworks[sn]);
                            // setFacebook(user.shop.socialNetworks[sn]);
                            break;
                        case "twitter":
                            setValue("twitter", user.shop.socialNetworks[sn]);
                            // setTwitter(user.shop.socialNetworks[sn]);
                            break;
                        default:
                            if (user.shop.socialNetworks[sn] !== "") {
                                osn.push(user.shop.socialNetworks[sn]);
                            }
                    }
                }
                setOtherSocialNetworks(osn);

                // Bank info
                setValue("bankName", user.shop.bank);
                setValue("bankAgency", user.shop.bankAgency);
                setValue("bankAddress", user.shop.bankAddress);
                setValue("bankZipcode", user.shop.bankZipCode);
                setValue("bankCity", user.shop.bankCity);
                setValue("bankAccountOwner", user.shop.bankAccountOwner);
                setValue("bankAccountIBAN", user.shop.bankAccountIBAN);
                setValue("bankAccountBIC", user.shop.bankAccountBIC);

                if (screenKey === "MyShop") {
                    setPage(3);
                } else if (screenKey === "MyBankInfo") {
                    setPage(4);
                } else if (screenKey === "CreateMyShop") {
                    setPage(5);
                }
            } else {
                if (!user.name || !user.firstname) {
                    history.push("/mes_informations?fromShop=1");
                } else if (screenKey !== "CreateMyShop") {
                    history.push("/creer_ma_boutique");
                } else {
                    if (user.facturationSameAsShipping) {
                        setValue("address", user.shippingAddress);
                        setValue("zipcode", user.shippingZipCode);
                        setValue("city", user.shippingCity);
                    } else {
                        setValue("address", user.facturationAddress);
                        setValue("zipcode", user.facturationZipCode);
                        setValue("city", user.facturationCity);
                    }
                    setValue("phone", user.phone);
                    setValue("email", user.email);

                    if (previewShop) {
                        for (const key of Object.keys(previewShop)) {
                            if (previewShop[key] !== null && previewShop[key] !== '') {
                                setValue(key, previewShop[key]);
                                if (key in stateSetters) {
                                    stateSetters[key](previewShop[key]);
                                }
                            }
                        }
                    }
                }
            }

        }
    };

    React.useEffect(() => {
        loadShop();
    }, [previewShop, page]);

    React.useEffect(() => {
        if (cgu) {
            clearErrors(['cgu']);
        }
        if (cgv) {
            clearErrors(['cgv']);
        }
    }, [cgu, cgv]);

    let onPickImage = (e) => {
        if (e.target.files && e.target.files[0]) {
            clearErrors(["picture"]);
            imageURLtoFile(e.target.files[0], setPicture);
        }
    };


    let onPickImageError = (error) => {

    };

    let onPickIdCard = (e) => {
        // setIdCard1(result.uri);
        if (e.target.files && e.target.files[0]) {
            imageURLtoFile(e.target.files[0], setIdCard1);
        }
    };

    let onPickIdCardError = (error) => {
        console.log(error);
    };

    let onPickIdCard2 = (e) => {
        if (e.target.files && e.target.files[0]) {
            imageURLtoFile(e.target.files[0], setIdCard2);
        }
    };

    let onPickIdCard2Error = (error) => {
        console.log(error);
    };

    let onPickAddressValidation = (e) => {
        if (e.target.files && e.target.files[0]) {
            imageURLtoFile(e.target.files[0], setAddressValidation);
        }
    };

    let onPickAddressValidationError = (error) => {
        console.log(error);
    };

    async function uploadStripeFile(file, name) {
        const data = new FormData();
        data.append('file', dataURLtoFile(file, name + ".jpg"));
        data.append('purpose', 'identity_document');
        const fileResult = await fetch('https://uploads.stripe.com/v1/files', {
            method: 'POST',
            headers: {'Authorization': 'Bearer ' + STRIPE_PUBLIC_KEY},
            body: data,
        });
        return await fileResult.json();
    }

    async function handleSaveShop(formData) {
        startLoading();
        let data = Object.assign({}, previewShop, user.shop ? user.shop : {}, {type, SIRET, dob, name, description, address, zipcode, city, email, phone, bankName, bankAgency, bankAddress, bankZipcode, bankCity, bankAccountOwner, bankAccountIBAN, bankAccountBIC, picture}, formData)
        delete data.events;
        delete data.picture;
        setSaveStarted(true);
        let osn = {};
        let i = 0;
        for (const index in otherSocialNetworks) {
            if (otherSocialNetworks[index] !== "") {
                osn["other_" + i] = otherSocialNetworks[index];
                i += 1;
            }
        }
        let accountResult = null;
        let personResult = null;

        let fileData = idCard1 ? await uploadStripeFile(idCard1, "idCard1") : null;
        let fileData2 = idCard2 ? await uploadStripeFile(idCard2, "idCard2") : null;
        let fileData3 = addressValidation ? await uploadStripeFile(addressValidation, "addressValidation") : null;

        let accountData = {
            tos_shown_and_accepted: true
        };
        let momentDob = new Date(dob);
        if ((user.shop && (user.shop.type === 0 || user.shop.type === 3)) || type === 0 || type === 3) { // Individual
            accountData.business_type = 'individual';
            accountData.individual = {
                first_name: user.firstname,
                last_name: user.name,
                email: data.email,
                dob: {
                    year: momentDob.getFullYear(),
                    month: momentDob.getMonth() + 1,
                    day: momentDob.getDate()
                },
                phone: '+33' + data.phone.slice(1),
                address: {
                    line1: data.address,
                    city: city ? city : data.city ? data.city : previewShop.city,
                    postal_code: data.zipcode,
                }
            };
            if (fileData || fileData2 || fileData3) {
                accountData.individual.verification = {};
                if (fileData3) {
                    accountData.individual.verification.additional_document = {
                        front: fileData3.id
                    };
                }
                if (fileData || fileData2) {
                    accountData.individual.verification.document = {};
                    if (fileData) {
                        accountData.individual.verification.document.front = fileData.id;
                    }
                    if (fileData2) {
                        accountData.individual.verification.document.back = fileData2.id;
                    }
                }
            }

        } else { // Company
            accountData.business_type = 'company';
            accountData.company = {
                name: name,
                    address: {
                    line1: data.address,
                    city: city ? city : data.city ? data.city : previewShop.city,
                    postal_code: data.zipcode,
                },
                tax_id: data.SIRET,
                owners_provided: true,
                directors_provided: true,
            };

            let personData = {
                person: {
                    first_name: user.firstname,
                    last_name: user.name,
                    dob: {
                        year: momentDob.getUTCFullYear(),
                        month: momentDob.getUTCMonth() + 1,
                        day: momentDob.getUTCDate() + 1
                    },
                    email: data.email,
                    phone: '+33' + data.phone.slice(1),
                    address: {
                        line1: data.address,
                        city: city ? city : data.city ? data.city : previewShop.city,
                        postal_code: data.zipcode,
                    },
                    relationship: {
                        representative: true,
                        owner: true,
                        director: true
                    }
                },
            };
            if (fileData || fileData2 || fileData3) {
                personData.verification = {};
                if (fileData3) {
                    personData.verification.additional_document = {
                        front: fileData3.id
                    };
                }
                if (fileData || fileData2) {
                    personData.verification.document = {};
                    if (fileData) {
                        personData.verification.document.front = fileData.id;
                    }
                    if (fileData2) {
                        personData.verification.document.back = fileData2.id;
                    }
                }
            }
            personResult = await stripe.createToken('person', personData);
        }

        accountResult = await stripe.createToken('account', accountData);

        let birthdate = data.dob ? Moment(data.dob).set('hour', 12).toDate() : null;
        console.log(data.dob, birthdate);
        let newShop = {
            id: user.shop ? user.shop.id : null,
            stripeAccountToken: accountResult && accountResult.token ? accountResult.token.id : null,
            stripePersonToken: personResult && personResult.token ? personResult.token.id : null,
            bank: data.bankName,
            bankZipCode: data.bankZipcode,
            ...data,
            birthdate: birthdate,
            socialNetworks: {"facebook": data.facebook, "instagram": data.instagram, "twitter": data.twitter, ...osn},
            facturationAddress: data.address,
            facturationZipCode: data.zipcode,
            facturationCity: city ? city : data.city ? data.city : previewShop.city,
        };
        saveShop(newShop, picture ? dataURLtoFile(picture, "shop-" + name.replace(/[^A-Z0-9]/ig, "_") + "-image") : null);
        setHandledStripeDocuments(true);
        stopLoading();
    }

    let handleClickContinue = (data) => {
        switch(page) {
            case 1:
                if (type === null) {
                    setError("type", {type: "mandatory", message: "Vous devez choisir un type de boutique"})
                } else {
                    handleSavePreviewShop(data);
                    history.push("/creer_ma_boutique/2");
                }
                break;
            case 2:
                let validSiret = (type !== 1 && type !== 2 && !data.SIRET) || validateSiret(data.SIRET);
                if (cgv && cgu && validSiret) {
                    handleSavePreviewShop(data);
                    history.push("/creer_ma_boutique/3");
                } else {
                    if (!cgv) {
                        setError("cgv", {type: "mandatory", message: "Vous devez accepter ces conditions pour continuer"})
                    }
                    if (!cgu) {
                        setError("cgu", {type: "mandatory", message: "Vous devez accepter ces conditions pour continuer"})
                    }
                    if (!validSiret) {
                        setError("SIRET", {type: "invalid", message: "Le format du SIRET est invalide"})
                    }
                }
                break;
            case 3:
                if (user.shop && user.shop.id) {
                    if (!dob || dob === null) {
                        setError("dob", {type: "invalid", message: "Ce champ est obligatoire"})
                    } else {
                        startLoading();
                        axios.get(getApiUrl('shop/validate_address', {city: data.city ? data.city : city, zipcode: data.zipcode, address: data.address})).then((response) => {
                            handleSaveShop(data)
                            stopLoading();
                        }).catch((error) => {
                            setError("address", {type: "invalid"});
                            setError("zipcode", {type: "invalid"});
                            setError("city", {type: "invalid", message: "L'adresse est invalide"});
                            stopLoading();
                        });
                    }
                } else {
                    let error = false;
                    if (type !== 0 && !picture) {
                        setError("picture", {type: "invalid", message: "Ce champ est obligatoire"})
                        error = true;
                    }
                    if (!dob || dob === null) {
                        setError("dob", {type: "invalid", message: "Ce champ est obligatoire"})
                        error = true;
                    }

                    if (!error) {
                        startLoading();
                        axios.get(getApiUrl('shop/validate_address', {city: data.city ? data.city : city, zipcode: data.zipcode, address: data.address})).then((response) => {
                            handleSavePreviewShop(data);
                            history.push("/creer_ma_boutique/4");
                            stopLoading();
                        }).catch((error) => {
                            setError("address", {type: "invalid"});
                            setError("zipcode", {type: "invalid"});
                            setError("city", {type: "invalid", message: "L'adresse est invalide"});
                            stopLoading();
                        });
                    }
                }
                break;
            case 4:
                let validIBAN = IBAN.isValid(data.bankAccountIBAN) || data.bankAccountIBAN === "FR89370400440532013000" || data.bankAccountIBAN === '';
                let validBIC = data.bankAccountBIC === '' || /^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(data.bankAccountBIC.toUpperCase());
                if (!validIBAN) {
                    setError("bankAccountIBAN", {type: "invalid", message: "Le format de l'IBAN est invalide"})
                }
                if (!validBIC) {
                    setError("bankAccountBIC", {type: "invalid", message: "Le format du BIC est invalide"})
                }
                if (validIBAN && validBIC) {
                    startLoading();
                    axios.get(getApiUrl('shop/validate_address', {city: data.bankCity, zipcode: data.bankZipcode, address: data.bankAddress})).then((response) => {
                        handleSaveShop(data);
                        stopLoading();
                    }).catch((error) => {
                        setError("bankAddress", {type: "invalid"});
                        setError("bankZipcode", {type: "invalid"});
                        setError("bankCity", {type: "invalid", message: "L'adresse est invalide"});
                        stopLoading();
                    });
                }
                break;
        }
    };

    let validateSiret = (siret) => {
        let valid;
        if (siret.length !== 14 || isNaN(siret)) {
            valid = false;
        } else {
            // Donc le SIRET est un numérique à 14 chiffres
            // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
            // correspondent au numéro d'établissement
            // et enfin le dernier chiffre est une clef de LUHN.
            let somme = 0;
            for (let i = 0; i < siret.length; i++) {
                if ((i % 2) === 0) { // Les positions impaires : 1er, 3è, 5è, etc...
                    let tmp = parseInt(siret.charAt(i)) * 2; // On le multiplie par 2
                    if (tmp > 9) {
                        tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
                    }
                    somme += tmp;
                } else {
                    somme += parseInt(siret.charAt(i));
                }
            }

            valid = (somme % 10) === 0; // Si la somme est un multiple de 10 alors le SIRET est valide
        }

        return valid;
    };

    let maxBirthdate = new Date();
    maxBirthdate.setFullYear(maxBirthdate.getFullYear() - 15);

    // Debug documents block on shop which are stripe validated
    // if (user && user.shop) {
    //     user.shop.stripeAccountStatus = {
    //         status: "missing_info",
    //         pending_verification: [
    //             "verification.document"
    //         ],
    //         requirements: []
    //
    //     }
    // }
    let _getStripeDocumentsBlock = () => {
        if (user && user.shop && (user.shop.stripeAccountStatus === null || user.shop.stripeAccountStatus.status === "stripe_account_ok")) {
            if (handledStripeDocuments) {
                return <Box style={{marginLeft: Constants.GET_SIZE(50), marginBottom: Constants.GET_SIZE(10)}}>
                    <Text className={Styles.missingDocumentsTitle}>Vos documents ont été enregistrés !</Text>
                </Box>;
            } else {
                return null;
            }
        } else {
            let missingDocumentsDom = [];
            if (user && user.shop && user.shop.stripeAccountStatus.status === 'missing_info' && user.shop.stripeAccountStatus.pending_verification.indexOf("verification.document") !== -1) {
                missingDocumentsDom.push(<Box style={{marginBottom: Constants.GET_SIZE(10)}}>
                    <Text className={Styles.documentButtonLabel}>Carte d'identité en cours de validation</Text>
                </Box>);
            } else if (!user || !user.shop || user.shop.stripeAccountStatus.status === 'no_stripe_account' || user.shop.stripeAccountStatus.requirements.indexOf("verification.document") !== -1) {
                missingDocumentsDom.push(<Box style={{marginBottom: Constants.GET_SIZE(10)}}>
                    <Link component={"label"}>
                        <Text className={Styles.documentButtonLabel}>{idCard1 && <DescriptionIcon />}{!idCard1 ? "Ajouter une" : "Modifier la"} carte
                            d'identité (recto)</Text>
                        <input type={"file"} hidden accept={"image/*"} onChange={(e) => onPickIdCard(e)}/>
                    </Link>
                </Box>);
                missingDocumentsDom.push(<Box style={{marginBottom: Constants.GET_SIZE(10)}}>
                    <Link component={"label"}>
                        <Text className={Styles.documentButtonLabel}>{idCard2 && <DescriptionIcon />}{!idCard2 ? "Ajouter une" : "Modifier la"} carte
                            d'identité (verso)</Text>
                        <input type={"file"} hidden accept={"image/*"} onChange={(e) => onPickIdCard2(e)}/>
                    </Link>
                </Box>);
            }
            if (user && user.shop && user.shop.stripeAccountStatus.status === 'missing_info' && user.shop.stripeAccountStatus.pending_verification.indexOf("verification.additional_document") !== -1) {
                missingDocumentsDom.push(<Box style={{marginBottom: Constants.GET_SIZE(10)}}>
                    <Text className={Styles.documentButtonLabel}>Justificatif de domicile en cours de validation</Text>
                </Box>);
            } else if (!user || !user.shop || user.shop.stripeAccountStatus.status === 'no_stripe_account' || user.shop.stripeAccountStatus.requirements.indexOf("verification.additional_document") !== -1) {
                missingDocumentsDom.push(<Box style={{marginBottom: Constants.GET_SIZE(10)}}>
                    <Link component={"label"}>
                        <Text className={Styles.documentButtonLabel}>
                            {addressValidation && <DescriptionIcon />}{!addressValidation ? "Ajouter un" : "Modifier le"} justificatif de domicile
                        </Text>
                        <input type={"file"} hidden accept={"image/*"} onChange={(e) => onPickAddressValidation(e)}/>
                    </Link>
                </Box>);
            }
            return <Box className={Styles.stripeBlock}>
                <Text className={Styles.missingDocumentsTitle}>Pour pouvoir recevoir vos paiements, vous devez nous fournir les documents suivants :</Text>
                {missingDocumentsDom}
                <Text className={Styles.missingDocumentsTitle}>Notre module de paiement doit valider vos informations. Une fois cela fait, vous pourrez mettre en place le virement que vous souhaitez.</Text>
            </Box>;
        }
    };

    let _getPage2 = () => {
        switch (type) {
            case 3:
                return <Box>
                    <Text className={Styles.step2Title}>Conditions blogueurs :</Text>
                    <Text className={Styles.step2BulletTitle}>Afin d'ouvrir votre boutique dans la catégorie Blogueur, vous devez remplir certaines conditions :</Text>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Charger une photo qui présente votre look : celle-ci est très importante car elle représentera le style de votre boutique</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Être un influenceur professionnel (c’est-à-dire à avoir des partenariats avec des marques, des publications sponsorisées et plus généralement tarifer votre visibilité...)</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Être actif sur les réseaux sociaux (Facebook, Instagram, Snapchat, Twitter et autres)</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Annoncer l’ouverture de votre boutique dans la catégorie “Blogueur” sur vos réseaux sociaux personnels ou professionnels</Text>
                    </Box>
                </Box>;
            case 1:
                return <Box>
                    <Text className={Styles.step2Title}>Conditions Eco-responsable:</Text>
                    <Text className={Styles.step2BulletTitle}>Afin d'ouvrir votre boutique dans la catégorie Eco-responsable, vous devez remplir certaines conditions :</Text>
                    <Text className={Styles.step2SiretLabel}>N° SIRET</Text>
                    <TextInputIcon
                        errors={errors}
                        // setValue={setSiret}
                        register={register}
                        placeholder={"N° SIRET"}
                        rules={{
                            required: "Ce champ est obligatoire"
                        }}
                        name={"SIRET"}
                    />
                    <Text className={Styles.step2BulletTitle}>Si vous êtes un créateur / une marque éco-responsable, vous vous engagez à :</Text>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Utiliser des matières bio, vegan ou de nouvelles matières biodégradables</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Être engagé dans le recyclage et le respect de l’environnement grâce aux matières que vous utilisez et à vos process de production.</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Avoir une démarche éthique et équitable</Text>
                    </Box>
                </Box>;
            case 2:
                return <Box>
                    <Text className={Styles.step2Title}>Conditions créateurs:</Text>
                    <Text className={Styles.step2BulletTitle}>Afin d'ouvrir votre boutique dans la catégorie Créateur, vous devez remplir certaines conditions :</Text>
                    <Text className={Styles.step2SiretLabel}>N° SIRET</Text>
                    <TextInputIcon
                        errors={errors}
                        // setValue={setSiret}
                        register={register}
                        placeholder={"N° SIRET"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"SIRET"}
                        errorClass={{marginBottom: Constants.GET_SIZE(20)}}
                    />
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Proposer des pièces de votre création propre (pas de plagiat, ni de contrefaçons)</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Pour vendre des produits cosmétiques : être en possession d'un brevet d'exploitation et/ou d'une license</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Ne pas vendre des articles alimentaires</Text>
                    </Box>
                </Box>;
            case 0:
                return <Box>
                    <Text className={Styles.step2Title}>Conditions particulier:</Text>
                    <Text className={Styles.step2BulletTitle}>Tes articles d'occasions doivent être en bon état (pas de taches, de trous...)</Text>
                    <Text className={Styles.step2SiretLabel}>Si vous êtes professionnel, ajoutez votre numéro de SIRET</Text>
                    <TextInputIcon
                        errors={errors}
                        // setValue={setSiret}
                        register={register}
                        placeholder={"N° SIRET"}
                        name={"SIRET"}
                        errorClass={{marginBottom: Constants.GET_SIZE(20)}}
                    />
                    <Text className={Styles.step2BulletTitle}>Si vous souhaitez vendre des articles de luxe, chaque pièce devra remplir certaines conditions suivantes :</Text>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Détenir un certificat d'authenticité et/ou une facture visible sur une des photos</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Numéro de série de l'article apparaissant sur au moins une des photos</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />3 photos obligatoires présentant l'article</Text>
                    </Box>
                    <Box className={Styles.step2BulletWrapper}>
                        <Text className={Styles.step2BulletItem}><FiberManualRecordIcon className={Styles.step2Bullet} />Accepter que l'équipe de modération vous demande d'expédier l'article afin qu'il soit expertisé</Text>
                    </Box>
                </Box>;
        }
    }

    let _getPage = () => {
        if (page === 1) {
            return <Grid className={Styles.step1Wrapper}>
                <Text className={Styles.step1Label}>Type de boutique:</Text>
                <Text className={Styles.step1SubLabel}>Un seul choix possible</Text>

                <Grid container className={Styles.step1CheckboxRow}>
                    <Grid item xs={12} sm={6}>
                        <Checkbox
                            containerClass={Styles.checkBox}
                            checkedColor={Constants.PINK}
                            uncheckedColor={errors.type ? Constants.COLOR_ERROR : Constants.FONT_GRAY}
                            title={<Text className={Styles.checkboxLabel} style={errors.type ? {color: Constants.COLOR_ERROR} : {}}>Particulier/Professionnel de la seconde main</Text>}
                            checked={type === 0}
                            onPress={() => setType(0)}
                            size={Constants.CHECKBOX_SIZE_BIG} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Checkbox
                            containerClass={Styles.checkBox}
                            checkedColor={Constants.PINK}
                            uncheckedColor={errors.type ? Constants.COLOR_ERROR : Constants.FONT_GRAY}
                            title={<Text className={Styles.checkboxLabel} style={errors.type ? {color: Constants.COLOR_ERROR} : {}}>Eco-responsable</Text>}
                            checked={type === 1}
                            onPress={() => setType(1)}
                            size={Constants.CHECKBOX_SIZE_BIG} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Checkbox
                            containerClass={Styles.checkBox}
                            checkedColor={Constants.PINK}
                            uncheckedColor={errors.type ? Constants.COLOR_ERROR : Constants.FONT_GRAY}
                            title={<Text className={Styles.checkboxLabel} style={errors.type ? {color: Constants.COLOR_ERROR} : {}}>Créateur</Text>}
                            checked={type === 2}
                            onPress={() => setType(2)}
                            size={Constants.CHECKBOX_SIZE_BIG} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Checkbox
                            containerClass={Styles.checkBox}
                            checkedColor={Constants.PINK}
                            uncheckedColor={errors.type ? Constants.COLOR_ERROR : Constants.FONT_GRAY}
                            title={<Text className={Styles.checkboxLabel} style={errors.type ? {color: Constants.COLOR_ERROR} : {}}>Blogueur</Text>}
                            checked={type === 3}
                            onPress={() => setType(3)}
                            size={Constants.CHECKBOX_SIZE_BIG} />
                    </Grid>
                </Grid>

                <ErrorMessage name={"type"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: Constants.GET_SIZE(30)}} />

                <Text className={Styles.step1Label}>Nom de votre boutique</Text>
                <TextInputIcon
                    errors={errors}
                    // setValue={setName}
                    defaultValue={name}
                    register={register}
                    placeholder={"Nom de la boutique ou pseudo*"}
                    rules={{
                        required: "Ce champ est obligatoire",
                    }}
                    name={"name"}
                />
                <Button onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                    Etape suivante
                </Button>
            </Grid>
        } else if (page === 2) {
            return <Box className={Styles.step2Wrapper}>
                {_getPage2()}
                <Checkbox
                    containerClass={Styles.checkBoxLarge}
                    checkedColor={Constants.PINK}
                    uncheckedColor={errors.cgu ? Constants.COLOR_ERROR : Constants.FONT_GRAY}
                    title={<Text className={Styles.checkboxLabel} style={errors.cgu ? {color: Constants.COLOR_ERROR} : {}}>En créant un compte sur la Marketplace Violette Sauvage, je m’engage à ne pas vendre de produits de contrefaçons, de la fourrure véritable, de cosmétique et des parfums, des produits alimentaires, des objets qui atteignent aux bonnes mœurs . Pour tout autre détail, se référer aux CGV/CGU de la marketplace Violette Sauvage.</Text>}
                    checked={cgu}
                    onPress={() => setCgu(!cgu)}
                    size={Constants.CHECKBOX_SIZE_BIG} />
                <ErrorMessage name={"cgu"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: Constants.GET_SIZE(30)}} />
                <Checkbox
                    containerClass={Styles.checkBoxLarge}
                    checkedColor={Constants.PINK}
                    uncheckedColor={errors.cgv ? Constants.COLOR_ERROR : Constants.FONT_GRAY}
                    title={
                        <Box className={Styles.checkboxLinkWrapper}>
                            <Text className={Styles.checkboxLabel}>
                                J'ai lu et j'accepte les &nbsp;
                                <Link target={"_blank"} href={LINKS["STATIC_CGV"]["web"]} style={{width: "max-content", color: Constants.PINK, textDecoration: "underline", textDecorationColor: Constants.PINK}} onClick={() => handleSavePreviewShop()}>
                                    CGV/CGU et la politique de confidentialité
                                </Link>
                            </Text>
                        </Box>
                    }
                    checked={cgv}
                    onPress={() => setCgv(!cgv)}
                    size={Constants.CHECKBOX_SIZE_BIG} />
                <ErrorMessage name={"cgv"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: Constants.GET_SIZE(30)}} />
                <Button onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                    Etape suivante
                </Button>

            </Box>
        } else if (page === 3) {
            return <Grid container xs={12} justify={"space-between"}>
                <Grid item xs={12} sm={6} direction={"column"} style={{position: "relative"}}>
                    <TextInputIcon
                        key={"shop-name"}
                        errors={errors}
                        // setValue={setName}
                        register={register}
                        defaultValue={name}
                        placeholder={"Nom de la boutique ou pseudo*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"name"}
                    />
                    <TextInputIcon
                        key={"shop-description"}
                        errors={errors}
                        // setValue={setDescription}
                        register={register}
                        defaultValue={description}
                        placeholder={"Texte de présentation*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"description"}
                        multiline
                    />
                    <TextInputIcon
                        key={"shop-address"}
                        errors={errors}
                        // setValue={setAddress}
                        register={register}
                        defaultValue={address}
                        placeholder={"Adresse de facturation*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"address"}
                    />
                    <TextInputIcon
                        key={"shop-zipcode"}
                        errors={errors}
                        // setValue={setZipcode}
                        register={register}
                        defaultValue={zipcode}
                        onFocus={() => setAllowAutocomplete(true)}
                        onBlur={() => setTimeout(() => setAllowAutocomplete(false), 500)}
                        placeholder={"Code postal*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                            pattern: {
                                value: /^[0-9]{5}$/,
                                message: "Format du code postal invalide"
                            }
                        }}
                        name={"zipcode"}
                    />
                    {cityAutocomplete.length > 0 && <List className={Styles.cityAutocomplete}>
                        {cityAutocomplete.map((c) => <ListItemText  className={Styles.cityAutocompleteItem} onClick={() => {setAllowAutocomplete(false); setValue('city', c);setCity(c)}}>{c}</ListItemText>)}
                    </List>}
                    <TextInputIcon
                        key={"shop-city"}
                        errors={errors}
                        readOnly={true}
                        defaultValue={city}
                        register={register}
                        placeholder={"Ville*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"city"}
                    />
                    <TextInputIcon
                        key={"shop-mail"}
                        errors={errors}
                        // setValue={setEmail}
                        register={register}
                        defaultValue={email}
                        placeholder={"Email*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                            pattern: {
                                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Format de l'adresse e-mail invalide"
                            }
                        }}
                        name={"email"}
                    />
                    <TextInputIcon
                        errors={errors}
                        // setValue={setPhone}
                        register={register}
                        defaultValue={phone}
                        placeholder={"Téléphone*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                            pattern: {
                                value: /^0[0-9]{9}$/,
                                message: "Format du numéro de téléphone invalide"
                            }
                        }}
                        name={"phone"}
                    />
                    <Hidden xsDown>
                        <ErrorMessage name={"general"} errors={errors} as={Text} className={ErrorStyle.error} />
                        <Button onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                            {user.shop && user.shop.id ? "Enregistrer la boutique" : "Etape suivante"}
                        </Button>
                    </Hidden>
                </Grid>
                <Grid item xs={12} sm={5} direction={"column"}>
                    <Box className={Styles.imageWrapper}>
                        <div ref={familyRef} tabIndex={0}></div>
                        {picture && <Image source={picture} className={Styles.image} />}
                        {!picture && user && user.shop && user.shop.picture && <Image source={getImageUrl(user.shop.picture.contentUrl, "medium")} className={Styles.image} />}
                        {!picture && (!user || !user.shop || !user.shop.picture) && <Image source={UserIcon} className={Styles.image} />}
                        <Link component={"label"}>
                            <Text className={Styles.imageButtonLabel}>{picture || (user && user.shop && user.shop.picture) ? "Modifier la" : "Ajouter une"} photo</Text>
                            <input type={"file"} hidden accept={"image/*"} onChange={(e) => onPickImage(e)}/>
                        </Link>
                        <ErrorMessage name={"picture"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: Constants.GET_SIZE(20), marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, textAlign: "center"}} />
                    </Box>

                    {_getStripeDocumentsBlock()}

                    <Box style={{paddingLeft: 50, marginBottom: 20}}>
                        <DatePicker
                            value={dob}
                            onChange={(value) => {clearErrors("dob");setDob(value);}}
                            format="DD/MM/YYYY"
                            showYearDropdown
                            yearDropdownItemNumber={5}
                            maxDate={maxBirthdate}
                            placeholder="Date de naissance*"
                            invalidDateMessage={"Format de date invalide"}
                            maxDateMessage={"Vous devez avoir au moins 15 ans pour vendre sur Violette Sauvage"}
                            minDateMessage={"Format de date invalide"}
                            inputVariant={"outlined"}
                        />
                        <ErrorMessage name={"dob"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: Constants.GET_SIZE(20), textAlign: "center"}} />
                    </Box>

                    <Box className={Styles.snInputWrapper}>
                        <FontAwesome name="facebook" style={{minWidth: Constants.GET_SIZE(25), fontSize: Constants.FONT_SIZE_BIG, marginRight: Constants.GET_SIZE(25)}} color={Constants.FONT_GRAY} />
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            defaultValue={facebook}
                            name={"facebook"}
                            wrapperClass={Styles.snInput}
                            rules={{
                                pattern: {
                                    value: /^(https?:\/\/)?www.facebook.com\//,
                                    message: "Lien facebook invalide"
                                }
                            }}
                        />
                    </Box>
                    <Box className={Styles.snInputWrapper}>
                        <FontAwesome name="instagram" style={{minWidth: Constants.GET_SIZE(25), fontSize: Constants.FONT_SIZE_BIG, marginRight: Constants.GET_SIZE(25)}} color={Constants.FONT_GRAY} />
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            defaultValue={instagram}
                            name={"instagram"}
                            wrapperClass={Styles.snInput}
                            rules={{
                                pattern: {
                                    value: /^(https?:\/\/)?www.instagram.com\//,
                                    message: "Lien instagram invalide"
                                }
                            }}
                        />
                    </Box>
                    <Box className={Styles.snInputWrapper}>
                        <FontAwesome name="twitter" style={{minWidth: Constants.GET_SIZE(25), fontSize: Constants.FONT_SIZE_BIG, marginRight: Constants.GET_SIZE(25)}} color={Constants.FONT_GRAY} />
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            defaultValue={twitter}
                            name={"twitter"}
                            wrapperClass={Styles.snInput}
                            rules={{
                                pattern: {
                                    value: /^(https?:\/\/)?(www.)?twitter.com\//,
                                    message: "Lien twitter invalide"
                                }
                            }}
                        />
                    </Box>
                    {otherSocialNetworks.map((osn, index) =>
                        <Box className={Styles.snInputWrapper}>
                            <Text style={{minWidth: Constants.GET_SIZE(25), fontSize: Constants.FONT_SIZE_BIG, marginRight: Constants.GET_SIZE(25), color: Constants.FONT_GRAY}}>W</Text>
                            <TextInputIcon
                                setValue={(value) => setOtherSocialNetworks([...otherSocialNetworks.slice(0, index), value, ...otherSocialNetworks.slice(index + 1)])}
                                value={osn}
                                wrapperClass={Styles.snInput}
                            />
                            <DeleteIcon className={Styles.snInputRemoveLabel} onClick={() => setOtherSocialNetworks([...otherSocialNetworks.slice(0, index), ...otherSocialNetworks.slice(index + 1)])} />
                        </Box>
                    )}
                    <Button onClick={() => setOtherSocialNetworks([...otherSocialNetworks, ""])} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                        Ajouter un lien
                    </Button>
                    <Hidden smUp>
                        <Button onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                            {user && user.shop && user.shop.id ? "Enregistrer la boutique" : "Etape suivante"}
                        </Button>
                    </Hidden>
                </Grid>
            </Grid>
        } else if (page === 4) {
            return <Grid container xs={12} justify={"space-between"}>
                {screenKey !== "MyBankInfo" && <Grid item xs={12} sm={12} style={{marginTop: -15, marginBottom: 30}}>
                    Vous ne pourrez pas récupérer les montants vendus tant que ces informations ne sont pas remplies mais vous pourrez remplir ces informations plus tard.
                </Grid>}
                <Grid item xs={12} sm={5} direction={"column"}>
                    <TextInputIcon
                        key={"bank-name"}
                        errors={errors}
                        // setValue={setBankName}
                        register={register}
                        defaultValue={bankName}
                        placeholder={"Nom de la banque"}
                        rules={{
                            // required: "Ce champ est obligatoire",
                        }}
                        name={"bankName"}
                    />
                    <TextInputIcon
                        key={"bank-agency"}
                        errors={errors}
                        // setValue={setBankAgency}
                        register={register}
                        defaultValue={bankAgency}
                        placeholder={"Nom de l'agence"}
                        rules={{
                            // required: "Ce champ est obligatoire",
                        }}
                        name={"bankAgency"}
                    />
                    <TextInputIcon
                        key={"bank-address"}
                        errors={errors}
                        // setValue={setBankAddress}
                        register={register}
                        defaultValue={bankAddress}
                        placeholder={"Adresse de l'agence"}
                        rules={{
                            // required: "Ce champ est obligatoire",
                        }}
                        name={"bankAddress"}
                    />
                    <TextInputIcon
                        key={"bank-zipcode"}
                        errors={errors}
                        // setValue={setBankZipcode}
                        register={register}
                        defaultValue={bankZipcode}
                        placeholder={"Code postal"}
                        rules={{
                            // required: "Ce champ est obligatoire",
                            pattern: {
                                value: /^[1-9][0-9]{4}$/,
                                message: "Format du code postal invalide"
                            }
                        }}
                        name={"bankZipcode"}
                    />
                    <TextInputIcon
                        key={"bank-city"}
                        errors={errors}
                        // setValue={setBankCity}
                        defaultValue={bankCity}
                        register={register}
                        placeholder={"Ville"}
                        rules={{
                            // required: "Ce champ est obligatoire",
                        }}
                        name={"bankCity"}
                    />
                    <Hidden xsDown>
                        <Button onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                            {screenKey === "MyBankInfo" ? "Enregistrer" : "Mise en ligne de la boutique"}
                        </Button>
                    </Hidden>
                </Grid>
                <Grid item xs={12} sm={5} direction={"column"}>
                    <TextInputIcon
                        errors={errors}
                        // setValue={setBankAccountOwner}
                        register={register}
                        defaultValue={bankAccountOwner}
                        rules={{
                            // required: "Ce champ est obligatoire",
                        }}
                        placeholder={"Titulaire du compte"}
                        name={"bankAccountOwner"}
                    />
                    <TextInputIcon
                        errors={errors}
                        // setValue={setIban}
                        register={register}
                        defaultValue={bankAccountIBAN}
                        rules={{
                            // required: "Ce champ est obligatoire"
                        }}
                        placeholder={"IBAN"}
                        name={"bankAccountIBAN"}
                    />
                    <TextInputIcon
                        errors={errors}
                        // setValue={setBic}
                        register={register}
                        defaultValue={bankAccountBIC}
                        rules={{
                            // required: "Ce champ est obligatoire"
                        }}
                        placeholder={"BIC"}
                        name={"bankAccountBIC"}
                    />
                    {(screenKey === "MyBankInfo" || page === 4) && <Text className={Styles.description}>Vous êtes responsables des informations renseignées</Text>}
                    <Hidden smUp>
                        <Button onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                            {screenKey === "MyBankInfo" ? "Enregistrer" : "Mise en ligne de la boutique"}
                        </Button>
                    </Hidden>
                </Grid>
            </Grid>
        } else if (page === 5) {
            return <Grid container className={Styles.descriptionWrapper} justify={"center"}>
                <Text className={Styles.description}>Félicitations, votre boutique est désormais en ligne !</Text>
                <Text className={Styles.description}>Maintenant il ne vous reste plus qu'à mettre en vente vos articles.</Text>

                <Button onClick={() => history.push(LINKS["CREATE_PRODUCT"]["web"])} variant={"contained"} color={"primary"} disableElevation className={Styles.button} style={{marginLeft: "auto", marginRight: "auto"}}>
                    Vendre un produit
                </Button>
            </Grid>;
        }
    };

    let _getHeader = () => {
        return <Box className={Styles.headerWrapper}>
            <Text className={clsx(Styles.headerStep, {[Styles.headerStepActive]: page === 1})}>1. Choix boutique</Text>
            <Text className={clsx(Styles.headerStep, {[Styles.headerStepActive]: page === 2})}>2. Conditions</Text>
            <Text className={clsx(Styles.headerStep, {[Styles.headerStepActive]: page === 3})}>3. Informations</Text>
            <Text className={clsx(Styles.headerStep, {[Styles.headerStepActive]: page === 4})}>4. Être payé</Text>
        </Box>
    };

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>{screenKey === "MyBankInfo" ? "Mes coordonnées bancaires" : "Ma boutique"}</Text>
            {screenKey === "CreateMyShop" && page < 5 && <Box className={Styles.description2Wrapper}>
                <Text className={Styles.description}>Ici, vous pouvez créer votre boutique en quelques clics !</Text>
                <Text className={Styles.description}>Plusieurs informations vous seront demandées en fonction du type de boutique que vous</Text>
                <Text className={Styles.description}>souhaitez créer. À vous de jouer !</Text>
            </Box>}
            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={screenKey === "MyBankInfo" ? "MY_BANK_INFO" : "MY_SHOP"} />
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    {screenKey === "CreateMyShop" && page < 5 && _getHeader()}
                    {_getPage()}
                </Box>
            </Grid>

            <ConfirmationModal
                visible={modalVisible}
                setVisible={setModalVisible}
                title={"Mes coordonnées bancaires"}
                message={"Vos informations ont bien été modifiées"}
                onConfirm={() => {setModalVisible(false)}} />
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        previewShop: state.shop.previewShop,
        saveShopErrors: state.shop.saveShopErrors,
        isSavingShop: state.shop.isSavingShop,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveShop: (shop, picture) => {
            dispatch({type: 'SAVE_SHOP_REQUESTED', shop, picture})
        },
        savePreviewShop: (shop) => {
            dispatch({type: 'SAVE_PREVIEW_SHOP', shop})
        },
        resetPreviewShop: () => {
            dispatch({type: 'RESET_PREVIEW_SHOP'})
        },
        fetchMe: (token) => {
            dispatch({type: 'FETCH_ME_REQUESTED', payload: {token}})
        },
        startLoading: () => {
            dispatch({type: 'START_LOADING_REQUESTED'})
        },
        stopLoading: () => {
            dispatch({type: 'START_LOADING_SUCCEEDED'})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
